import { createSlice } from "@reduxjs/toolkit"
import { Banner } from "@app/interfaces/banner.interface"
import { Slider } from "@app/interfaces/slider.interface"

interface homeState {
  banners: Banner[]
  sliders: Slider[]
  isLoading: boolean
}

interface payloadBanner {
  payload: {
    banners: Banner[]
    sliders: Slider[]
    isLoading: boolean
  }
}

const initialState: homeState = {
  banners: [],
  sliders: [],
  isLoading: false,
}

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeDataReducer: (state, action: payloadBanner) => {
      state.banners = action.payload.banners
      state.sliders = action.payload.sliders
      state.isLoading = action.payload.isLoading
    },
  },
})

export const { setHomeDataReducer } = homeSlice.actions

export default homeSlice.reducer
