import React, { FC } from "react"
import { ViewStyle, ActivityIndicator, View, Image, ImageStyle } from "react-native"
import { Screen } from "@app/components"
import { AppStackScreenProps } from "@app/navigators"
import { colors } from "@app/theme"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { isWeb } from "@app/utils/isWeb"

interface LoginScreenProps extends AppStackScreenProps<"Login"> {}

export const LoadingSplash: FC<LoginScreenProps> = (_props) => {
  const logo = require("../../../assets/images/bootsplash_logo.png")
  const { height } = useWindowDimensions()

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    height: isWeb() ? height : "100%",
    backgroundColor: colors.MAIN_BLUE,
  }

  return (
    <Screen preset="fixed" contentContainerStyle={$screenContentContainer}>
      <View style={$container}>
        <Image source={logo} style={$logo} />
        <View style={$activityIndicator}>
          <ActivityIndicator color={colors.MAIN_AQUAMARINE} size={35} />
        </View>
      </View>
    </Screen>
  )
}

const $container: ViewStyle = {
  backgroundColor: colors.MAIN_BLUE,
  height: "100%",
  width: "100%",
  flex: 1,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
}

const $logo: ImageStyle = { width: 150, height: 50 }

const $activityIndicator: ViewStyle = { marginTop: 20 }
