import { createSlice } from "@reduxjs/toolkit"
import { UserHistory } from "@app/interfaces/user_history.interface"

interface historyState {
  userHistory: UserHistory[]
}

interface payloadHistory {
  payload: {
    userHistory: UserHistory[]
  }
}

interface payloadAddRemoveHistory {
  payload: {
    userHistory: UserHistory
  }
}

const initialState: historyState = {
  userHistory: [],
}

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setHistoryReducer: (state, action: payloadHistory) => {
      state.userHistory = action.payload.userHistory
    },
    addOrSaveHistoryReducer: (state, action: payloadAddRemoveHistory) => {
      const filter = state.userHistory
        .map((history) => {
          if (history.id === action.payload.userHistory.id) {
            return action.payload.userHistory
          } else {
            return history
          }
        })
        .sort((a, b) => {
          return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
        })
      state.userHistory = filter
    },
    removeHistoryReducer: (state, action: payloadAddRemoveHistory) => {
      state.userHistory = state.userHistory.filter((history) => {
        return history.id !== action.payload.userHistory.id
      })
    },
  },
})

export const { setHistoryReducer, addOrSaveHistoryReducer, removeHistoryReducer } =
  historySlice.actions

export default historySlice.reducer
