import { useState } from "react"
import { Platform, Linking, Alert } from "react-native"
import DeviceInfo from "react-native-device-info"
import firestore from "@app/services/firebase/firestore/Firestore"

const useUpdateModal = () => {
  const [openModal, setOpenModal] = useState(false)
  const [content, setContent] = useState(null)
  const [updateBtn, setUpdateBtn] = useState(() => {
    return () => console.log("updateBtn")
  })
  const [maintenanceText, setMaintenanceText] = useState("")

  const maintenanceIcon = require("../../../../assets/icons/maintenanceIcon.png")

  const updateIcon = require("../../../../assets/icons/updateIcon.png")

  const initializeFirestore = () => {
    firestore()
      .collection("settings")
      .onSnapshot((querySnapshot) => {
        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.exists ? doc.data() : {},
        }))
        isMaintenanceOrForceUpdate(items)
      })
  }

  const isMaintenanceOrForceUpdate = (items: Array<{ id: string; data: any }>) => {
    items.forEach((item) => {
      if (item.id === "config") {
        if (item.data.maintenance.isMaintenance) {
          setOpenModal(true)
          setContent("maintenance")
          setMaintenanceText(item.data.maintenance.maintenanceInformation)
        } else {
          const deviceOS = Platform.OS
          const appVersion = DeviceInfo.getVersion()
          if (deviceOS === "android") {
            if (item.data.minAndroidBinaryVersion !== appVersion) {
              setOpenModal(true)
              setContent("forceUpdate")
              setUpdateBtn(() => linkingUrl(item.data.androidStoreUrl))
            }
          } else if (deviceOS === "ios") {
            if (item.data.minIosBinaryVersion !== appVersion) {
              setOpenModal(true)
              setContent("forceUpdate")
              setUpdateBtn(() => linkingUrl(item.data.iosStoreUrl))
            }
          } else {
            // TODO: implementar el update en web
          }
        }
      }
    })
  }

  const linkingUrl = (url: string) => async () => {
    const supported = await Linking.canOpenURL(url)
    if (supported) {
      await Linking.openURL(url)
    } else {
      Alert.alert(`Error al intentar abrir la siguiente url: ${url}`)
    }
  }

  return {
    openModal,
    content,
    updateBtn,
    maintenanceText,
    initializeFirestore,
    maintenanceIcon,
    updateIcon,
  }
}

export default useUpdateModal
