import React, { Fragment, FC } from "react"
import {
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ImageBackground,
  ViewStyle,
  TextStyle,
  ImageStyle,
  ActivityIndicator,
} from "react-native"
import { Text, Icon } from "@app/components"
import { colors, typography } from "@app/theme"
import { Grid } from "@mui/material"
import useSearch from "../hooks/use-search"
import { THUMBNAIL_PLACEHOLDER } from "@app/utils/constants"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import { Document } from "@app/interfaces/document.interface"

interface Props {
  items: Document[]
  navigateTo: any
}

export const DocumentComponent: FC<Props> = ({ items, navigateTo }) => {
  const type = "document"
  const { auth, isInFavorites, removeFavorite, addFavorite, showModal, hideModal, favStates } =
    useSearch()

  const handleNavigate = (id: number, type: string) => {
    switch (type) {
      case "video":
        navigateTo("Player", { videoId: id })
        break
      case "document":
        navigateTo("Document", { documentId: id })
        break
      case "serie":
        navigateTo("Serie", { serieId: id })
        break
      default:
        break
    }
  }

  return (
    <Fragment>
      <View style={$searchBarContainer}>
        <View style={$searchInputContainer}>
          <Text text={"Documentos"} style={$textHeader2} />
        </View>
      </View>
      <View style={$gridContainer}>
        <Grid container rowSpacing={3} spacing={2} sx={$gridContainer}>
          {items.map((item) => {
            return (
              <Grid key={item.id} item sx={$gridContainer2}>
                <TouchableOpacity
                  onPress={() => handleNavigate(item.id, type)}
                  key={item.id}
                  style={$touchable}
                >
                  <ImageBackground
                    source={{ uri: item.thumbnail || THUMBNAIL_PLACEHOLDER }}
                    resizeMode="cover"
                    key={item.thumbnail}
                    style={$imageContainerStyle}
                    imageStyle={$imageStyleImage}
                  >
                    {auth.isAnonymous ? (
                      <TouchableOpacity
                        onPress={() => {
                          showModal(
                            <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                          )
                        }}
                        style={$favoriteContainer}
                      >
                        <View>
                          <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                        </View>
                      </TouchableOpacity>
                    ) : favStates[type] && favStates[type][item.id] ? (
                      <TouchableWithoutFeedback>
                        <View style={$favoriteContainer}>
                          <ActivityIndicator size={14} color={colors.MAIN_AQUAMARINE} />
                        </View>
                      </TouchableWithoutFeedback>
                    ) : isInFavorites(item.id, type) ? (
                      <TouchableOpacity
                        onPress={() => removeFavorite(item.id, type)}
                        style={$favoriteContainer}
                      >
                        <View>
                          <Icon icon="heart" size={14} color={colors.MAIN_AQUAMARINE} />
                        </View>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => addFavorite(item.id, type)}
                        style={$favoriteContainer}
                      >
                        <View>
                          <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                        </View>
                      </TouchableOpacity>
                    )}
                  </ImageBackground>
                  <View style={$documentDataContainer}>
                    <View>
                      <Text style={$titleTextStyle} numberOfLines={2}>
                        {item.title}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        paddingTop: 10,
                      }}
                    >
                      {item.categories.length > 0 &&
                        item.categories.map((category) => (
                          <View key={category.id} style={{ padding: 3 }}>
                            <View
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderRadius: 5,
                                paddingVertical: 2,
                                paddingHorizontal: 3,
                                borderColor: colors.LIGHT_GRAY,
                              }}
                            >
                              <Text style={$categoryTestStyle}>{category.name}</Text>
                            </View>
                          </View>
                        ))}
                    </View>

                    <View style={{ paddingVertical: 10 }}>
                      <Text style={$descriptionTextStyle} numberOfLines={2}>
                        {item.description}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </Grid>
            )
          })}
        </Grid>
      </View>
    </Fragment>
  )
}

const $searchInputContainer: ViewStyle = {
  width: "90%",
  flexGrow: 1,
}

const $gridContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  // TODO: Ver como se ve mejor
  // justifyContent: "center",
  backgroundColor: colors.transparent,
  paddingLeft: 8,
}

const $gridContainer2: ViewStyle = {
  display: "flex",
  alignItems: "center",
  // TODO: Ver como se ve mejor
  // justifyContent: "center",
  backgroundColor: colors.transparent,
}

const $imageContainerStyle: ViewStyle = {
  width: 320,
  height: 150,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}

const $touchable: ViewStyle = {
  flex: 1,
  backgroundColor: colors.WHITE,
  minHeight: 280,
  width: 320,
  borderRadius: 17,
}

const $favoriteContainer: ViewStyle = {
  width: 32,
  height: 32,
  borderRadius: 16,
  margin: 9,
  backgroundColor: colors.MEDIUM_GRAY,
  alignSelf: "flex-end",
  alignItems: "center",
  justifyContent: "center",
}

const $searchBarContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
  marginTop: 45,
}

const $textHeader2: TextStyle = {
  flexGrow: 1,
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 20,
  lineHeight: 1,
  fontStyle: "normal",
  letterSpacing: 0,
  paddingBottom: 23,
  color: colors.WHITE,
}

const $imageStyleImage: ImageStyle = {
  borderRadius: 15,
}

const $documentDataContainer: ViewStyle = {
  flex: 1,
  justifyContent: "flex-start",
  padding: 13,
}
const $titleTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: 19,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.MAIN_DARK_BLUE,
}

const $descriptionTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 13,
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: 15,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.SECONDARY_BLACK,
}

const $categoryTestStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: 14,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.GRAY,
}
