import axios from "@app/services/axios"
import { Favorite } from "@app/interfaces/favorite.interface"
import { AUTH_HEADERS } from "@app/utils/constants"

export const fetchFavoritesById = async (id: number) => {
  const response = await axios.get<{ data: Favorite[]; status: number }>(
    `/api/favorites/${id}`,
    await AUTH_HEADERS(),
  )
  return response
}

export const createFavorite = async (userId: number, entityId: number, type: string) => {
  const response = await axios.post<{ data: Favorite; status: number }>(
    `/api/favorites`,
    {
      user_id: userId,
      entity_id: entityId,
      type,
    },
    await AUTH_HEADERS(),
  )
  return response
}

export const deleteFavorite = async (userId: number, entityId: number, type: string) => {
  const response = await axios.put<{ data: Favorite; status: number }>(
    `/api/favorites/delete`,
    {
      user_id: userId,
      entity_id: entityId,
      type,
    },
    await AUTH_HEADERS(),
  )
  return response
}
