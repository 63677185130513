import AsyncStorage from "@react-native-async-storage/async-storage"

export const THUMBNAIL_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/images%2Fthumbnail%2Fthumbnail-placeholder.png?alt=media&token=97df4694-81fd-4661-8657-944d3ebb0a3e"

export const FULL_SIZE_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/images%2Fimages_full_size%2Fimage_full_size_placeholder.png?alt=media&token=8bdb34bb-e72d-4ace-828a-be806ab35cb2"

export const AVATAR_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/images%2Fprofile%2Favatar-placeholder.png?alt=media&token=9ea49c09-b9ca-42c6-8709-e903afe7433e"

export const APP_BAR_HEIGHT = 70

export const APP_BAR_EXPLORER = 70

export const FAQ_ARRAY = [
  {
    id: 1,
    title: "¿Qué es Libbre?",
    response: `Plataforma digital con contenido educativo, informativo, de divulgación y de entretenimiento adaptado a las distintas edades y niveles`,
  },
  {
    id: 2,
    title: `¿Tengo que suscribirme para poder usarlo?`,
    response: `Libbre es abierta a cualquier persona y puede ser usada y consultada sin necesidad de registrarse. Igualmente existe la opción de ser un usuario registrado a la plataforma que puede acceder a guardar sus contenidos como favoritos, disponer de un historial de sus consultas y crear y editar su perfil.`,
  },
  {
    id: 3,
    title: `¿Dónde se encuentra Libbre?`,
    response: `Libbre es un recurso en línea, ¡así que básicamente estamos en todas partes! Somos gratuitos y disponibles para cualquier persona con una mente abierta y acceso en línea. Nuestra sede es en Chile, en la capital Santiago de Chile.`,
  },
  {
    id: 4,
    title: `¿Cuáles son sus objetivos?`,
    response: `Libbre busca que las personas comprendan los valores constitutivos de una sociedad libre como los derechos individuales, la responsabilidad por los actos propios, el respeto a la diversidad de opiniones y a la libertad de expresarlas; los mecanismos de funcionamiento de las instituciones que rigen la vida comunitaria, la comprensión de los fenómenos sociales, políticos y educativos que permitan ir en la dirección de una sociedad libre.`,
  },
  {
    id: 5,
    title: `¿Cómo funciona la aplicación?`,
    response: `Es una plataforma de fácil uso, que los usuarios puedan acceder gratuitamente al contenido deseado desde cualquier lugar.`,
  },
  {
    id: 6,
    title: `¿A qué edad está dirigida o qué tipo de público?`,
    response: `Tanto a niños como adultos. Queremos que el contenido llegue a un público variado, de manera amigable, cómoda, que seduzca a padres y niños por igual.`,
  },
  {
    id: 7,
    title: `¿Qué herramientas puedo obtener de Libbre?`,
    response: `La plataforma brindará herramientas discursivas e ideológicas a las personas que comulgan con las ideas de la libertad y pro occidentales, pero también buscará seducir con una narrativa clara, calma, contenedora y simple a aquellos que no comulgan con estas ideas porque no tuvieron la oportunidad de escuchar otra versión ni la información disponible.`,
  },
  {
    id: 8,
    title: `¿A través de que dispositivos se puede consultar?`,
    response: `La aplicación es web y mobile. Se pueden utilizar tanto el televisor, como el celular, computadora o tablet. Puede descargar la aplicación móvil gratuita de las tiendas on line Google Play y App Store. En las redes sociales puede seguirnos en @libbre en Facebook , YouTube e Instagram.`,
  },
  {
    id: 9,
    title: `¿En qué formato se encuentra el contenido?`,
    response: `Se pueden consultar videos y documentos (revistas). Los documentos se pueden bajar de manera gratuita. También encontrará series que agrupan videos con episodios de una temática en particular.`,
  },
  {
    id: 10,
    title: ` ¿Qué contenidos hay para niños?`,
    response: `Sobre grandes personajes de la historia y la actualidad. Aventuras históricas con personajes Animados. Artesanía con temas educativos. Ejemplos de contenidos para estudiantes nivel medio: Videos actuados y revistas sobre temas de debate actual. Historia nacional e internacional. Cómo vivir mejor, consejos cívicos y morales.`,
  },
  {
    id: 11,
    title: `¿Con qué frecuencia lanzan nuevo contenido?`,
    response: `Lanzamos contenido nuevo todas las semanas.`,
  },
  {
    id: 12,
    title: `¿Es responsable Libbre o respalda el contenido que muestran los presentadores?`,
    response: `Libbre es responsable únicamente por el contenido de sus presentadores en los videos y los otros medios como documentos y revistas producidos por Libbre. No somos responsables de, ni necesariamente estamos de acuerdo, con nada dicho o escrito por los presentadores en cualquier otro foro o espacio.`,
  },
]

export const AUTH_HEADERS = async () => {
  const token = await AsyncStorage.getItem("token")
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}
