import axios from "@app/services/axios"
import { User } from "@app/interfaces/user.interface"
import auth from "@app/services/firebase/auth/Auth"
import { Alert } from "react-native"
import { useNavigation } from "@app/navigators/utils"
import { AUTH_HEADERS } from "@app/utils/constants"

export const editUser = async (userId: number, body: unknown) => {
  const response = await axios.put<{ data: User; status: number }>(
    `/api/users/${userId}`,
    body,
    await AUTH_HEADERS(),
  )
  return response
}

export const loginUser = async (token: string) => {
  const response = await axios.get<{ data: User; status: number }>(`/api/users/login`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

export const registerUser = async (
  values,
  setErrors,
  setStatus,
  setSubmitting,
): Promise<number | void> => {
  const { goBack } = useNavigation()
  try {
    const responseCreateFirebase = await auth().createUserWithEmailAndPassword(
      values.email,
      values.password,
    )

    await responseCreateFirebase.user.sendEmailVerification()

    const body = {
      ...values,
      auth_id: responseCreateFirebase.user.uid,
    }

    const response = await axios.post<{ data: User }>("/api/users/register", body)
    setStatus({ success: true })
    setSubmitting(true)
    //! AQUI IRIA EL MODAL SUCCESS:

    Alert.alert(`Usuario ${response.data.data.email} creado con éxito`, null, [
      {
        text: "Aceptar",
        onPress: () => goBack(),
      },
    ])

    return response.data.data.id
  } catch (error) {
    // toDo: arreglar la logica
    if (error.message.includes("This email already exists")) {
      //! ACA UN MODAL WARNING
      Alert.alert("Este email ya se encuentra en uso")
    } else {
      //! AQUI VA EL MODAL ERROR

      Alert.alert("No se pudo crear el usuario")
    }
    console.log(error)
    // showErrorModal()
    setStatus({ success: false })
    setErrors({ submit: error.code })
    setSubmitting(false)
  }
}

export const updateUser = async (idUser, newBody) => {
  await axios.put(`/api/users/${idUser}`, newBody, await AUTH_HEADERS())
}
