/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useMemo } from "react"
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ImageBackground,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from "react-native"
import { Text, Button, Icon, TextField, TextFieldAccessoryProps } from "@app/components"
import { colors, typography, spacing } from "@app/theme"
import useLogin from "../hooks/use-login"
import { isWeb } from "@app/utils/isWeb"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"

const backgroundLoginImage = require("../../../../../assets/images/background-login.png")
const logoImage = require("../../../../../assets/images/LogotipoOne.png")

export const LoginForm = ({ navigateTo, goBack }) => {
  const { isAuthPasswordHidden, setIsAuthPasswordHidden, isLoading, errorLoginMsg, form } =
    useLogin(navigateTo)
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const PasswordRightAccessory = useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Icon
            icon={isAuthPasswordHidden ? "view" : "hidden"}
            color={colors.LIGHT_GRAY}
            containerStyle={props.style}
            onPress={() => setIsAuthPasswordHidden(!isAuthPasswordHidden)}
          />
        )
      },
    [isAuthPasswordHidden],
  )

  const $backgroundLogin: ViewStyle = {
    width: "100%",
    height: 250,
    paddingTop: insets.top,
  }

  const $viewContainerWeb =
    width < 600
      ? {
          paddingHorizontal: "10%",
          flexGrow: 1,
          height: "100%",
          paddingTop: "10%",
          paddingBottom: "10%",
        }
      : {
          paddingHorizontal: "20%",
          flexGrow: 1,
          height: "100%",
          paddingTop: "10%",
          paddingBottom: "10%",
        }

  return (
    <Fragment>
      {!isWeb() && (
        <View>
          <ImageBackground
            source={backgroundLoginImage}
            resizeMode="cover"
            style={$backgroundLogin}
          >
            <View style={$headerContainer}>
              <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
                <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
              </TouchableOpacity>
              <View style={$textHeaderContainer}>
                <Text text="Iniciar sesión" style={$textHeader} />
              </View>
            </View>
            <View style={$isologoOneWrapper}>
              <Image source={logoImage} style={$isologoOne} resizeMode="contain" />
            </View>
          </ImageBackground>
        </View>
      )}

      <View style={isWeb() ? $viewContainerWeb : $viewContainer}>
        <View style={[$titleTextContainer, isWeb() && { flex: 0.3 }]}>
          <View style={$titleContainer}>
            <Text
              testID="login-heading"
              tx="loginScreen.title"
              style={[$title, isWeb() && $titleWeb]}
            />
          </View>
          <View style={$welcomeContainer}>
            <Text tx="loginScreen.welcome" style={[$welcome, isWeb() && $welcomeWeb]} />
            {isWeb() && (
              <View style={{ marginBottom: 15 }}>
                <Text text="Iniciar sesión" style={$logInWebText} />
              </View>
            )}
          </View>
        </View>

        <View style={[$formContainer, isWeb() && { flex: 0.5, justifyContent: "center" }]}>
          <View style={$emailInputContainer}>
            <View style={$inputLabelContainer}>
              <Text tx="loginScreen.emailFieldLabel" style={$inputLabel} />
            </View>
            <TextField
              onChangeText={form.handleChange("email")}
              onBlur={form.handleBlur("email")}
              value={form.values.email}
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect={false}
              helper={form.touched.email && form.errors.email}
              status={form.touched.email && form.errors.email ? "error" : null}
              placeholderTx="loginScreen.emailFieldPlaceholder"
              inputWrapperStyle={$inputWrapper}
              style={{ color: colors.WHITE }}
              placeholderTextColor={colors.GRAY}
              HelperTextProps={{ style: { color: colors.SECONDARY_CORAL } }}
            />
          </View>

          <View style={$passwordInputContainer}>
            <View style={$inputLabelContainer}>
              <Text tx="loginScreen.passwordFieldLabel" style={$inputLabel} />
            </View>
            <TextField
              onChangeText={form.handleChange("password")}
              onBlur={form.handleBlur("password")}
              value={form.values.password}
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect={false}
              secureTextEntry={isAuthPasswordHidden}
              RightAccessory={PasswordRightAccessory}
              helper={form.touched.password && form.errors.password}
              status={form.touched.password && form.errors.password ? "error" : null}
              placeholderTx="loginScreen.passwordFieldPlaceholder"
              inputWrapperStyle={$inputWrapper}
              style={{ color: colors.WHITE }}
              placeholderTextColor={colors.GRAY}
              HelperTextProps={{ style: { color: colors.SECONDARY_CORAL } }}
            />
          </View>

          {Boolean(errorLoginMsg) && (
            <Text preset="formHelper" text={errorLoginMsg} style={$errorText} />
          )}
        </View>

        <View style={[$forgotPasswordContainer, isWeb() && { flex: 0.1 }]}>
          <TouchableOpacity onPress={() => navigateTo("ForgotPassword")}>
            <Text testID="login-heading" tx="loginScreen.forgotPassword" style={$forgotPassword} />
          </TouchableOpacity>
        </View>

        <View style={[$submitContainer, isWeb() && { flex: 0.2 }]}>
          {isLoading ? (
            <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
          ) : (
            <>
              <View style={$signInBtnContainer}>
                <Button
                  testID="login-button"
                  tx="loginScreen.tapToSignIn"
                  style={$signInBtn}
                  textStyle={$signInText}
                  pressedStyle={$signInBtnPressed}
                  pressedTextStyle={$signInTextPressed}
                  preset="reversed"
                  onPress={form.handleSubmit}
                />
              </View>
              <View style={$registerBtnContainer}>
                <Button
                  testID="login-button"
                  tx="loginScreen.tapToRegister"
                  style={$registerBtn}
                  textStyle={$registerText}
                  pressedStyle={$registerBtnPressed}
                  pressedTextStyle={$registerTextPressed}
                  preset="reversed"
                  onPress={() => navigateTo("Register")}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </Fragment>
  )
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
}

const $viewContainer: ViewStyle = {
  paddingHorizontal: "5%",
  flexGrow: 1,
}

const $isologoOne: ImageStyle = {
  height: 85,
}

const $isologoOneWrapper: ViewStyle = { justifyContent: "center", flex: 1, alignItems: "center" }

const $titleTextContainer: ViewStyle = { flex: 0.2, justifyContent: "center" }

const $title: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 35,
  lineHeight: 44,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $titleWeb = {
  fontSize: 64,
  marginBottom: 15,
  lineHeight: 70,
}

const $titleContainer: ViewStyle = { alignSelf: "flex-start" }

const $welcome: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 20,
  lineHeight: 32,
  fontStyle: "normal",
  fontWeight: "500",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $welcomeWeb = {
  fontSize: 32,
  lineHeight: 40,
}

const $logInWebText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 28,
  lineHeight: 32,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $welcomeContainer: ViewStyle = { alignSelf: "flex-start" }

const $formContainer: ViewStyle = { flex: 0.3, justifyContent: "flex-start" }

const $inputLabelContainer: ViewStyle = { marginBottom: 10 }

const $inputLabel: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 13,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "500",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $emailInputContainer: ViewStyle = { marginTop: 10 }

const $passwordInputContainer: ViewStyle = { marginTop: 35, marginBottom: 15 }

const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: colors.SECONDARY_BLACK,
  minHeight: 10,
  // borderColor: colors.SECONDARY_CORAL,
}

const $forgotPasswordContainer: ViewStyle = { flex: 0.15, justifyContent: "center" }

const $forgotPassword: TextStyle = {
  lineHeight: 22,
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 15,
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $submitContainer: ViewStyle = {
  flex: 0.3,
  justifyContent: "center",
}

const $signInBtnContainer: ViewStyle = { marginBottom: 13 }

const $signInBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $signInBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $signInText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $signInTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $registerBtnContainer: ViewStyle = { marginBottom: 10 }

const $registerBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $registerBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $registerText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $registerTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $loader: ViewStyle = {
  marginTop: spacing.medium,
}

const $errorText: TextStyle = {
  color: colors.SECONDARY_CORAL,
}
