import React, { FC } from "react"
import { View, ViewStyle, TextStyle, ImageStyle, Image, TouchableOpacity } from "react-native"
import { Button, Icon, Text } from "@app/components"
import { colors, typography } from "@app/theme"

interface ModalAnonymousProps {
  hideModal: any
  navigateTo: any
}

export const ModalAnonymous: FC<ModalAnonymousProps> = ({ hideModal, navigateTo }) => {
  return (
    <View style={$modalContainerSuccess}>
      <View style={$exitModalContainer}>
        <TouchableOpacity onPress={() => hideModal()}>
          <Icon icon="x" size={23} />
        </TouchableOpacity>
      </View>
      <View style={$titleContainer}>
        <Text text="Ups!" style={$titleText} />
      </View>
      <View style={$imageContainer}>
        <Image source={require("../../assets/images/locked.png")} style={$imageSuccess} />
      </View>
      <View style={$descriptionContainer}>
        <Text style={$textStyle1} text="¡Disfruta " />
        <Text style={$textStyle2} text="Libbre " />
        <Text style={$textStyle1} text="al máximo, agregando " />
        <Text style={$textStyle2} text="favoritos " />
        <Text style={$textStyle1} text="y revisando tu " />
        <Text style={$textStyle2} text="actividad!" />
      </View>
      <View style={$description2Container}>
        <Text style={$description2} text="Inicia sesión para mejorar tu experiencia" />
      </View>
      <View style={$buttonsContainer}>
        <View style={$loginBtnContainer}>
          <Button
            preset="filled"
            text="Iniciar sesión"
            style={$btnLogin}
            onPress={() => {
              hideModal()
              navigateTo("Login")
            }}
            textStyle={$btnLoginText}
          />
        </View>
        <View>
          <Button
            preset="filled"
            text="Seguir como anónimo"
            style={$btnBack}
            onPress={() => hideModal()}
            textStyle={$btnBackText}
          />
        </View>
      </View>
    </View>
  )
}

const $loginBtnContainer: ViewStyle = { marginBottom: 20 }

const $buttonsContainer: ViewStyle = { paddingHorizontal: 10 }

const $titleContainer: ViewStyle = { marginBottom: 30 }

const $exitModalContainer: ViewStyle = { alignItems: "flex-end", paddingRight: 10 }

const $titleText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 35,
  lineHeight: 42,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SECONDARY_VIOLET,
}

const $descriptionContainer: ViewStyle = {
  marginBottom: 20,
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
}

const $description2Container: ViewStyle = {
  marginBottom: 50,
}

const $imageContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 32,
}

const $modalContainerSuccess: ViewStyle = {
  backgroundColor: colors.WHITE,
  borderRadius: 12,
  justifyContent: "center",
  paddingTop: 15,
  paddingBottom: 25,
  paddingHorizontal: 10,
}

const $textStyle1: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 23,
  fontWeight: '400',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}
const $textStyle2: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 23,
  fontWeight: 'bold',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $imageSuccess: ImageStyle = {
  width: 98,
  height: 98,
}

const $btnBack: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.transparent,
}

const $btnLogin: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $btnLoginText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 23,
  fontWeight: 'bold',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $btnBackText: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 23,
  fontWeight: '400',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $description2: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 23,
  fontWeight: 'bold',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}
