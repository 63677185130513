import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
// reducer
import auth from "./slices/auth"
import app from "./slices/app"
import favorites from "./slices/favorites"
import history from "./slices/history"
import home from "./slices/home"
import { verifyAuth } from "./actions/auth"

export const store = configureStore({
  reducer: { auth, app, favorites, history, home },
})

store.dispatch(verifyAuth())

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
