import { Button, Icon } from "@app/components"
import { useNavigation } from "@app/navigators/utils"
import React from "react"
import { View, Modal, ViewStyle, TextStyle, ImageStyle, Image, Alert } from "react-native"
import { Text } from "../../../../components/Text"
import { colors } from "../../../../theme/colors"

export const ModalInfo = (props) => {
  const { isVisible, setVisible, typeModal, email } = props

  const { goBack } = useNavigation()

  return (
    <Modal animationType="fade" visible={isVisible} transparent>
      {/* background y container general */}
      <View style={$backgroundTransparency}>
        <View style={$modalContainer}>
          <View style={$closeIconContainer}>
            <Icon
              icon="x"
              size={20}
              onPress={() => {
                setVisible(false)
              }}
            />
          </View>
          {typeModal === "error" && (
            <>
              <Text style={$modah1Error}>Ups!</Text>
              <Image
                source={require("../../../../../assets/icons/errorIcon.png")}
                style={$modalImageError}
              />
              <Text style={$modalh2Error}>Tuvimos problemas para crear el usuario.</Text>
              <Text style={$modalh3Error}>Intentá en unos segundos nuevamente</Text>
              <Button
                preset="reversed"
                style={$modalButtonTryAgain}
                text="Intentar de nuevo"
                onPress={() => {
                  setVisible(false)
                }}
              />
            </>
          )}
          {typeModal === "user-not-found" && (
            <>
              <Text style={$modah1Error}>Ups!</Text>
              <Image
                source={require("../../../../../assets/icons/errorIcon.png")}
                style={$modalImageError}
              />
              <Text style={$modalh2Error}>Este usuario no se encuentra registrado.</Text>
              <Button
                preset="reversed"
                style={$modalButtonTryAgain}
                text="Intentar de nuevo"
                onPress={() => {
                  setVisible(false)
                }}
              />
            </>
          )}
          {typeModal === "error-email" && (
            <>
              <Text style={$modah1Error}>Ups!</Text>
              <Image
                source={require("../../../../../assets/icons/errorIcon.png")}
                style={$modalImageError}
              />
              <Text style={$modalh2Warning}>El email ingresado ya se encuentra registrado.</Text>
              <Button
                preset="reversed"
                style={$modalButtonTryAgain}
                text="Intentar de nuevo"
                onPress={() => {
                  setVisible(false)
                }}
              />
            </>
          )}

          {typeModal === "success" && (
            <>
              <Text style={$modalh1Success}>Excelente!</Text>
              <Image
                source={require("../../../../../assets/icons/successIcon.png")}
                style={$modalImageSuccess}
              />
              <Text style={$modalh2Success}>Te enviamos un correo a</Text>
              <Text style={$textBold}>{email}</Text>
              <Text style={$modalh2Success}>
                con un <Text style={$textBold}>enlace para activar tu cuenta.</Text>
              </Text>
              <View style={$buttonContainer}>
                <Button
                  preset="reversed"
                  text="Aún no recibí el correo"
                  style={$modalButtonSuccess1}
                  onPress={() => {
                    // send email again code:
                    setTimeout(() => {
                      setVisible(false)
                      goBack()
                    }, 100)
                    Alert.alert("Se envió otro email de verificación. Revise su correo")
                  }}
                />
                <Button
                  preset="filled"
                  style={$modalButtonSuccess2}
                  text="Ya activé mi cuenta"
                  onPress={() => {
                    // goBack login:
                    setTimeout(() => {
                      setVisible(false)
                      goBack()
                    }, 100)
                  }}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  )
}

// Styles:

// Background transparency:
const $backgroundTransparency: ViewStyle = {
  backgroundColor: "rgba(0,0,0, 0.9)",
  width: "100%",
  height: "100%",
}

//! Modal warning:
const $modalContainer: ViewStyle = {
  margin: "auto",
  alignContent: "center",
  justifyContent: "center",
  backgroundColor: "rgba(240,240, 240,0.9)",
  borderRadius: 22,
  padding: "3%",
  position: "relative",
  width: 500,
  height: 450,
}
const $closeIconContainer: ViewStyle = {
  position: "relative",
  alignItems: "flex-end",
}

// title:
const $modah1Error: TextStyle = {
  fontSize: 35,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.error,
}

// subtitle:
const $modalh2Warning: TextStyle = {
  marginTop: "3%",
  fontSize: 20,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.black,
}

const $modalImageError: ImageStyle = {
  marginTop: "3%",
  marginBottom: "5%",
  width: 79,
  height: 79,
  marginStart: "auto",
  marginEnd: "auto",
}

const $modalButtonTryAgain: TextStyle = {
  marginTop: "10%",
  width: 273,
  height: 51,
  marginHorizontal: "auto",
  borderRadius: 30,
  color: "fff",
}

//* Modal success:

// title:
const $modalh1Success: TextStyle = {
  fontSize: 24,
  fontWeight: "bold",
  textAlign: "center",
  color: "green",
}

// subtitle:
const $modalh2Success: TextStyle = {
  marginTop: "3%",
  fontSize: 17,
  textAlign: "center",
  color: colors.black,
}

const $modalImageSuccess: ImageStyle = {
  width: 98,
  height: 98,
  marginStart: "auto",
  marginEnd: "auto",
  marginTop: "5%",
  position: "relative",
}

const $buttonContainer: ViewStyle = {
  alignSelf: "center",
  alignContent: "center",
  alignItems: "center",
  marginHorizontal: 'auto',
  marginVertical: 'auto'
}

const $modalButtonSuccess1: ViewStyle = {
  marginTop: "10%",
  width: 273,
  height: 51,
  borderRadius: 30,
  backgroundColor: "#12223f",
}

const $modalButtonSuccess2: ViewStyle = {
  marginTop: "5%",
  width: 273,
  height: 51,
  borderRadius: 30,
  backgroundColor: "#21f4b0",
}

// subtitle:
const $modalh2Error: TextStyle = {
  marginTop: "3%",
  fontSize: 20,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.black,
}

const $modalh3Error: TextStyle = {
  marginTop: "3%",
  fontSize: 18,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.black,
}

const $textBold: TextStyle = {
  fontSize: 17,
  fontWeight: "bold",
  color: colors.black,
  textAlign: "center",
}
