/* eslint-disable react-native/no-inline-styles */
import React, { Fragment } from "react"
import {
  View,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
  ImageStyle,
} from "react-native"
import { Text, Button, Icon } from "@app/components"
import { colors, typography } from "@app/theme"
import { useNavigation } from "@app/navigators/utils"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { Grid } from "@mui/material"

import {
  $btnWatch,
  $btnWatchPressedStyle,
  $btnWatchPressedTextStyle,
  $btnWatchTextStyle,
  $descriptionTextStyle,
  $separator,
  $titleTextStyle,
  $videoContainer,
} from "../styles"
import { Favorite } from "@app/interfaces/favorite.interface"

export const FavoritesComponent = ({ favorites, removeFavorite, navigation, favStates }) => {
  const { navigateTo } = useNavigation(navigation)
  const { width } = useWindowDimensions()

  const convertVideoType = (type: string): string => {
    switch (type) {
      case "video":
        return "Video"
      case "document":
        return "Documento"
      case "serie":
        return "Serie"
      default:
        return ""
    }
  }

  const handleNavigate = (id: number, type: string) => {
    switch (type) {
      case "video":
        navigateTo("Player", { videoId: id })
        break
      case "document":
        navigateTo("Document", { documentId: id })
        break
      case "serie":
        navigateTo("Serie", { serieId: id })
        break
      default:
        break
    }
  }

  return (
    <View style={$videoContainer}>
      {favorites.favorites.length > 0 &&
        favorites.favorites.map((favorite: Favorite, index: number) => {
          let entity = null
          let type = null
          if (favorite.video) {
            type = "video"
            entity = favorite.video
          } else if (favorite.document) {
            type = "document"
            entity = favorite.document
          } else if (favorite.serie) {
            type = "serie"
            entity = favorite.serie
          }
          return (
            <Fragment key={index}>
              <Grid container justifyContent="center">
                <Grid item xs="auto">
                  <TouchableOpacity onPress={() => removeFavorite(entity.id, type)}>
                    <ImageBackground
                      style={$backgroundImage}
                      imageStyle={{ borderRadius: 7.3 }}
                      source={{ uri: entity.thumbnail }}
                      resizeMode="cover"
                    >
                      {favStates[type] && favStates[type][entity.id] ? (
                        <View style={$videoStyle}>
                          <ActivityIndicator size={14} color={colors.MAIN_AQUAMARINE} />
                        </View>
                      ) : (
                        <View style={$videoStyle}>
                          <Icon icon="heart" color={colors.MAIN_AQUAMARINE} size={14} />
                        </View>
                      )}
                    </ImageBackground>
                  </TouchableOpacity>
                </Grid>
                <Grid
                  item
                  xs={width >= 655 ? 8 : 7}
                  container
                  justifyContent="center"
                  direction="column"
                  sx={{ paddingLeft: 2 }}
                >
                  <View
                    style={{
                      marginBottom: 6,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text text={entity.title} numberOfLines={2} style={$titleTextStyle} />
                    <div
                      // eslint-disable-next-line react-native/no-color-literals
                      style={{
                        marginLeft: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.19)",
                        paddingLeft: 3,
                        paddingRight: 3,
                        borderRadius: 5,
                        paddingTop: 1,
                        paddingBottom: 1,
                        display: "flex",
                        alignItems: "center",
                        backdropFilter: "blur(5px)",
                      }}
                    >
                      <Text
                        // TODO: Ver como conseguir el tipo
                        text={convertVideoType(type)}
                        style={{
                          fontFamily: typography.fonts.montserrat.normal,
                          color: colors.WHITE,
                          fontSize: 12,
                          lineHeight: 13,
                        }}
                      />
                    </div>
                  </View>
                  {width >= 650 && (
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          text={entity.description}
                          numberOfLines={2}
                          style={$descriptionTextStyle}
                        />
                      </View>
                    </View>
                  )}
                </Grid>
                <Grid
                  item
                  xs={width >= 655 ? 2 : 1}
                  container
                  justifyContent="flex-end"
                  alignContent="center"
                >
                  <Button
                    onPress={() => handleNavigate(entity.id, type)}
                    text="Ver"
                    preset="reversed"
                    style={$btnWatch}
                    pressedStyle={$btnWatchPressedStyle}
                    textStyle={$btnWatchTextStyle}
                    pressedTextStyle={$btnWatchPressedTextStyle}
                  />
                </Grid>
              </Grid>
              <View style={$separator} />
            </Fragment>
          )
        })}
    </View>
  )
}

const $backgroundImage: ImageStyle = {
  width: 97,
  height: 97,
  justifyContent: "center",
  marginVertical: 20,
  alignItems: "center",
}

const $videoStyle: ImageStyle = {
  height: 35,
  width: 35,
  backgroundColor: colors.DARK_GRAY,
  borderRadius: 33,
  justifyContent: "center",
  alignItems: "center",
}
