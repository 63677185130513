import React from "react"
import JWPlayer from "@jwplayer/jwplayer-react"

const JWPlayerComponent = (props) => {
  const { didMountCallback, onPause } = props

  const newProps = Object.assign({}, props)
  delete newProps.ref
  delete newProps.key
  delete newProps.config
  delete newProps.style
  delete newProps.didMountCallback

  const configDefaults = { width: 560, height: 520 }

  return (
    <JWPlayer
      config={{ configDefaults }}
      library="https://cdn.jwplayer.com/libraries/rDw6LS5T.js"
      {...newProps}
      onPause={onPause}
      didMountCallback={didMountCallback}
      onSetupPlayerError={(e) => console.log(e)}
      onPlayerError={(e) => alert(e.nativeEvent?.error || "Player Error.")}
    />
  )
}

export default JWPlayerComponent
