import React, { FC, CSSProperties, Fragment } from "react"
import "swiper/css"
import "swiper/css/navigation"
import "./css/carouselSlider.css"
import { ViewStyle } from "react-native"
import { colors, typography } from "@app/theme"
import LinearGradient from "react-native-linear-gradient"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import { CircularProgress } from "@mui/material"
import useHome from "@app/screens/Home/hooks/use-home"
import { Icon } from "@app/components"

interface Props {
  entity: any
  navigateTo: any
  type: string
}

export const SliderContent: FC<Props> = ({ entity, navigateTo, type }) => {
  const { isInFavorites, addFavorite, removeFavorite, auth, hideModal, showModal, favStates } =
    useHome()

  const $cardContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: 180,
    width: "100%",
    backgroundColor: colors.WHITE,
    aspectRatio: 16 / 9,
    backgroundImage: `url(${entity.thumbnail})`,
    backgroundAttachment: "local",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 7,
  }

  return (
    <Fragment>
      <LinearGradient
        colors={["#00000000", "#000000"]}
        locations={[0.5, 1]}
        style={$bannerGradient}
      />
      <div style={$cardContainer}>
        <div style={$iconContainer}>
          {auth.isAnonymous ? (
            <div
              style={$iconFavoriteContainer}
              onClick={(e) => {
                showModal(
                  <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                  null,
                  600,
                )
                e.stopPropagation()
              }}
            >
              <Icon icon="heartOutlinedWeb" color={colors.WHITE} size={24} />
            </div>
          ) : favStates[type] && favStates[type][entity.id] ? (
            <div
              style={$iconFavoriteContainer}
              onClick={(e) => {
                removeFavorite(entity.id, type)
                e.stopPropagation()
              }}
            >
              <CircularProgress size={25} value={25} style={{ color: colors.MAIN_AQUAMARINE }} />
            </div>
          ) : isInFavorites(entity.id, type) ? (
            <div
              style={$iconFavoriteContainer}
              onClick={(e) => {
                removeFavorite(entity.id, type)
                e.stopPropagation()
              }}
            >
              <Icon icon="heartWeb" color={colors.MAIN_AQUAMARINE} size={21} />
            </div>
          ) : (
            <div
              style={$iconFavoriteContainer}
              onClick={(e) => {
                addFavorite(entity.id, type)
                e.stopPropagation()
              }}
            >
              <Icon icon="heartOutlinedWeb" color={colors.WHITE} size={24} />
            </div>
          )}
        </div>
        <div style={$titleContainer}>
          <p style={$videoTitle} className="break-word">
            {entity.title}
          </p>
        </div>
      </div>
    </Fragment>
  )
}

const $iconContainer: CSSProperties = {
  alignSelf: "flex-end",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  zIndex: 3,
}

const $iconFavoriteContainer: CSSProperties = {
  backgroundColor: colors.MEDIUM_GRAY,
  width: 40,
  height: 40,
  borderRadius: 100,
  marginTop: 10,
  marginRight: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const $titleContainer: CSSProperties = {
  height: "100%",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  zIndex: 2,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
}

const $bannerGradient: ViewStyle = {
  height: "100%",
  position: "absolute",
  width: "100%",
  opacity: 1,
  borderRadius: 6,
}

const $videoTitle: CSSProperties = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 14,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  color: colors.WHITE,
  margin: 0,
  textAlign: "start",
}
