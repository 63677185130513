import React, { FC, Fragment } from "react"
import { colors, typography } from "@app/theme"
import {
  View,
  ViewStyle,
  ImageBackground,
  ImageStyle,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native"
import { Grid } from "@mui/material"
import { Text, Button, Icon } from "@app/components"
import { VideosContent } from "./VideosContent"
import { Document } from "@app/interfaces/document.interface"
import useDocument from "../hooks/use-document"
import { ModalAnonymous } from "@app/components/ModalAnonymous"

interface DocumentContentProps {
  document: Document
  navigateTo: any
}

export const DocumentContent: FC<DocumentContentProps> = ({ document, navigateTo }) => {
  const {
    onDownloadDocument,
    auth,
    showModal,
    hideModal,
    favStates,
    isInFavorites,
    removeFavorite,
    addFavorite,
  } = useDocument()

  const type = "document"

  return (
    <Grid container spacing={4} style={$container}>
      <Grid item container xs={12} md={4} justifyContent="center">
        <View style={$documentThumbnailContainer}>
          <ImageBackground
            source={{ uri: document.thumbnail }}
            resizeMode="cover"
            style={$documentThumbnail}
            imageStyle={$documentThumbnailImg}
          >
            {auth.isAnonymous ? (
              <TouchableOpacity
                onPress={() =>
                  showModal(
                    <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                    null,
                    600,
                  )
                }
              >
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartOutlined" size={23} color={colors.WHITE} />
                </View>
              </TouchableOpacity>
            ) : favStates[type] && favStates[type][document.id] ? (
              <TouchableWithoutFeedback>
                <View style={$favoriteDocContainer}>
                  <ActivityIndicator size={16} color={colors.MAIN_AQUAMARINE} />
                </View>
              </TouchableWithoutFeedback>
            ) : isInFavorites(document.id, type) ? (
              <TouchableOpacity onPress={() => removeFavorite(document.id, type)}>
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartWeb" size={20} color={colors.MAIN_AQUAMARINE} />
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => addFavorite(document.id, type)}>
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartOutlined" size={23} color={colors.SHADES_OF_BLACK} />
                </View>
              </TouchableOpacity>
            )}
          </ImageBackground>
        </View>
      </Grid>
      <Grid
        item
        container
        spacing={4}
        xs={12}
        md={8}
        direction="column"
        justifyContent="space-evenly"
      >
        <Grid item>
          <Text text={document.title} style={$titleStyle} />
        </Grid>
        <Grid item>
          <Text text={document.description} style={$descriptionTextStyle} />
        </Grid>
        <Grid item>
          <Button
            onPress={() => onDownloadDocument(document.document_url)}
            LeftAccessory={() => (
              <View style={$iconBtnDownloadContainer}>
                <Icon icon="document" color={colors.MAIN_DARK_BLUE} size={20} />
              </View>
            )}
            preset="filled"
            style={$downloadBtn}
            pressedStyle={$downloadBtnPressed}
            textStyle={$downloadBtnText}
            text="Descargar"
          />
        </Grid>
      </Grid>
      {document.videos.length > 0 && (
        <VideosContent videos={document.videos} navigateTo={navigateTo} />
      )}
    </Grid>
  )
}

const $iconBtnDownloadContainer: ViewStyle = { paddingRight: 5 }

const $favoriteDocContainer: ViewStyle = {
  height: 50,
  width: 50,
  borderRadius: 100,
  backgroundColor: colors.LIGHT_GRAY,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
}

const $videoHeaderText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $downloadBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontWeight: "bold",
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $downloadBtn: ViewStyle = {
  height: 51,
  maxWidth: 200,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $downloadBtnPressed: ViewStyle = {
  height: 51,
  maxWidth: 200,
  borderRadius: 30,
  backgroundColor: colors.WHITE,
}

const $titleStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 24,
  lineHeight: 28,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
  paddingBottom: 20,
}

const $descriptionTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 16,
  lineHeight: 20,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $documentThumbnailContainer: ViewStyle = {
  borderRadius: 20,
  aspectRatio: 3 / 4,
  maxWidth: 250,
  width: "100%",
  backgroundColor: colors.WHITE,
}

const $documentThumbnail: ViewStyle = {
  width: "100%",
  // height: 280,
  aspectRatio: 3 / 4,
  borderRadius: 20,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  paddingBottom: 13,
  paddingRight: 13,
}

const $documentThumbnailImg: ImageStyle = {
  borderRadius: 20,
}

const $container: ViewStyle = { paddingTop: 15 }
