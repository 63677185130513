import axios from "@app/services/axios"
import { Video } from "@app/interfaces/video.interface"
import { Document } from "@app/interfaces/document.interface"

export const fetchDocumentById = async (id: number) => {
  const response = await axios.get<{ data: Video; status: number }>(`/api/documents/${id}`)
  return response
}
export const fetchAllDocuments = async () => {
  const response = await axios.get<{ data: Document; status: number }>(`/api/documents`)
  return response
}
