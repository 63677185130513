import { setAuth } from "@app/store/slices/auth"
import { setIsLoading } from "@app/store/slices/app"
import { ILogin } from "@app/store/interface/auth"
import { IDispatch } from "@app/store/interface"
import { firebaseErrorHandler } from "@app/utils/firebase/errorHandler"
import { User } from "@app/interfaces/user.interface"
import auth from "@app/services/firebase/auth/Auth"
import { fetchFavoritesById } from "@app/services/api/favorites"
import { fetchHistoryById } from "@app/services/api/history"
import { setFavorites } from "@app/store/slices/favorites"
import { setHistoryReducer } from "@app/store/slices/history"
import { loginUser } from "@app/services/api/users"
import AsyncStorage from "@react-native-async-storage/async-storage"

export const login = (data: ILogin, setError: any, onError: any, navigateTo: any) => (dispatch) => {
  dispatch(setIsLoading(true))
  setError("")
  auth()
    .signInWithEmailAndPassword(data.email, data.password)
    .then(() => {
      dispatch(verifyAuth(setError, onError, navigateTo))
    })
    .catch((error) => {
      dispatch(setIsLoading(false))
      console.log(error.code)
      setError(firebaseErrorHandler(error.code))
    })
}

export const refreshUser = (updateUser: User) => (dispatch: IDispatch) => {
  dispatch(
    setAuth({
      isAuth: true,
      user: {
        id: updateUser.id,
        auth_id: updateUser.auth_id,
        avatar_url: updateUser.avatar_url,
        name: updateUser.name,
        last_name: updateUser.last_name,
        email: updateUser.email,
        notes: updateUser.notes,
        rol: updateUser.rol,
      },
      isAnonymous: false,
    }),
  )
}

export const verifyAuth =
  (setError?: any, onError?: any, navigateTo?: any) => (dispatch: IDispatch) => {
    dispatch(setIsLoading(true))
    auth().onAuthStateChanged(async (userFirebase) => {
      if (userFirebase) {
        if (!userFirebase.emailVerified) {
          dispatch(setIsLoading(false))
          dispatch(
            setAuth({
              isAuth: false,
              user: {},
              isAnonymous: true,
            }),
          )
          setError && setError(firebaseErrorHandler("auth/email-no-verify"))
          return
        }
        try {
          const token = await userFirebase.getIdToken()
          console.log("TOKEN", token)
          await AsyncStorage.setItem("token", token)
          const res = await loginUser(token)
          const userDB = res.data.data
          const resFavorites = await fetchFavoritesById(userDB.id)
          const resHistory = await fetchHistoryById(userDB.id)
          dispatch(
            setAuth({
              isAuth: true,
              user: {
                id: userDB.id,
                auth_id: userDB.auth_id,
                avatar_url: userDB.avatar_url,
                name: userDB.name,
                last_name: userDB.last_name,
                email: userDB.email,
                notes: userDB.notes,
                rol: userDB.rol,
              },
              isAnonymous: false,
            }),
          )
          dispatch(
            setFavorites({
              favorites: resFavorites.data.data.sort((a, b) => {
                return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
              }),
            }),
          )
          dispatch(
            setHistoryReducer({
              userHistory: resHistory.data.data.sort((a, b) => {
                return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
              }),
            }),
          )
          dispatch(setIsLoading(false))
          navigateTo && navigateTo("Home")
        } catch (err) {
          await AsyncStorage.removeItem("token")
          console.log(err)
          dispatch(setIsLoading(false))
          dispatch(
            setAuth({
              isAuth: false,
              user: {},
              isAnonymous: true,
            }),
          )
          onError && onError()
        }
      } else {
        await AsyncStorage.removeItem("token")
        dispatch(setIsLoading(false))
        dispatch(
          setAuth({
            isAuth: false,
            user: {},
            isAnonymous: true,
          }),
        )
      }
    })
  }
