import { User } from "@app/interfaces/user.interface"
import { createSlice } from "@reduxjs/toolkit"

interface authState {
  isAuth: boolean
  user: User
  isAnonymous: boolean
}

interface payloadAuth {
  payload: {
    isAuth: boolean
    user: User
    isAnonymous: boolean
  }
}

const initialState: authState = {
  isAuth: null,
  user: {},
  isAnonymous: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: payloadAuth) => {
      state.isAuth = action.payload.isAuth
      state.user = action.payload.user
      state.isAnonymous = action.payload.isAnonymous
    },
  },
})

export const { setAuth } = authSlice.actions

export default authSlice.reducer
