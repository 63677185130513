import React, { FC } from "react"
import { View, ViewStyle, TextStyle, ImageStyle, Image } from "react-native"
import { Button, Text } from "@app/components"
import { colors, typography } from "@app/theme"

interface ModalShowInfoProps {
  hideModal: any
  callBack?: any
  type: "error" | "success"
  title?: string
  message?: string
  btnText?: string
  
}

export const ModalShowInfo: FC<ModalShowInfoProps> = ({
  hideModal,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callBack = () => {},
  type,
  title = "",
  message = "",
  btnText = "Volver",
}) => {
  return (
    <View style={$modalContainerSuccess}>
      <View style={$imageContainer}>
        {type === "success" ? (
          <Image source={require("../../assets/icons/successIcon.png")} style={$imageSuccess} />
        ) : type === "error" ? (
          <Image source={require("../../assets/icons/errorIcon.png")} style={$imageSuccess} />
        ) : null}
      </View>
      {title && (
        <View style={$titleTextContainer}>
          <Text style={$titleTextStyle} text={title} />
        </View>
      )}
      {message && (
        <View style={$textContainer}>
          <Text style={$textStyle} text={message} />
        </View>
      )}
      <View>
        <Button
          preset="filled"
          text={btnText}
          style={$btnBack}
          onPress={() => {
            hideModal()
            callBack && callBack()
          }}
        />
      </View>
    </View>
  )
}

const $titleTextContainer: ViewStyle = {
  marginBottom: 10,
}

const $titleTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 23,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $textContainer: ViewStyle = { marginBottom: 20 }

const $imageContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 32,
}

const $modalContainerSuccess: ViewStyle = {
  backgroundColor: colors.WHITE,
  borderRadius: 12,
  justifyContent: "center",
  paddingVertical: 25,
  paddingHorizontal: 10,
}

const $textStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 23,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $imageSuccess: ImageStyle = {
  width: 98,
  height: 98,
}

const $btnBack: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}
