import { useState, useRef } from "react"
import { useIsMounted } from "@app/utils/isMounted"
import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { getHomeDataAction } from "@app/store/actions/home"

const useHome = () => {
  const auth = useAppSelector((state) => state.auth)
  const user = useAppSelector((state) => state.auth.user)
  const isLoading = useAppSelector((state) => state.home.isLoading)
  const favorites = useAppSelector((state) => state.favorites)
  const history = useAppSelector((state) => state.history)
  const sliders = useAppSelector((state) => state.home.sliders)
  const banners = useAppSelector((state) => state.home.banners)
  const { showModal, hideModal } = useModal()
  const dispatch = useAppDispatch()
  const isMounted = useIsMounted()
  const sliderCarousel = useRef([])
  const favCarousel = useRef(null)
  const historyCarousel = useRef(null)
  const bannerCarouselRef = useRef(null)
  const [favStates, setFavStates] = useState({})
  const [paginationState, setPaginationState] = useState({
    entries: 0,
    activeSlide: 0,
  })

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const initializeHome = async () => {
    try {
      dispatch(getHomeDataAction())
    } catch (err) {
      console.error(err)
    }
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (entityId: number, type: string) => {
    dispatch(addNewFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  return {
    sliderCarousel,
    isLoading,
    sliders,
    banners,
    initializeHome,
    paginationState,
    setPaginationState,
    bannerCarouselRef,
    user,
    isInFavorites,
    addFavorite,
    removeFavorite,
    auth,
    showModal,
    hideModal,
    favStates,
    handleFavState,
    history,
    favorites,
    favCarousel,
    historyCarousel,
    isMounted,
  }
}

export default useHome
