import { createSlice } from "@reduxjs/toolkit"
import { Favorite } from "@app/interfaces/favorite.interface"

interface favoritesState {
  favorites: Favorite[]
}

interface payloadFavorites {
  payload: {
    favorites: Favorite[]
  }
}

interface payloadAddRemoveFavorites {
  payload: {
    favorites: Favorite
  }
}

const initialState: favoritesState = {
  favorites: [],
}

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setFavorites: (state, action: payloadFavorites) => {
      state.favorites = action.payload.favorites
    },
    addFavorite: (state, action: payloadAddRemoveFavorites) => {
      const favorites = [action.payload.favorites, ...state.favorites]
      state.favorites = favorites
    },
    removeFavorite: (state, action: payloadAddRemoveFavorites) => {
      state.favorites = state.favorites.filter((favorite) => {
        return favorite.id !== action.payload.favorites.id
      })
    },
  },
})

export const { setFavorites, addFavorite, removeFavorite } = favoritesSlice.actions

export default favoritesSlice.reducer
