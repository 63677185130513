import React, { useMemo, CSSProperties } from "react"
import { colors, typography } from "@app/theme"

// Components:
import { View, Image, ViewStyle, TextStyle, ImageStyle, ActivityIndicator } from "react-native"
import { Button, Icon, Text, TextField } from "@app/components"
import { useNavigation } from "@app/navigators/utils"

// useRegister:
import useRegister from "../hooks/useRegister"
import { TextFieldAccessoryProps } from "../../../../components/TextField"
import { ModalInfo } from "./Modal"
import { isWeb } from "@app/utils/isWeb"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"

// Avatar default:
const RegisterForm = () => {
  const { width, height } = useWindowDimensions()
  const logoHeader = require("../../../../../assets/images/web/logotipoWeb@3x.png")
  const FOOTER_IMAGE = require("../../../../../assets/images/web/background-activity.png")

  const $container: CSSProperties = {
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${FOOTER_IMAGE})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: width,
    width,
    height,
    paddingTop: "2%",
    display: 'flow-root'
  }

  const { goBack } = useNavigation()
  const {
    isLoading,
    isVisible,
    setVisible,
    typeModal,
    setTypeModal,
    emailRegister,
    image,
    isAuthPasswordHidden,
    setImage,
    setIsAuthPasswordHidden,
    selectUploadType,
    avatar,
    setAvatar,
    AVATAR_DEFAULT,
    formik: { values, handleBlur, handleChange, handleSubmit, errors, touched },
  } = useRegister()

  // Utilizo este hook para evitar que se vuelva a renderizar todo
  const PasswordRightAccessory = useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Icon
            icon={isAuthPasswordHidden ? "view" : "hidden"}
            color={colors.WHITE}
            containerStyle={props.style}
            onPress={() => setIsAuthPasswordHidden(!isAuthPasswordHidden)}
          />
        )
      },
    [isAuthPasswordHidden],
  )

  return (
    <>
      <ModalInfo
        isVisible={isVisible}
        setVisible={setVisible}
        typeModal={typeModal}
        setTypeModal={setTypeModal}
        email={emailRegister}
      />
      <View style={$containerHeader}>
        <View style={$containerIconBack}>
          <Icon icon="back" style={iconBack} onPress={() => goBack()} />
        </View>
        <View style={$containerLogoImageHeader}>
          <Image source={logoHeader} style={$logoHeader} />
        </View>
      </View>
      <div style={$container}>
        <View style={$containerTitle}>
          <Text tx="registerScreen.titleScreen" style={$formTitle} />
        </View>

        <View style={$containerForm}>
          <View style={$containterImage}>
            <Image source={avatar} style={$imageStyle} />
            {image ? (
              <View>
                <Icon
                  icon="edit"
                  style={$iconDeleteAvatar}
                  onPress={() => {
                    setImage(null)
                    setAvatar(AVATAR_DEFAULT)
                  }}
                />
              </View>
            ) : (
              <View style={$iconCamera}>
                {isWeb() && (
                  <input
                    id="file-upload"
                    type="file"
                    style={$inputStyleImage}
                    accept="image/png, image/jpeg"
                  />
                )}
                <Icon icon="camera" size={24} onPress={selectUploadType} />
              </View>
            )}
          </View>
          <View style={$containerInputs}>
            <View style={$inputGroup1}>
              <View style={$marginBottom5}>
                <Text style={$labelInput} tx="registerScreen.nameFieldLabel" />
                <TextField
                  style={$inputStyle}
                  onChangeText={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                  helper={touched.name && errors.name}
                  status={touched.name && errors.name ? "error" : null}
                  placeholderTextColor={colors.GRAY}
                  placeholderTx="registerScreen.nameFieldPlaceHolder"
                  inputWrapperStyle={$inputWrapper}
                />
              </View>
              <Text style={$labelInput} tx="registerScreen.emailFieldLabel" />
              <TextField
                style={$inputStyle}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                autoCapitalize="none"
                autoCorrect={false}
                helper={touched.email && errors.email}
                value={values.email}
                status={touched.email && errors.email ? "error" : null}
                placeholderTextColor={placeholderColor}
                placeholderTx="registerScreen.emailFieldPlaceHolder"
                inputWrapperStyle={$inputWrapper}
              />
            </View>
            <View style={$inputGroup2}>
              <View style={$marginBottom5}>
                <Text style={$labelInput} tx="registerScreen.lastNameFieldLabel" />
                <TextField
                  style={$inputStyle}
                  onChangeText={handleChange("last_name")}
                  onBlur={handleBlur("last_name")}
                  value={values.last_name}
                  helper={touched.last_name && errors.last_name}
                  status={touched.last_name && errors.last_name ? "error" : null}
                  placeholderTextColor={placeholderColor}
                  placeholderTx="registerScreen.lastNameFieldPlaceHolder"
                  inputWrapperStyle={$inputWrapper}
                />
              </View>

              <Text style={$labelInput} tx="registerScreen.passwordFieldLabel" />
              <TextField
                style={$inputStyle}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                autoCorrect={false}
                helper={touched.password && errors.password}
                status={touched.password && errors.password ? "error" : null}
                value={values.password}
                placeholderTx="registerScreen.passwordFieldPlaceHolder"
                placeholderTextColor={placeholderColor}
                secureTextEntry={isAuthPasswordHidden}
                RightAccessory={PasswordRightAccessory}
                inputWrapperStyle={$inputWrapper}
              />
            </View>
          </View>
        </View>

        <View style={$containerSubmitAndLoader}>
          {isLoading ? (
            <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
          ) : (
            <View style={$submitContainer}>
              <Button
                style={$buttonConfirm}
                textStyle={$textButtonConfirm}
                preset="filled"
                tx="registerScreen.tapToConfirm"
                onPress={handleSubmit}
              />
            </View>
          )}
        </View>
      </div>
    </>
  )
}

export default RegisterForm

//* Styles:

const $inputStyleImage = {
  display: "none",
}

// spinner:
const $loader: ViewStyle = {
  flex: 1,
  backgroundColor: colors.MAIN_DARK_BLUE,
}
// button submit:
const $submitContainer: ViewStyle = {
  flex: 1,
  backgroundColor: colors.transparent,
  marginTop: "10%",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
}

const $buttonConfirm = {
  width: 391,
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $containerHeader: ViewStyle = {
  height: 100,
  backgroundColor: colors.MAIN_DARK_BLUE,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
}

const $containerSubmitAndLoader: ViewStyle = {
  flex: 1,
  justifyContent: "center",
}

const $containerTitle: ViewStyle = {
  justifyContent: "center",
  marginBottom: "5%",
}

const $containerIconBack: ViewStyle = {
  marginLeft: 17,
  justifyContent: "flex-start",
  flexDirection: "row",
}

const $containerForm: ViewStyle = {
  padding: "3%",
  width: "100%",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}

const $formTitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 48,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $labelInput: TextStyle = {
  marginTop: 5,
  marginBottom: 5,
  fontSize: 16,
  fontFamily: typography.fonts.montserrat.bold,
  color: colors.WHITE,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
}

const $inputStyle: TextStyle = {
  backgroundColor: colors.MAIN_DARK_BLUE,
  color: colors.WHITE,
}

const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: colors.MAIN_DARK_BLUE,
  minHeight: 10,
}

const placeholderColor = colors.LIGHT_GRAY

const $containterImage: ViewStyle = {
  width: "auto",
  height: "auto",
}

const $imageStyle: ImageStyle = {
  width: 178.3,
  height: 178.3,
  borderRadius: 95,
}

const iconBack: ImageStyle = {
  width: 40,
  height: 40,
  position: "relative",
  marginRight: "auto",
  marginLeft: "5%",
}

const $iconDeleteAvatar: ImageStyle = {
  width: 30,
  height: 30,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-10%",
  position: "relative",
  borderRadius: 18,
  backgroundColor: "lightgrey",
}

const $iconCamera: ImageStyle = {
  width: 32,
  height: 32,
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-10%",
  position: "relative",
  borderRadius: 18,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $containerInputs: ViewStyle = {
  width: "70%",
  padding: 10,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}

const $inputGroup1: ViewStyle = {
  width: "50%",
  paddingRight: "2.7%",
}

const $inputGroup2: ViewStyle = {
  width: "50%",
}

const $containerLogoImageHeader: ViewStyle = {
  width: 196,
  height: 74,
  display: "flex",
  marginRight: "4.3%",
}

const $logoHeader: ImageStyle = { width: 196, height: 74 }

const $textButtonConfirm: TextStyle = {
  fontFamily: "Montserrat",
  fontSize: 18,
  fontWeight: "bold",
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $marginBottom5: ViewStyle = { marginBottom: "5%" }
