import { Screen, Text } from "@app/components"
import { colors, spacing } from "@app/theme"
import React, { FC } from "react"
import { Alert, ImageStyle, TextStyle, View, ViewStyle } from "react-native"
import { AppStackScreenProps } from "../../../navigators/AppNavigator"
import { Button } from "../../../components/Button"
import { typography } from "../../../theme/typography"
import { useNavigation } from "@app/navigators/utils"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { APP_BAR_HEIGHT, THUMBNAIL_PLACEHOLDER } from "@app/utils/constants"
import { Link } from "react-router-dom"
// toDo: Adaptar botones e imagen

// Interface:
interface SendEmailSuccessProps extends AppStackScreenProps<"SendEmailSuccess"> {}

const backgroundImage = require("../../../../assets/images/web/background-screens.png")
const logo = require("../../../../assets/images/web/logotipoWeb.png")
const imgSuccess = require("../../../../assets/icons/web/Status@2x.png")

const SendEmailSuccess: FC<SendEmailSuccessProps> = (props) => {
  const { params, navigateTo } = useNavigation()
  const { height } = useWindowDimensions()

  const insets = useSafeAreaInsets()

  const $screenContentContainerWeb = {
    flexGrow: 1,
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${backgroundImage})`,
    minHeight: height,
    paddingBottom: spacing.large,
    paddingTop: insets.top,
    backgroundSize: "100% 100%",
    backgrounAttachment: "fixed",
    backgroundPosition: "center, center",
  }

  return (
    <Screen preset="scroll" contentContainerStyle={$screenContentContainerWeb}>
      <View style={$header}>
        <View style={$logo}>
          <Link to="/" relative="path">
            <img src={logo} style={$iconLogo} alt="home" />
          </Link>
        </View>
      </View>

      <View style={$containerImageMiddle}>
        <img src={imgSuccess} style={$successLogo} alt="success" />
      </View>
      <View>
        <Text style={$titleh1} tx="sendEmailSuccessScreen.sendEmail" />
      </View>
      <View style={$textContainer}>
        <Text style={$titleh2} text="Te enviamos un correo a" />
        <Text style={$txtBold}>{params && params.email}</Text>
        <Text style={$titleh2} text="con un enlace para recuperar tu contraseña" />
      </View>
      <View>
        <Text style={$titleh3} text="Accedé al enlace enviado para comenzar " />
        <Text style={$secondTitleh3} text="el proceso de recuperación" />
      </View>

      <View style={$containerButtons}>
        <Button
          preset="reversed"
          style={$btn1}
          text="Aún no recibí el correo"
          textStyle={$textBtn1}
          onPress={() => {
            // toDo: implementar alguna funcionalidad
            Alert.alert(
              "Se ha enviado otro email. Verifique su correo",
              null,
              [{ text: "Aceptar", onPress: () => navigateTo("Login") }],
              { cancelable: false },
            )
          }}
        />
        <Button
          preset="default"
          style={$btn2}
          onPress={() => {
            navigateTo("Login")
          }}
          text="Finalizar"
          textStyle={$textBtn2}
        />
      </View>
    </Screen>
  )
}

export default SendEmailSuccess

const $headerStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 30,
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
  /* marginBottom: 49, */
}

const $textContainer: ViewStyle = {
  alignItems: "center",
  paddingHorizontal: "5%",
  marginTop: 20,
}

const $txtBold: TextStyle = {
  fontWeight: "bold",
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  fontStyle: "normal",
  letterSpacing: 0,
  color: colors.WHITE,
}

const $titleh1: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 48,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.GREEN_OK,
}

const $titleh2: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}
const $titleh3: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 24,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  marginTop: "3%",
  paddingHorizontal: "5%",
  color: colors.DARK_GRAY,
}
const $secondTitleh3: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 24,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  marginBottom: "3%",
  paddingHorizontal: "5%",
  color: colors.DARK_GRAY,
}
const $containerButtons: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}

const $containerImageFooter: ViewStyle = {
  width: 390,
  height: 63,
  flex: 0.7,
}

const $btn1: ViewStyle = {
  marginBottom: 14,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
  width: "30%",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  minHeight: 10,
}

const $btn2: ViewStyle = {
  borderRadius: 30,
  marginBottom: 14,
  backgroundColor: colors.MAIN_AQUAMARINE,
  width: "30%",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  minHeight: 10,
}

const $containerImageMiddle: ViewStyle = {
  margin: "5%",
}

const $imgSuccess: ImageStyle = {
  marginLeft: "auto",
  marginRight: "auto",
}

const $textBtn1: TextStyle = {
  fontSize: 16,
  fontWeight: "bold",
}
const $header: ViewStyle = {
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  height: 55,
}
const $logo: ViewStyle = {
  flex: 1,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
}
const $iconLogo: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  marginTop: 65,
  marginRight: 60,
  height: 50,
  borderWidth: 1,
  minHeight: 10,
  objectFit: "contain",
}
const $successLogo: React.CSSProperties = {
  marginLeft: "auto",
  marginRight: "auto",
  width: 123,
  height: 123,
}
const $textBtn2: TextStyle = { fontSize: 16, fontWeight: "bold" }
