import { useState, useEffect } from "react"
import { isWeb } from "./isWeb"
import { Dimensions } from "react-native"

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const { height, width } = Dimensions.get("screen")

  const [windowDimensions, setWindowDimensions] = useState(
    isWeb() ? getWindowDimensions() : { width, height },
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(isWeb() ? getWindowDimensions() : { width, height })
    }
    if (isWeb()) window.addEventListener("resize", handleResize)

    return () => {
      if (isWeb()) window.removeEventListener("resize", handleResize)
    }
  }, [])

  return windowDimensions
}
