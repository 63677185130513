import React, { FC, Fragment, useEffect, useState } from "react"
import { ViewStyle, View, TouchableOpacity, TextStyle, ImageBackground } from "react-native"
import { Screen, Text, Icon } from "@app/components"
import { spacing, typography, colors } from "@app/theme"
import { useNavigation } from "@app/navigators/utils"
import { APP_BAR_HEIGHT, FAQ_ARRAY } from "@app/utils/constants"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { isWeb } from "@app/utils/isWeb"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { Footer } from "../Home/components/Footer"
import { AppStackScreenProps } from "@app/navigators"
import { Question } from "./components/Question"

const BACKGROUND = require("../../../assets/images/web/bg-player.png")

interface QuestionStatesType {
  [x: number]: boolean
}

export const FAQScreen: FC<AppStackScreenProps<"FAQ">> = ({ route }) => {
  const { goBack } = useNavigation(null, route)
  const { height } = useWindowDimensions()
  const [questionStates, setQuestionStates] = useState<QuestionStatesType[]>(null)

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    minHeight: height - APP_BAR_HEIGHT,
  }

  useEffect(() => {
    const questionState = FAQ_ARRAY.map((question) => {
      return { [question.id]: false }
    })
    setQuestionStates(questionState)
  }, [])

  const onPressQuestion = (id: number) => {
    setQuestionStates((prev) => {
      const newStates = prev.map((questionState) => {
        const key = parseInt(Object.keys(questionState)[0])
        if (key === id) {
          return { [key]: true }
        } else {
          return { [key]: false }
        }
      })
      return [...newStates]
    })
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["top"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <ContainerResponsive>
        <View style={$headerContainer}>
          <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            {isWeb() ? (
              <Text text="Volver" style={$textHeader} />
            ) : (
              <Text text="Preguntas frecuentes" style={$textHeader} />
            )}
          </View>
        </View>
      </ContainerResponsive>
      <ImageBackground
        style={$imageBgContainer}
        source={BACKGROUND}
        resizeMode={isWeb() ? "stretch" : "cover"}
      >
        <ContainerResponsive padding={10}>
          {isWeb() ? (
            <View style={$titleContainer}>
              <Text style={$textTitlePage}>Preguntas frecuentes</Text>
            </View>
          ) : (
            <View style={{ paddingTop: 20 }} />
          )}
          <View style={$questionsContainer}>
            {FAQ_ARRAY.map((question, index) => {
              return (
                <Fragment key={question.id}>
                  <Question
                    question={question}
                    onPressQuestion={onPressQuestion}
                    questionStates={questionStates}
                  />
                  {index + 1 !== FAQ_ARRAY.length && <View style={$divider} />}
                </Fragment>
              )
            })}
          </View>
        </ContainerResponsive>
      </ImageBackground>
      {isWeb() && <Footer />}
    </Screen>
  )
}

const $divider: ViewStyle = { borderWidth: 0.5, borderColor: colors.WHITE, margin: 10 }

const $questionsContainer: ViewStyle = { paddingBottom: 50 }

const $titleContainer: ViewStyle = { paddingVertical: 35 }

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textTitlePage: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 24,
  lineHeight: 30,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
  paddingLeft: 10,
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $imageBgContainer: ViewStyle = {
  paddingTop: isWeb() ? spacing.medium : 0,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}
