import React, { FC, useEffect, useRef, useState } from "react"
import {
  ViewStyle,
  View,
  TouchableOpacity,
  TextStyle,
  ImageBackground,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from "react-native"
import { Screen, Text, Icon } from "@app/components"
import { spacing, typography, colors } from "@app/theme"
import { AppStackScreenProps } from "@app/navigators"
import usePlayer from "./hooks/use-player"
import { BackDrop } from "@app/components/BackDrop"
import { useNavigation } from "@app/navigators/utils"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { PlayerWrapper } from "./components/PlayerWrapper"
import { isWeb } from "@app/utils/isWeb"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { Footer } from "../Home/components/Footer"
import { useIsMounted } from "@app/utils/isMounted"
import { VideoSerieComponent } from "./components/VideoSerieComponent"
import { CardDocument } from "./components/CardDocument"
import { createProgress } from "@app/services/api/video-progress"
import { useLocation } from "react-router-dom"

const BACKGROUND = require("../../../assets/images/web/bg-player.png")

export const PlayerScreen: FC<AppStackScreenProps<"Player">> = ({ route }) => {
  const isMounted = useIsMounted()
  const { goBack, navigateTo } = useNavigation(null, route)
  const location = useLocation()
  const { height } = useWindowDimensions()
  const videoId: string = location.search.slice(9, location.search.length)
  const dataFetch = useRef(false)
  const type = "video"
  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    minHeight: height - APP_BAR_HEIGHT,
  }

  const {
    initializePlayer,
    video,
    playerRef,
    getPresentersNames,
    getCategoriesNames,
    onPlay,
    onTime,
    onFullScreen,
    onFullScreenExit,
    isLoading,
    addFavorite,
    removeFavorite,
    isInFavorites,
    JWPlayer,
    saveOrUpdateVideoToHistory,
    onPause,
    authUser,
    showModal,
    hideModal,
    playerMountedCallback,
    favStates,
    videosRelated,
    refPlayerWeb,
    isPlaying,
    docPublished
  } = usePlayer()

  useEffect(() => {
    if (isMounted()) {
      isWeb() && window.scrollTo(0, 0)
      initializePlayer(Number(videoId))
      if (dataFetch.current) return
      dataFetch.current = true
      if (!authUser.isAnonymous) {
        saveOrUpdateVideoToHistory(Number(videoId), "video")
      }
    }
  }, [videoId])

  useEffect(() => {
    if (!video) return
    if (!isPlaying) return
    const interval = setInterval(async () => {
      console.log("Save progress..")
      if (isWeb()) {
        if (!authUser.isAnonymous && refPlayerWeb) {
          const position = Math.round(refPlayerWeb?.getCurrentTime())
          if (position && position !== 0) await createProgress(authUser.user.id, video.id, position)
        }
      } else {
        if (!authUser.isAnonymous && playerRef) {
          const position = await playerRef.current?.position()
          if (position && position !== 0)
            await createProgress(authUser.user.id, video.id, Math.round(position))
        }
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [video, isPlaying])

  if (isLoading) {
    return (
      <Screen
        preset="scroll"
        safeAreaEdges={["top"]}
        contentContainerStyle={$screenContentContainer}
        backgroundColor={colors.SHADES_OF_BLACK}
      >
        <BackDrop />
      </Screen>
    )
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["top"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <ContainerResponsive>
        <View style={$headerContainer}>
          <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            {isWeb() ? (
              <Text text="Volver" style={$textHeader} />
            ) : (
              <Text text="Reproductor" style={$textHeader} />
            )}
          </View>
        </View>
      </ContainerResponsive>
      <ImageBackground
        style={$imageBgContainer}
        source={BACKGROUND}
        resizeMode={isWeb() ? "stretch" : "cover"}
      >
        <PlayerWrapper
          video={video}
          JWPlayer={JWPlayer}
          onFullScreen={onFullScreen}
          onFullScreenExit={onFullScreenExit}
          onPause={onPause}
          onPlay={onPlay}
          onTime={onTime}
          playerMountedCallback={playerMountedCallback}
          playerRef={playerRef}
        />
        <ContainerResponsive padding={!isWeb() && 16} xs={11} md={10} lg={9} xl={8}>
          {video && (
            <View style={$infoViewContainer}>
              <View style={$titleContainer}>
                <View style={$titleTextContainer}>
                  <Text style={$textTitle}>{video?.title}</Text>
                </View>
                <View style={$iconStyle}>
                  {authUser.isAnonymous ? (
                    <TouchableOpacity
                      onPress={() =>
                        showModal(
                          <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                          null,
                          isWeb() && 600,
                        )
                      }
                    >
                      <View style={$isNotFavoriteContainer}>
                        <Icon icon="heartOutlined" size={20} color={colors.SHADES_OF_BLACK} />
                      </View>
                    </TouchableOpacity>
                  ) : favStates[type] && favStates[type][video.id] ? (
                    <TouchableWithoutFeedback>
                      <View style={$isNotFavoriteContainer}>
                        <ActivityIndicator size={16} color={colors.MAIN_AQUAMARINE} />
                      </View>
                    </TouchableWithoutFeedback>
                  ) : isInFavorites(video.id, type) ? (
                    <TouchableOpacity onPress={() => removeFavorite(video.id, type)}>
                      <View style={$isFavoriteContainer}>
                        <Icon icon="heart" size={17} color={colors.MAIN_DARK_BLUE} />
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => addFavorite(video?.id, type)}>
                      <View style={$isNotFavoriteContainer}>
                        <Icon icon="heartOutlined" size={20} color={colors.SHADES_OF_BLACK} />
                      </View>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View style={$descriptionContainer}>
                <Text text={video?.description} style={$regularText} />
              </View>
              <View style={$infoTextContainers}>
                <Text style={$subHeadingText} preset="bold" text="Presentadores:  " />
                <Text style={$regularText} text={getPresentersNames(video.presenters)} />
              </View>
              <View style={$infoTextContainers}>
                <Text style={$subHeadingText} preset="bold" text="Categorías:  " />
                <Text style={$regularText} text={getCategoriesNames(video?.categories)} />
              </View>
              {docPublished.length > 0 && (
                <CardDocument documents={docPublished} navigateTo={navigateTo} />
              )}
              {videosRelated.length > 0 && (
                <VideoSerieComponent items={videosRelated} navigateTo={navigateTo} type="video" />
              )}
            </View>
          )}
        </ContainerResponsive>
      </ImageBackground>
      {isWeb() && <Footer />}
    </Screen>
  )
}

const $descriptionContainer: ViewStyle = { marginBottom: 15 }

const $isNotFavoriteContainer: ViewStyle = {
  height: 37,
  width: 37,
  backgroundColor: colors.GRAY,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
}

const $isFavoriteContainer: ViewStyle = {
  height: 37,
  width: 37,
  backgroundColor: colors.MAIN_AQUAMARINE,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
}

const $textTitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: isWeb() ? 24 : 20,
  fontWeight: "bold",
  lineHeight: 31,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $regularText: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 15,
  lineHeight: 21,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $subHeadingText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 16,
  lineHeight: 21,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $infoTextContainers: ViewStyle = {
  marginBottom: 6,
  flexDirection: "column",
}

const $titleContainer: ViewStyle = {
  paddingBottom: spacing.medium,
  display: "flex",
  flexDirection: "row",
}

const $titleTextContainer: ViewStyle = {
  width: "90%",
}

const $iconStyle: ViewStyle = {
  width: "10%",
  marginTop: 5,
  marginLeft: 5,
  alignItems: "center",
}

const $infoViewContainer: ViewStyle = {
  paddingTop: spacing.medium,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}

const $imageBgContainer: ViewStyle = {
  paddingTop: isWeb() ? spacing.medium : 0,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}

// TODO: Por alguna razón totalmente random, ponerle un backgroundColor a
// estos 2 view hace que no se corra el header hacia abajo

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}
