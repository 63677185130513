/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import { useFonts } from "expo-font"
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import React, { useEffect } from "react"
import { BrowserRouter } from "@app/local_modules/react-router-dom"
import { ModalProvider } from "./components/CustomComponents/Modal/modal-context"
import Config from "./config"
import { customFontsToLoad } from "./theme"
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary"
import { useNavigationPersistence } from "./navigators"
import { isWeb } from "@app/utils/isWeb"
import { store } from "./store"
import { Provider } from "react-redux"
import { AppNavigator } from "./navigators/AppNavigator"
import { WebNavigator } from "./navigators/Web/WebNavigator"
import { setupReactotron } from "./services/reactotron"
import "./utils/ignoreWarnings"
import "./i18n"
import * as storage from "./utils/storage"
import { UpdateModalScreen } from "./screens/UpdateModal"
import Orientation from "react-native-orientation-locker"
import { Dimensions } from "react-native"

// Set up Reactotron
setupReactotron({
  clearOnLoad: true,
  host: "localhost",
  useAsyncStorage: true,
  logInitialState: true,
  logSnapshots: false,
})

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

interface AppProps {
  hideSplashScreen: () => Promise<void>
}

function App({ hideSplashScreen }: AppProps): JSX.Element | null {
  const isOnWeb = isWeb()
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)
  const [areFontsLoaded] = useFonts(customFontsToLoad)

  useEffect(() => {
    setTimeout(hideSplashScreen, 500)
  }, [])

  const isTablet = () => {
    const { width, height } = Dimensions.get("window")
    const aspectRatio = height / width
    console.log("aspectRatio: ", aspectRatio)
    return aspectRatio < 1.6 // Valor de aspect ratio para determinar si es una tablet
  }

  const onOrientationDidChange = (orientation: string) => {
    console.log("change orientation")
    if (isTablet()) {
      if (orientation === "PORTRAIT") {
        Orientation.lockToLandscape()
      }
    } else {
      if (orientation !== "PORTRAIT") {
        Orientation.lockToPortrait()
      }
    }
  }

  useEffect(() => {
    if (!isWeb()) {
      if (isTablet()) {
        // TODO: Funciona, pero se renderiza todo en portrait primero
        // y hace que se vea mal el diseño
        Orientation.lockToLandscape()
      } else {
        Orientation.lockToPortrait()
      }
      Orientation.addOrientationListener(onOrientationDidChange)
    }

    return () => {
      if (!isWeb()) {
        Orientation.removeOrientationListener(onOrientationDidChange)
      }
    }
  }, [])

  if (!isNavigationStateRestored || !areFontsLoaded) {
    return null
  }

  return (
    <ActionSheetProvider>
      <Provider store={store}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <UpdateModalScreen />
          <ErrorBoundary catchErrors={Config.catchErrors}>
            <ModalProvider>
              {isOnWeb ? (
                <BrowserRouter>
                  <WebNavigator />
                </BrowserRouter>
              ) : (
                <AppNavigator
                  initialState={initialNavigationState}
                  onStateChange={onNavigationStateChange}
                />
              )}
            </ModalProvider>
          </ErrorBoundary>
        </SafeAreaProvider>
      </Provider>
    </ActionSheetProvider>
  )
}

export default App
