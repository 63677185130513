import { useState, useCallback } from "react"
import { Video } from "@app/interfaces/video.interface"
import { Presenter } from "@app/interfaces/presenter.interface"
import { Category } from "@app/interfaces/category.interface"
import { fetchCategories } from "@app/services/api/categories"
import { fetchPresenters } from "@app/services/api/presenters"
import { debounce } from "lodash"
import { fetchSearchEntities } from "@app/services/api/search"
import { Document } from "@app/interfaces/document.interface"
import { Serie } from "@app/interfaces/serie.interface"
import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"

const useSearch = () => {
  const auth = useAppSelector((state) => state.auth)
  const favorites = useAppSelector((state) => state.favorites)
  const dispatch = useAppDispatch()
  const { showModal, hideModal } = useModal()
  const [favStates, setFavStates] = useState({})

  const [isFirstSearch, setIsFirstSearch] = useState(true)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState("")
  const [resultsSearch, setResultsSearch] = useState<{
    videos: Video[]
    documents: Document[]
    series: Serie[]
  }>({ videos: [], documents: [], series: [] })

  const [categories, setCategories] = useState<Category[]>([])
  const [presenters, setPresenters] = useState<Presenter[]>([])
  const [filterModal, setFilterModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState({
    categories: [],
    presenters: [],
  })
  const [applyFilter, setApplyFilter] = useState(false)

  const backgroundNotFound = require("../../../../assets/images/backgroundNotFound.png")
  const backgroundBlue = require("../../../../assets/images/backgroundBlue.png")

  const initializeSearch = async () => {
    const categoriesResponse = await fetchCategories()
    setCategories(categoriesResponse.data.data)
    const presentersResponse = await fetchPresenters()
    const formattedPresenters = presentersResponse.data.data.map((presenter) => {
      return { ...presenter, name: `${presenter.first_name} ${presenter.last_name}` }
    })
    setPresenters(formattedPresenters)
  }

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (entityId: number, type: string) => {
    dispatch(addNewFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const onSelectedItemsChange = (selectedItems, type) => {
    switch (type) {
      case "categories":
        setSelectedItems((prevState) => ({
          ...prevState,
          categories: selectedItems,
        }))
        break
      case "presenters":
        setSelectedItems((prevState) => ({
          ...prevState,
          presenters: selectedItems,
        }))
        break
      default:
        break
    }
  }

  const handleChangeTitle = (e: string) => {
    setTitle(e)
  }

  const applyFilters = () => {
    setApplyFilter((prev) => !prev)
    setFilterModal(!filterModal)
  }

  const removeFilters = () => {
    setSelectedItems({
      categories: [],
      presenters: [],
    })
  }

  const isFiltersEmpty = () => {
    if (
      title === "" &&
      selectedItems.presenters.length === 0 &&
      selectedItems.categories.length === 0
    ) {
      return true
    }
    return false
  }

  const onFiltersChange = async (title: string, searchBar = false) => {
    setIsLoading(true)
    if (!isFirstRender || searchBar) {
      try {
        const searchResponse = await fetchSearchEntities({
          title,
          presenters: selectedItems.presenters,
          categories: selectedItems.categories,
          limit: isFiltersEmpty() ? 30 : 0,
        })
        setResultsSearch(searchResponse.data.data)
        setIsLoading(false)
        setIsFirstSearch(false)
      } catch (err) {
        console.error(err)
        setIsLoading(false)
      }
    }
    setIsFirstRender(false)
    setIsLoading(false)
  }

  const onOpenFilterModal = () => {
    setFilterModal(true)
  }

  const onCloseFilterModal = () => {
    setFilterModal(false)
  }

  const debouncedTitleHandler = useCallback(debounce(handleChangeTitle, 1000), [])

  return {
    onSelectedItemsChange,
    handleChangeTitle,
    initializeSearch,
    onFiltersChange,
    debouncedTitleHandler,
    isLoading,
    title,
    applyFilter,
    filterModal,
    categories,
    presenters,
    selectedItems,
    applyFilters,
    removeFilters,
    onOpenFilterModal,
    onCloseFilterModal,
    backgroundNotFound,
    isFirstSearch,
    backgroundBlue,
    resultsSearch,
    favStates,
    addFavorite,
    removeFavorite,
    isInFavorites,
    auth,
    showModal,
    hideModal,
  }
}

export default useSearch
