import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/auth"
import { firebaseConfig } from "@app/utils/firebase/config"

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default firebase
