import axios from "axios"
import Config from "@app/config"

interface ApiConfig {
  url: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 10000,
}

const axiosInt = axios.create({
  baseURL: DEFAULT_API_CONFIG.url,
  timeout: DEFAULT_API_CONFIG.timeout,
  headers: {
    Accept: "application/json",
  },
})

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    return Promise.reject((error.response && error.response.data) || "There is an error!")
  },
)

export default axiosInt
