import { useState } from "react"
import { useIsMounted } from "@app/utils/isMounted"
import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { fetchDocumentById } from "@app/services/api/documents"
import { Linking, Alert } from "react-native"
import { addOrUpdateHistoryAction } from "@app/store/actions/history"

const useDocument = () => {
  const isMounted = useIsMounted()
  const { hideModal, showModal } = useModal()
  const dispatch = useAppDispatch()
  const auth = useAppSelector((state) => state.auth)
  const favorites = useAppSelector((state) => state.favorites)
  const history = useAppSelector((state) => state.history)
  const [isLoading, setIsLoading] = useState(true)
  const [documentFetch, setDocumentFetch] = useState(null)
  const [favStates, setFavStates] = useState({})

  const initializeDocument = async (id: number) => {
    if (isMounted()) {
      try {
        const response = await fetchDocumentById(id)
        setDocumentFetch(response.data.data)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const isInHistory = (entityId: number, type: string) => {
    const found = history.userHistory.find((history) => {
      switch (type) {
        case "video":
          return history.video?.id === entityId
        case "document":
          return history.document?.id === entityId
        case "serie":
          return history.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const saveOrUpdateToHistory = (entityId: number, type: string) => {
    dispatch(addOrUpdateHistoryAction(entityId, type))
  }

  const onDownloadDocument = async (url: string) => {
    const supported = await Linking.canOpenURL(url)
    if (supported) {
      await Linking.openURL(url)
    } else {
      Alert.alert(`URL invalida: ${url}`)
    }
  }

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (entityId: number, type: string) => {
    dispatch(addNewFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  return {
    isMounted,
    isLoading,
    initializeDocument,
    documentFetch,
    auth,
    favorites,
    favStates,
    isInFavorites,
    addFavorite,
    removeFavorite,
    showModal,
    hideModal,
    onDownloadDocument,
    saveOrUpdateToHistory,
    isInHistory,
  }
}

export default useDocument
