import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { Serie } from "@app/interfaces/serie.interface"
import { fetchSerieById, fetchSearchSeries } from "@app/services/api/series"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"
import { addOrUpdateHistoryAction } from "@app/store/actions/history"
import { useAppDispatch, useAppSelector } from "@app/store/hooks/useRedux"
import { useState } from "react"

const useSeries = () => {
  const [serie, setSerie] = useState<Serie>(null)
  const [seriesRelated, setSeriesRelated] = useState<Serie[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [favStates, setFavStates] = useState({})
  const [episodePublished, setEpisodePublished] = useState([])
  const auth = useAppSelector((state) => state.auth)
  const { hideModal, showModal } = useModal()
  const dispatch = useAppDispatch()
  const favorites = useAppSelector((state) => state.favorites)

  const initializeSerie = async (id: number) => {
    try {
      setIsLoading(true)
      const responseSerie = await fetchSerieById(id)
      const categoriesIds = responseSerie.data.data.categories.map((category) => category.id)
      const responseSeriesRelated = await fetchSearchSeries(null, null, categoriesIds, 6)
      setSeriesRelated(responseSeriesRelated.data.data.filter(serieActual => serieActual.id !== id))
      setSerie(responseSerie.data.data)
      setEpisodePublished(responseSerie.data.data.episodes.filter(ep => ep.status === 'published'))
      setIsLoading(false)
    } catch (error) {
      console.log("Error:", error)
      setIsLoading(false)
    }
  }

  const saveOrUpdateToHistory = (entityId: number, type: string) => {
    dispatch(addOrUpdateHistoryAction(entityId, type))
  }

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (entityId: number, type: string) => {
    dispatch(addNewFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  return {
    isLoading,
    serie,
    initializeSerie,
    seriesRelated,
    saveOrUpdateToHistory,
    auth,
    isInFavorites,
    addFavorite,
    removeFavorite,
    showModal,
    hideModal,
    favStates,
    episodePublished
  }
}

export default useSeries
