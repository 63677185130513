import React, { FC } from "react"
import { Grid } from "@mui/material"
import {
  View,
  ViewStyle,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  ImageStyle,
  TextStyle,
} from "react-native"
import { Icon, Text } from "@app/components"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import useSeries from "../hooks/useSeries"
import { colors, typography } from "@app/theme"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import LinearGradient from "react-native-linear-gradient"
import { Serie } from "@app/interfaces/serie.interface"
import { handleNavigate } from "@app/utils/handleNavigate"
import { Video } from "@app/interfaces/video.interface"

interface Props {
  items: Serie[] | Video[]
  navigateTo: any
  type: "video" | "serie"
}

export const OtherSeriesContainer: FC<Props> = ({ items, type, navigateTo }) => {
  const { auth, addFavorite, removeFavorite, isInFavorites, showModal, hideModal, favStates } =
    useSeries()
  const { width } = useWindowDimensions()

  return (
    <Grid container style={{ paddingLeft: width <= 600 ? 0 : 20 }}>
      <Grid item xs={12}>
        <View style={{ paddingVertical: 20 }}>
          <Text text="Series relacionadas:" style={$headingText} />
        </View>
      </Grid>
      <Grid item container xs={12}>
        {items.map((item) => (
          <Grid item key={item.id} xs={6} sm={6} md={6} lg={4} xl={3} sx={{ padding: 2 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate(item.id, type, navigateTo)}
            >
              <ImageBackground
                source={{ uri: item.thumbnail }}
                resizeMode="cover"
                style={$videoThumbnail}
                imageStyle={$videoThumbnailImg}
              >
                <LinearGradient
                  colors={["#00000000", "#000000"]}
                  locations={[0, 0.98]}
                  style={$bannerGradient}
                />
                {auth.isAnonymous ? (
                  <TouchableOpacity
                    onPress={() =>
                      showModal(
                        <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                        null,
                        600,
                      )
                    }
                    style={$touchableFavContainer}
                  >
                    <View style={$favoriteDocContainer}>
                      <Icon icon="heartOutlined" size={23} color={colors.WHITE} />
                    </View>
                  </TouchableOpacity>
                ) : favStates[type] && favStates[type][item.id] ? (
                  <TouchableWithoutFeedback>
                    <View style={$favoriteDocContainer}>
                      <ActivityIndicator size={14} color={colors.MAIN_AQUAMARINE} />
                    </View>
                  </TouchableWithoutFeedback>
                ) : isInFavorites(item.id, type) ? (
                  <TouchableOpacity
                    onPress={() => removeFavorite(item.id, type)}
                    style={$touchableFavContainer}
                  >
                    <View style={$favoriteDocContainer}>
                      <Icon icon="heartWeb" size={16} color={colors.MAIN_AQUAMARINE} />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => addFavorite(item.id, type)}
                    style={$touchableFavContainer}
                  >
                    <View style={$favoriteDocContainer}>
                      <Icon icon="heartOutlined" size={18} color={colors.WHITE} />
                    </View>
                  </TouchableOpacity>
                )}
                <View style={$sliderTextContainer}>
                  <Text numberOfLines={2} style={$videoTitleTextStyle} preset="formLabel">
                    {item.title}
                  </Text>
                </View>
              </ImageBackground>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const $headingText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 20,
  lineHeight: 22,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}
//

const $touchableFavContainer: ViewStyle = { width: 45, alignSelf: "flex-end" }

const $videoThumbnail: ViewStyle = {
  borderRadius: 20,
  aspectRatio: 16 / 9,
  justifyContent: "space-between",
  display: "flex",
}

const $videoThumbnailImg: ImageStyle = {
  borderRadius: 12,
}

const $favoriteDocContainer: ViewStyle = {
  width: 32,
  height: 32,
  borderRadius: 16,
  margin: 5,
  backgroundColor: colors.MEDIUM_GRAY,
  alignSelf: "flex-end",
  alignItems: "center",
  justifyContent: "center",
}

const $sliderTextContainer: ViewStyle = {
  paddingHorizontal: 10,
  paddingBottom: 20,
}

const $videoTitleTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: 21,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $bannerGradient: ViewStyle = {
  opacity: 1,
  borderRadius: 11,
  height: "100%",
  width: "100%",
  position: "absolute",
}
