import { VideoProgress } from "@app/interfaces/video_progress.interface"
import axios from "@app/services/axios"
import { AUTH_HEADERS } from "@app/utils/constants"

export const fetchVideoProgress = async (userId: number, videoId: number) => {
  const response = await axios.get<{ data: VideoProgress }>(
    `/api/video-progress?video=${videoId}&user=${userId}`,
    await AUTH_HEADERS(),
  )
  return response
}

export const createProgress = async (userId: number, videoId: number, progress: number) => {
  const response = await axios.post<{ data: VideoProgress }>(
    `/api/video-progress`,
    {
      video_id: videoId,
      user_id: userId,
      progress,
    },
    await AUTH_HEADERS(),
  )
  return response
}

export const updateProgress = async (userId: number, videoId: number, progress: number) => {
  const response = await axios.put<{ data: VideoProgress }>(
    `/api/video-progress`,
    {
      video_id: videoId,
      user_id: userId,
      progress,
    },
    await AUTH_HEADERS(),
  )
  return response
}
