/* eslint-disable camelcase */
import axios from "@app/services/axios"
import type { Serie } from "@app/interfaces/serie.interface"

// Recupera toda la información de la serie y sus episodios según el id que le pase:
export const fetchSerieById = async (id: number) => {
  const response = await axios.get<{ data: Serie; status: number }>(`/api/series/${id}`)
  return response
}

export const fetchSearchSeries = async (
  title: string,
  presenters: number[] = null,
  categories: number[] = null,
  limit = 0,
) => {
  const response = await axios.post<{ data: Serie[]; status: number }>(`/api/series/search`, {
    ...(title && { title }),
    ...(presenters && { presenters }),
    ...(categories && { categories }),
    ...(limit && { limit }),
  })
  return response
}
