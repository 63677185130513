import React, { FC, useEffect, useState } from "react"
import { View, TextStyle, TouchableOpacity, ViewStyle } from "react-native"
import { Text, Icon } from "@app/components"
import { typography, colors } from "@app/theme"

interface QuestionType {
  id: number
  title: string
  response: string
}

interface Props {
  question: QuestionType
  onPressQuestion: any
  questionStates: any
}

export const Question: FC<Props> = ({ question, onPressQuestion, questionStates }) => {
  const [isQuestionPressed, setIsQuestionPressed] = useState<boolean>(false)

  useEffect(() => {
    setIsQuestionPressed(
      questionStates
        ? questionStates.some((q) => {
            if (isQuestionPressed && q[question.id] === true) {
              return false
            }
            return q[question.id] === true
          })
        : false,
    )
  }, [questionStates])

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          onPressQuestion(question.id)
        }}
        style={isQuestionPressed ? $titleOpenContainer : $titleClosedContainer}
      >
        <View style={$titleView}>
          <Text style={isQuestionPressed ? $textTitleOpen : $textTitleClosed}>
            {question.title}
          </Text>
        </View>
        <View style={$iconView}>
          {isQuestionPressed ? (
            <Icon icon="arrowUpFilled" color={colors.MAIN_DARK_BLUE} size={50} />
          ) : (
            <Icon icon="arrowDownFilled" color={colors.MAIN_AQUAMARINE} size={50} />
          )}
        </View>
      </TouchableOpacity>
      <View style={isQuestionPressed ? $responseOpenContainer : $responseClosedContainer}>
        <Text style={$textResponse}>{question.response}</Text>
      </View>
    </View>
  )
}

const $iconView: ViewStyle = {
  flexBasis: "20%",
  justifyContent: "center",
  alignItems: "flex-end",
  paddingRight: 15,
}

const $responseOpenContainer: ViewStyle = {
  display: "flex",
  padding: 10,
}

const $responseClosedContainer: ViewStyle = {
  display: "none",
}

const $textResponse: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 16,
  lineHeight: 28,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "auto",
  color: colors.WHITE,
}

const $titleOpenContainer: ViewStyle = {
  flexBasis: "80%",
  justifyContent: "center",
  paddingLeft: 10,
  paddingVertical: 5,
  backgroundColor: colors.MAIN_AQUAMARINE,
  borderRadius: 20,
  display: "flex",
  flexDirection: "row",
}

const $titleClosedContainer: ViewStyle = {
  flexBasis: "80%",
  justifyContent: "center",
  paddingLeft: 10,
  paddingVertical: 5,
  borderRadius: 20,
  display: "flex",
  flexDirection: "row",
}

const $titleView: ViewStyle = {
  flexBasis: "80%",
  justifyContent: "center",
  paddingLeft: 10,
}

const $textTitleClosed: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 18,
  lineHeight: 30,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textTitleOpen: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 18,
  lineHeight: 30,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.MAIN_DARK_BLUE,
}
