import React, { FC, CSSProperties } from "react"
import { View, TouchableOpacity, ViewStyle, TextStyle } from "react-native"
import { Icon, Text } from "@app/components"
import { Grid } from "@mui/material"
import { LoginFormContainerProps } from "../interfaces/loginFormContainer.interface"
import { useWindowDimensions } from "react-native"
import { colors, typography } from "@app/theme"

const backgroundLoginImage = require("../../../../../assets/images/background-home.png")
const logoImage = require("../../../../../assets/images/web/LogotipoOne.png")

export const LoginFormContainer: FC<LoginFormContainerProps> = ({ children, goBack }) => {
  const { height, width } = useWindowDimensions()

  const $logoContainer: CSSProperties =
    width >= 900
      ? {
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          width: "100%",
          height: "100%",
        }
      : { display: "none" }

  const $headerContainer: CSSProperties =
    width >= 900
      ? {
          height: 60,
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          paddingTop: 20,
          paddingLeft: 20,
        }
      : { display: "none" }

  return (
    <Grid container style={{ height }}>
      <Grid item xs={0} md={6} lg={7} sx={$backgroundContainer}>
        <div style={$headerContainer}>
          <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircleWeb" size={54} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            <Text text="Iniciar sesión" style={$textHeader} />
          </View>
        </div>
        <div style={$logoContainer}>
          <img src={logoImage} loading="lazy" style={$logoStyle} />
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        {children}
      </Grid>
    </Grid>
  )
}

const $logoStyle: CSSProperties = { width: 400, objectFit: "contain" }

const $backgroundContainer: CSSProperties = {
  backgroundImage: `url(${backgroundLoginImage})`,
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 29,
  lineHeight: 34,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}
