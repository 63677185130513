import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { useState } from "react"
import { deleteFavorite } from "@app/store/actions/favorites"

const useActivity = () => {
  const user = useAppSelector((state) => state.auth.user)
  const favorites = useAppSelector((state) => state.favorites)
  const history = useAppSelector((state) => state.history)

  const dispatch = useAppDispatch()
  const [isFavoritesActive, setIsFavoriteActive] = useState(true)
  const [favStates, setFavStates] = useState({})

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const onPressActivityBtn = () => {
    setIsFavoriteActive((prev) => !prev)
  }
  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  return {
    user,
    favorites,
    isFavoritesActive,
    onPressActivityBtn,
    removeFavorite,
    history,
    isInFavorites,
    favStates,
  }
}

export default useActivity
