import { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { goBack } from "@app/navigators"
import auth from "@app/services/firebase/auth/Auth"
import { useNavigation } from "@app/navigators/utils"
// Redux:
import { setIsLoading } from "@app/store/slices/app"
import { useAppDispatch, useAppSelector } from "@app/store/hooks/useRedux"

const useRecovery = () => {
  const [typeModal, setTypeModal] = useState("")
  const [isVisible, setVisible] = useState(false)
  const { navigateTo } = useNavigation()
  const { isLoading } = useAppSelector((state) => state.app)

  // Acciones al store
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email no válido")
        .min(8, "Mín. 8 caracteres")
        .max(50, "Máx. 50 caracteres")
        .trim()
        .required("Este campo es requerido"),
    }),
    onSubmit: async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      try {
        dispatch(setIsLoading(true))
        await auth().sendPasswordResetEmail(values.email)
        dispatch(setIsLoading(false))
        navigateTo("SendEmailSuccess", [formik.values.email])
      } catch (error) {
        if (error.code === "auth/user-not-found") {
          setTypeModal("user-not-found")
          setVisible(true)
          console.log("Email no registrado:", error.message)
          resetForm()
          setStatus({ success: false })
          setErrors(error.message)
          setSubmitting(false)
          dispatch(setIsLoading(false))
        }
        // TODO: Manejar un error por defecto
        console.log(error)
        dispatch(setIsLoading(false))
      }
    },
  })

  return {
    typeModal,
    isVisible,
    setVisible,
    goBack,
    isLoading,
    formik,
  }
}

export default useRecovery
