import { useState } from "react"

export const useToggleDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (state: boolean) => {
    setOpenDrawer(state)
  }

  return {
    toggleDrawer,
    openDrawer,
    setOpenDrawer,
  }
}
