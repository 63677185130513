import { useState } from "react"
import { login } from "@app/store/actions/auth"
import { ILogin } from "@app/store/interface/auth"
import { useAppDispatch, useAppSelector } from "@app/store/hooks/useRedux"
import { useFormik } from "formik"
import { useIsMounted } from "@app/utils/isMounted"
import * as Yup from "yup"
import auth from "@app/services/firebase/auth/Auth"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { ModalShowInfo } from "@app/components/ModaShowInfo"
import AsyncStorage from "@react-native-async-storage/async-storage"

const useLogin = (navigateTo: unknown) => {
  const dispatch = useAppDispatch()
  const isMounted = useIsMounted()
  const { hideModal, showModal } = useModal()
  const [isAuthPasswordHidden, setIsAuthPasswordHidden] = useState(true)
  const [password, setAuthPassword] = useState("")
  const [email, setAuthEmail] = useState("")
  const [errorLoginMsg, setErrorLoginMsg] = useState<string>("")
  const { isLoading } = useAppSelector((state) => state.app)

  const modalError = () => {
    showModal(
      ModalShowInfo({
        hideModal,
        type: "error",
        title: "Ocurrió un error",
        message: "Intente ingresar nuevamente en unos segundos..",
      }),
    )
  }

  const loginUser = (data: ILogin) => {
    dispatch(login(data, setErrorLoginMsg, modalError, navigateTo))
  }

  const logOutUser = async () => {
    await AsyncStorage.removeItem("token")
    auth().signOut()
  }

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Debe ser un email valido").max(255).required("Email es requerido"),
      password: Yup.string().max(255).required("Contraseña es requerida"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        loginUser(values)
        if (isMounted()) {
          setStatus({ success: true })
          setSubmitting(false)
        }
      } catch (err) {
        console.error(err)
        if (isMounted()) {
          setStatus({ success: false })
          setErrors({ submit: err.code })
          setSubmitting(false)
        }
      }
    },
  })

  return {
    loginUser,
    isAuthPasswordHidden,
    setIsAuthPasswordHidden,
    setAuthEmail,
    setAuthPassword,
    email,
    password,
    isLoading,
    logOutUser,
    errorLoginMsg,
    form,
  }
}

export default useLogin
