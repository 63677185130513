import React, { FC } from "react"
import { ViewStyle } from "react-native"
import { Screen } from "@app/components"
import { AppStackScreenProps } from "@app/navigators"
import { colors } from "@app/theme"
import { LoginForm } from "./components/LoginForm"
import { LoginFormContainer } from "./components/LoginFormContainer"
import { useNavigation } from "@app/navigators/utils"

interface LoginScreenProps extends AppStackScreenProps<"Login"> {}

export const LoginScreen: FC<LoginScreenProps> = ({ navigation }) => {
  const { navigateTo, goBack } = useNavigation(navigation)

  return (
    <Screen
      preset="scroll"
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SECONDARY_BLACK}
    >
      <LoginFormContainer goBack={goBack}>
        <LoginForm navigateTo={navigateTo} goBack={goBack} />
      </LoginFormContainer>
    </Screen>
  )
}

const $screenContentContainer: ViewStyle = {
  flexGrow: 1,
  backgroundColor: colors.SECONDARY_BLACK,
}
