// TODO: write documentation for colors and palette in own markdown file and add links from here

const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#564E4A",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#000000",

  primary100: "#F4E0D9",
  primary200: "#E8C1B4",
  primary300: "#DDA28E",
  primary400: "#D28468",
  primary500: "#C76542",
  primary600: "#A54F31",

  secondary100: "#DCDDE9",
  secondary200: "#BCC0D6",
  secondary300: "#9196B9",
  secondary400: "#626894",
  secondary500: "#41476E",

  accent100: "#FFEED4",
  accent200: "#FFE1B2",
  accent300: "#FDD495",
  accent400: "#FBC878",
  accent500: "#FFBB50",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",

  black: "#000000",
}

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral800,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral200,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: "#df015e",
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,

  black: palette.black,
  MAIN_DARK_BLUE: "#12223f",
  GRAY: "#929292",
  MEDIUM_GRAY: "#646160",
  DARK_GRAY: "#3a3a3a",
  MAIN_AQUAMARINE: "#21f4b0",
  LIGHT_GRAY: "#d9d9d9",
  GREEN_OK: "#45a819",
  RED_ERROR: "#f5120c",
  SECONDARY_VIOLET: "#5b23d4",
  SECONDARY_CORAL: "#df015e",
  MAIN_BLUE: "#004071",
  SHADES_OF_BLACK: "#000000",
  WHITE: "#ffffff",
  PRIMARY_BRAINSTORMING_BLACK: "#121212",
  PALE_GREY: "#dee2ee",
  SECONDARY_BLACK: "#202020",
}
