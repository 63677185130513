import axios from "@app/services/axios"
import { Video } from "@app/interfaces/video.interface"

type Status = "published" | "hidden" | "draft"

export const fetchVideoById = async (id: number) => {
  const response = await axios.get<{ data: Video; status: number }>(`/api/videos/${id}`)
  return response
}

export const fetchVideos = async (status?: Status) => {
  const response = await axios.get<{ data: Video; status: number }>(`/api/videos`, {
    params: { ...(status && { status }) },
  })
  return response
}

export const fetchSearchVideos = async (
  title: string,
  presenters: number[] = null,
  categories: number[] = null,
  limit = 0,
) => {
  const response = await axios.post<{ data: Video[]; status: number }>(`/api/videos/search`, {
    ...(title && { title }),
    ...(presenters && { presenters }),
    ...(categories && { categories }),
    ...(limit && { limit }),
    status: "published",
  })
  return response
}
