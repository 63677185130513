import { Button, Icon } from "@app/components"
import { useNavigation } from "@app/navigators/utils"
import React from "react"
import { View, Modal, ViewStyle, TextStyle, ImageStyle, Image, useWindowDimensions } from "react-native"
import { Text } from "../../../components/Text"
import { colors } from "../../../theme/colors"

export const ModalFeedback = (props) => {
  const { isVisible, setVisible, typeModal } = props
  const { width } = useWindowDimensions()

  const { goBack } = useNavigation()

  return (
    <Modal animationType="fade" visible={isVisible} transparent>
      <View style={$backgroundTransparency}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={[$modalContainer, { width: width >= 900 ? '50%' : '90%'}]}>
          <View style={$closeIconContainer}>
            <Icon
              icon="x"
              size={20}
              onPress={
                typeModal === "success"
                  ? () => {
                      setVisible(false)
                      goBack()
                    }
                  : () => {
                      setVisible(false)
                    }
              }
            />
          </View>
          {typeModal === "error" && (
            <>
              <Text style={$modah1Error}>Ups!</Text>
              <Image
                source={require("../../../../assets/icons/errorIcon.png")}
                style={$modalImageError}
              />
              <Text style={$modalh2Error}>Tuvimos problemas para enviar tus comentarios.</Text>
              <Text style={$modalh3Error}>Intentá en unos segundos nuevamente</Text>
              <Button
                preset="reversed"
                style={$modalButtonTryAgain}
                text="Intentar de nuevo"
                onPress={() => {
                  setVisible(false)
                }}
              />
            </>
          )}

          {typeModal === "success" && (
            <>
              <Image
                source={require("../../../../assets/icons/successIcon.png")}
                style={$modalImageSuccess}
              />
              <Text style={[$modalh2Success, $top3]}>Pudimos enviar tus comentarios</Text>
              <Text style={$modalh2Success}>Muchas gracias por ponerte en contacto!</Text>
              <View style={$buttonContainer}>
                <Button
                  preset="filled"
                  style={$modalButtonSuccess2}
                  text="Finalizar"
                  onPress={() => {
                    // goBack login:
                    setTimeout(() => {
                      setVisible(false)
                      goBack()
                    }, 100)
                  }}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  )
}

const $backgroundTransparency: ViewStyle = {
  backgroundColor: "rgba(0,0,0, 0.9)",
  width: "100%",
  height: "100%",
}

const $modalContainer: ViewStyle = {
  margin: "auto",
  alignContent: "center",
  justifyContent: "center",
  backgroundColor: "rgba(240,240, 240,0.9)",
  borderRadius: 22,
  padding: "3%",
  position: "relative",
  width: "90%",
  height: 450,
}
const $closeIconContainer: ViewStyle = {
  position: "relative",
  alignItems: "flex-end",
}

const $modah1Error: TextStyle = {
  fontSize: 35,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.error,
}

const $modalImageError: ImageStyle = {
  marginTop: "3%",
  marginBottom: "5%",
  width: 79,
  height: 79,
  marginStart: "auto",
  marginEnd: "auto",
}

const $modalButtonTryAgain: TextStyle = {
  marginTop: "10%",
  width: 273,
  height: 51,
  marginHorizontal: "auto",
  borderRadius: 30,
  color: "fff",
}

const $modalh2Success: TextStyle = {
  marginTop: "3%",
  fontSize: 17,
  textAlign: "center",
  color: colors.black,
}

const $modalImageSuccess: ImageStyle = {
  width: 98,
  height: 98,
  marginStart: "auto",
  marginEnd: "auto",
  marginTop: "5%",
  position: "relative",
}

const $buttonContainer: ViewStyle = {
  alignSelf: "center",
  alignContent: "center",
  alignItems: "center",
  marginHorizontal: "auto",
  marginVertical: "auto",
}

const $modalButtonSuccess2: ViewStyle = {
  marginTop: "5%",
  width: 273,
  height: 51,
  borderRadius: 30,
  backgroundColor: "#21f4b0",
}

const $modalh2Error: TextStyle = {
  marginTop: "3%",
  fontSize: 20,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.black,
}

const $modalh3Error: TextStyle = {
  marginTop: "3%",
  fontSize: 18,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.black,
}

const $top3: ViewStyle = {
  marginTop: "3%",
}
