import { DrawerScreenProps, createDrawerNavigator } from "@react-navigation/drawer"
import { CompositeScreenProps } from "@react-navigation/native"
import React from "react"
import { Icon } from "@app/components"
import { translate } from "@app/i18n"
import { colors, typography } from "@app/theme"
import { AppStackParamList, AppStackScreenProps } from "./AppNavigator"
import { Profile } from "@app/screens/Profile"
import { HomeScreen } from "@app/screens/Home"
import { SearchScreen } from "@app/screens/Search"
import { CustomDrawer } from "./components/CustomDrawer"
import { ExplorerScreen } from "@app/screens/Explorer"
import { CustomHeader } from "./components/CustomHeader"
import { ImageStyle } from "react-native"
import { Activity } from "@app/screens/Activity"
import { useAppSelector } from "@app/store/hooks/useRedux"

export type TabParamList = {
  Profile: undefined
  Activity: undefined
  Home: undefined
  Explorer: undefined
  Search: { searchValue: string }
}

/**
 * Helper for automatically generating navigation prop types for each route.
 *
 * More info: https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type TabScreenProps<T extends keyof TabParamList> = CompositeScreenProps<
  DrawerScreenProps<TabParamList, T>,
  AppStackScreenProps<keyof AppStackParamList>
>

const Drawer = createDrawerNavigator<TabParamList>()

export function DrawerNavigator() {
  const user = useAppSelector((state) => state.auth)
  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      screenOptions={{
        headerShown: true,
        drawerItemStyle: { height: 60, justifyContent: "center", marginLeft: 15 },
        drawerActiveTintColor: colors.SECONDARY_VIOLET,
        drawerAllowFontScaling: true,
        drawerLabelStyle: {
          fontFamily: typography.fonts.montserrat.medium,
          fontSize: 20,
          lineHeight: 32,
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.DARK_GRAY,
        },
        drawerContentStyle: { backgroundColor: colors.SHADES_OF_BLACK },
        header: ({ navigation, route, options, layout }) => {
          return (
            <CustomHeader navigation={navigation} route={route} options={options} layout={layout} />
          )
        },
      }}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{
          drawerLabel: translate("tabNavigator.home"),
          drawerIcon: ({ focused }) => (
            <Icon
              icon="home"
              size={26}
              color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
              style={$marginIcons}
            />
          ),
        }}
      />
      <Drawer.Screen
        name="Search"
        component={SearchScreen}
        initialParams={{ searchValue: null }}
        options={{
          drawerLabel: translate("tabNavigator.search"),
          drawerIcon: ({ focused }) => (
            <Icon
              icon="magnify"
              size={26}
              color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
              style={$marginIcons}
            />
          ),
        }}
      />
      {!user.isAnonymous && (
        <Drawer.Screen
          name="Activity"
          component={Activity}
          options={{
            drawerLabel: translate("tabNavigator.favorites"),
            drawerIcon: ({ focused }) => (
              <Icon
                icon="heartOutlined"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            ),
          }}
        />
      )}
      <Drawer.Screen
        name="Profile"
        component={Profile}
        options={{
          drawerLabel: translate("tabNavigator.profile"),
          drawerIcon: ({ focused }) => (
            <Icon
              icon="account"
              size={26}
              color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
              style={$marginIcons}
            />
          ),
        }}
      />
      <Drawer.Screen
        name="Explorer"
        component={ExplorerScreen}
        options={{
          drawerLabel: translate("tabNavigator.explorer"),
          drawerIcon: ({ focused }) => (
            <Icon
              icon="account"
              size={26}
              color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
              style={$marginIcons}
            />
          )
        }}
      />
    </Drawer.Navigator>
  )
}

const $marginIcons: ImageStyle = {
  marginLeft: 15,
}
