import React, { FC } from "react"
import { View, Image, ViewStyle, ImageBackground, ImageStyle, TextStyle } from "react-native"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { Button, Text, Screen, Icon } from "@app/components"
import auth from "@app/services/firebase/auth/Auth"
import { TabScreenProps } from "@app/navigators/DrawerNavigator"
import { typography, colors, spacing } from "@app/theme"
import { useNavigation } from "@app/navigators/utils"

const backgroundImage = require('../../../assets/images/backgroundBlue.png')

export const Profile: FC<TabScreenProps<"Profile">> = ({ navigation }) => {
  const { navigateTo } = useNavigation(navigation)
  const user = useAppSelector((state) => state.auth.user)
  const authUser = useAppSelector((state) => state.auth)

  const profileImage = require("../../../assets/images/profileImage.png")
  const avatar = authUser.isAnonymous ? profileImage : { uri: user.avatar_url || profileImage }
  return (
    <Screen
      preset="scroll"
      safeAreaEdges={[]}
      contentContainerStyle={$screenContentContainer}
    >
      <ImageBackground
        source={backgroundImage}
        style={$imgBackground}
        resizeMode="stretch"
      >
      <View style={$background}>
          <View style={$backgroundCircleAvatar}>
            <Image source={avatar} style={$profileImage} />
          </View>
      </View>
      <View style={$content}>
        {authUser.isAnonymous ? (
          <View style={$nameContainer}>
            <Text style={$nameText} tx="profile.anonymous" />
          </View>
        ) : (
          <>
            <View style={$nameContainer}>
              <Text style={$nameText} text={`${user.name} ${user.last_name}`} />
            </View>
            <View style={$emailContainer}>
              <View style={$iconContainer}>
                <Icon icon="emailIcon" size={24} />
              </View>
              <Text style={$emailText} text={user.email} />
            </View>
          </>
        )}
        {authUser.isAnonymous && (
          <View style={$notFoundContainerText2}>
            <Text text="Ups!" style={$notFoundText2} />
            <View style={$notFoundContainerText3}>
              <Text
                text="Al parecer no has iniciado sesión y no podemos ver tu perfil"
                style={$notFoundText3}
              />
            </View>
            <View style={$notFoundContainerText4}>
              <Text
                text="Presiona iniciar sesión para acceder o registrarte como nuevo usuario"
                style={$notFoundText4}
              />
            </View>
          </View>
        )}
      </View>

      <View style={$btnContent}>
        {authUser.isAnonymous ? (
          <Button
            onPress={() => navigateTo("Login")}
            tx="profile.logIn"
            preset="filled"
            style={$editBtn}
            pressedStyle={$editBtnPressed}
            textStyle={$editBtnText}
            pressedTextStyle={$editBtnTextPressed}
          />
        ) : (
          <>
            <View style={$editBtnContainer}>
              <Button
                onPress={() => navigateTo("EditProfile", { user })}
                tx="profile.editProfile"
                preset="filled"
                style={$editBtn}
                pressedStyle={$editBtnPressed}
                textStyle={$editBtnText}
                pressedTextStyle={$editBtnTextPressed}
              />
            </View>
            <Button
              onPress={() => {
                auth().signOut()
                navigateTo("Home")
              }}
              tx="profile.logOut"
              preset="filled"
              style={$logOutBtn}
              pressedStyle={$logOutBtnPressed}
              textStyle={$logOutBtnText}
              pressedTextStyle={$logOutBtnTextPressed}
            />
          </>
        )}
      </View>
    </ImageBackground>
    </Screen>
  )
}
const $notFoundContainerText3: ViewStyle = { marginTop: 14 }

const $notFoundText4: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $notFoundContainerText4: ViewStyle = { marginTop: 10, marginBottom: 15 }

const $notFoundText2: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 35,
  lineHeight: 42,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $notFoundContainerText2: ViewStyle = { marginTop: 10 }

const $notFoundText3: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $screenContentContainer: ViewStyle = {
  flexGrow: 1,
  backgroundColor: colors.black,
}

const $nameContainer: ViewStyle = { marginBottom: 40 }

const $emailContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

const $editBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $editBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $editBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $editBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $logOutBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $logOutBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $logOutBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $logOutBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $iconContainer: ViewStyle = { 
  marginRight: 14, 
  alignItems: 'center'
}

const $profileImage: ImageStyle = { 
  width: 109, 
  height: 109, 
  borderRadius: 100
}

const $editBtnContainer: ViewStyle = { marginBottom: 27 }

const $backgroundCircleAvatar: ViewStyle = {
  width: 145,
  height: 145,
  backgroundColor: colors.MAIN_DARK_BLUE,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  marginTop: 20
}

const $background: ViewStyle = {
  height: 200,
  flex: 0.1,
  alignItems: "center"
}

const $content: ViewStyle = {
  flex: 0.6,
  paddingVertical: 10,
  paddingHorizontal: 20,
}

const $nameText: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 28,
  fontStyle: "normal",
  lineHeight: 36,
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $emailText: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 15,
  lineHeight: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $btnContent: ViewStyle = {
  paddingHorizontal: 30,
  flex: 0.3,
  justifyContent: "flex-end",
  marginBottom: 100,
}
const $imgBackground: ImageStyle =  {
  flexGrow: 1,
  width: "auto",
  height: "auto",
  paddingBottom: spacing.large
  }