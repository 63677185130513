import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import { ModalEditProfile } from "@app/components/ModalEditProfile"
import {
  View,
  ViewStyle,
  ImageBackground,
  ImageStyle,
  TextStyle,
  Image,
  TouchableOpacity,
} from "react-native"
import { Button, Icon } from "@app/components"
import { colors, typography } from "@app/theme"
import useProfile from "../../screens/Profile/hooks/use-profile"
import { useNavigation } from "../utils"
import { useIsMounted } from "@app/utils/isMounted"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import auth from "@app/services/firebase/auth/Auth"
import { Text } from "@app/components"
import { useAppSelector } from "@app/store/hooks/useRedux"
type Anchor = "right"

const emailIcon = require("../../../assets/icons/web/mailIcon.png")

export default function DrawerWeb({ toggleDrawer, open }) {
  const { navigateTo } = useNavigation()
  const { showModal, hideModal } = useModal()

  const user = useAppSelector((state) => state.auth.user)
  const authUser = useAppSelector((state) => state.auth)

  const profileImage = require("../../../assets/images/profileImage.png")
  const avatar = authUser.isAnonymous ? profileImage : { uri: user.avatar_url || profileImage }

  const list = (anchor: Anchor) => (
    <Box
      sx={$box}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
      style={{ backgroundColor: colors.black }}
    >
      <List>
        <View style={$backgroundImage}>
          <View style={$backgroundCircleAvatar}>
            <Image source={avatar} style={$profileImage} />
          </View>
        </View>
        <View style={$content}>
          {authUser.isAnonymous ? (
            <>
              <View style={$nameContainer}>
                <Text style={$nameText} tx="profile.anonymous" />
              </View>
              <View style={$btnContent}>
                <Button
                  onPress={() => {
                    hideModal()
                    navigateTo("Login")
                  }}
                  text="Iniciar sesión"
                  preset="filled"
                  style={$logOutBtn}
                  textStyle={$logOutBtnText}
                />
              </View>
            </>
          ) : (
            <>
              <View style={$nameContainer}>
                <Text style={$nameText} text={`${user.name} ${user.last_name}`} />
              </View>
              <View style={$emailContainer}>
                <View style={$iconContainer}>
                  <img src={emailIcon} style={$emailIcon} />
                </View>
                <Text style={$emailText} text={user.email} />
              </View>
              <View style={$btnContent}>
                <View style={$editBtnContainer}>
                  <Button
                    onPress={() => {
                      navigateTo("Activity")
                      toggleDrawer(false)
                    }}
                    text="Mi actividad"
                    preset="filled"
                    style={$editBtn}
                    
                    textStyle={$editBtnText}
                  />
                  <Button
                    onPress={() => {
                      toggleDrawer(false)
                      showModal(
                        <ModalEditProfile hideModal={hideModal} navigateTo={navigateTo} />,
                        null,
                        588,
                      )
                    }}
                    text="Editar perfil"
                    preset="filled"
                    style={$editBtn}
                    textStyle={$editBtnText}
                  />

                  <Button
                    onPress={() => {
                      auth().signOut()
                      navigateTo("Home")
                      toggleDrawer(false)
                    }}
                    text="Cerrar sesión"
                    preset="filled"
                    style={$logOutBtn}
                    textStyle={$logOutBtnText}
                  />
                </View>
              </View>
            </>
          )}
        </View>
      </List>
    </Box>
  )

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          PaperProps={{ sx: { backgroundColor: colors.black } }}
          anchor={"right"}
          open={open}
          onClose={() => toggleDrawer(false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  )
}

const $box = {
  width: 305,
  backgroundColor: colors.black,
}

const $nameText: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
  marginTop: -30,
}
const $emailText: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
  marginBottom: 90,
  marginTop: -25,
  marginLeft: -5,
}
const $emailIcon: React.CSSProperties = {
  height: 24,
}

const $iconContainer: ViewStyle = { marginRight: 14, marginBottom: 90, marginTop: -25 }

const $editBtn: ViewStyle = {
  height: 40,
  minHeight: 40,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
  marginLeft: 5,
  marginRight: 5,
  marginVertical: 5,
  display: "flex",
  justifyContent: "center",
}

const $logOutBtn: ViewStyle = {
  height: 40,
  minHeight: 40,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
  marginLeft: 5,
  marginRight: 5,
  marginTop: 30,
  display: "flex",
  justifyContent: "center",
}



const $editBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $logOutBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $editBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $profileImage: ImageStyle = {
  width: 109,
  height: 109,
  borderRadius: 100,
  justifyContent: "flex-end",
}

const $editBtnContainer: ViewStyle = { marginBottom: 27 }

const $backgroundCircleAvatar: ViewStyle = {
  width: 145,
  height: 145,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: colors.black,
}

const $nameContainer: ViewStyle = { marginBottom: 40 }

const $emailContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

const $content: ViewStyle = {
  flex: 0.6,
  paddingHorizontal: 20,
}

const $backgroundImage: ImageStyle = {
  height: 203,
  width: "100%",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  backgroundColor: colors.black,
}

const $btnContent: ViewStyle = {
  paddingHorizontal: 30,
  flex: 0.2,
  justifyContent: "center",
  marginBottom: -60,
  backgroundColor: colors.black,
}
