type Types = "video" | "document" | "serie" | string

export const handleNavigate = (id: number, type: Types, navigateTo: any) => {
  switch (type) {
    case "video":
      navigateTo("Player", { videoId: id })
      break
    case "document":
      navigateTo("Document", { documentId: id })
      break
    case "serie":
      navigateTo("Serie", { serieId: id })
      break
    default:
      break
  }
}
