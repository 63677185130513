import { IDispatch } from "@app/store/interface"
import {
  fetchFavoritesById,
  createFavorite,
  deleteFavorite as deleteFavoriteApi,
} from "@app/services/api/favorites"
import { setFavorites, addFavorite, removeFavorite } from "@app/store/slices/favorites"
import { RootState } from "@app/store"

export const getFavorites = () => (dispatch: IDispatch, state: () => RootState) => {
  const userId = state().auth.user.id
  fetchFavoritesById(userId)
    .then((res) => {
      dispatch(setFavorites({ favorites: res.data.data }))
    })
    .catch((err) => console.error(err))
}

export const addNewFavorite =
  (entityId: number, type: string, cb: any) => (dispatch: IDispatch, state: () => RootState) => {
    const userId = state().auth.user.id
    cb && cb()
    createFavorite(userId, entityId, type)
      .then((res) => {
        dispatch(addFavorite({ favorites: res.data.data }))
        cb && cb()
      })
      .catch((err) => {
        cb && cb()
        console.error(err)
      })
  }

export const deleteFavorite =
  (entityId: number, type: string, cb: any) => (dispatch: IDispatch, state: () => RootState) => {
    const userId = state().auth.user.id
    cb && cb()
    deleteFavoriteApi(userId, entityId, type)
      .then((res) => {
        dispatch(removeFavorite({ favorites: res.data.data }))
        cb && cb()
      })
      .catch((err) => {
        cb && cb()
        console.error(err)
      })
  }
