import * as React from "react"
import { Box, Button } from "@mui/material"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import MenuItem from "@mui/material/MenuItem"
import InputBase from "@mui/material/InputBase"
import ButtonBase from "@mui/material/ButtonBase"
import { Link } from "react-router-dom"
import { useNavigation } from "../utils"
import DrawerWeb from "./DrawerWeb"
import { colors, typography } from "@app/theme"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { useLocation } from "@app/local_modules/react-router-dom"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { useToggleDrawer } from "./use-drawer-hook.web"
import { View, ViewStyle } from "react-native"
import { fetchPresenters } from "@app/services/api/presenters"
import { fetchCategories } from "@app/services/api/categories"
import { useEffect } from "react"
import { Button as ButtonLibbre } from "@app/components"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"

const LOGO = require("../../../assets/images/web/LogotipoOne.png")
const AVATAR = require("../../../assets/images/profileImage.png")
const SEARCH_ICON = require("../../../assets/icons/glassBigIcon.png")

async function obtenerPresentersResponse() {
  try {
    const PresentersResponse = await fetchPresenters()

    const presenters = PresentersResponse.data.data

    return presenters
  } catch (error) {
    console.log(error)
    return []
  }
}

async function obtenerCategoriesResponse() {
  try {
    const categoriesResponse = await fetchCategories()

    const categories = categoriesResponse.data.data

    return categories
  } catch (error) {
    console.log(error)
    return []
  }
}

function ResponsiveAppBar() {
  const { width } = useWindowDimensions()
  const location = useLocation()
  const authUser = useAppSelector((state) => state.auth)
  /*   const [anchorEl, setAnchorEl] = React.useState(null) */
  /*   const [selectedGenre, setSelectedGenre] = React.useState("Todos los géneros") */
  const { toggleDrawer, openDrawer } = useToggleDrawer()
  const [presenters, setPresenters] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const { navigateTo } = useNavigation()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [searchValue, setSearchValue] = React.useState("")
  const [isMobile, setIsMobile] = React.useState(false)

  useEffect(() => {
    const setResponsiveness = () => {
      setIsMobile(window.innerWidth <= 1300)
    }

    setResponsiveness()

    window.addEventListener("resize", setResponsiveness)
  }, [])

  useEffect(() => {
    obtenerPresentersResponse().then((presenters) => {
      setPresenters(presenters)
    })
  }, [])

  useEffect(() => {
    obtenerCategoriesResponse().then((categories) => {
      setCategories(categories)
    })
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: colors.SHADES_OF_BLACK, height: APP_BAR_HEIGHT }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "Flex", marginRight: 50 } }}>
            <Link to="/" relative="path">
              <img src={LOGO} style={$logo} alt="logo" />
            </Link>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                borderRadius: 0,
                display: { xs: "block", md: "none" },
                "& .MuiMenu-paper": {
                  borderRadius: 0,
                },
              }}
            >
              <MenuItem onClick={() => navigateTo("Home")}>
                <Typography textAlign="center">Inicio</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigateTo("Nosotros")}>
                <Typography textAlign="center">
                  {isMobile ? "Sobre Nosotros" : "Nosotros"}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => navigateTo("FAQ")}>
                <Typography textAlign="center">
                  {isMobile ? "Preguntas Frecuentes" : "FAQ"}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => navigateTo("Explorer")} divider>
                <Typography textAlign="center">Explorar</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigateTo("Login")}>
                <Typography textAlign="center">Iniciar sesión</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigateTo("Register")}>
                <Typography textAlign="center">Registrarse</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: {
                flexGrow: 1,
                xs: "flex",
                md: "none",
                justifyContent: "center",
              },
            }}
          >
            <img src={LOGO} style={$logo} alt="logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={() => navigateTo("Home")}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Montserrat",
                whiteSpace: "nowrap",
              }}
            >
              Inicio
            </Button>
            <Button
              onClick={() => navigateTo("Nosotros")}
              sx={{
                my: 2,
                mx: 2,
                color: "white",
                display: "block",
                fontFamily: "Montserrat",
                whiteSpace: "nowrap",
              }}
            >
              {isMobile ? "Nosotros" : "Sobre Nosotros"}
            </Button>
            <Button
              onClick={() => navigateTo("FAQ")}
              sx={{
                my: 2,
                mx: 2,
                color: "white",
                display: "block",
                fontFamily: "Montserrat",
                whiteSpace: "nowrap",
              }}
            >
              {isMobile ? "FAQ" : "Preguntas Frecuentes "}
            </Button>
            <Button
              onClick={() => {
                navigateTo("Explorer")
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block", fontFamily: "Montserrat" }}
            >
              Explorar
            </Button>
          </Box>
          {width > 900 && width > 1050 && (
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex", justifyContent: "flex-end" } }}
            >
              <InputBase
                value={searchValue}
                disabled={location?.pathname === "/Search"}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigateTo("Search", { searchValue })
                    setSearchValue("")
                  }
                }}
                placeholder="Buscar"
                style={$searchInput}
                sx={{
                  "& .MuiInputBase-input": {
                    fontFamily: typography.fonts.montserrat.normal,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    lineHeight: 18,
                  },
                }}
              />
              <Box sx={{ marginLeft: 2, marginRight: 5 }}>
                <ButtonBase disableTouchRipple>
                  <img
                    src={SEARCH_ICON}
                    style={$searchIcon}
                    onClick={() => {
                      navigateTo("Search", { searchValue })
                      setSearchValue("")
                    }}
                  />
                </ButtonBase>
              </Box>
            </Box>
          )}

          {authUser.isAuth ? (
            <ButtonBase
              sx={{ flexGrow: 0, borderRadius: 100 }}
              component="div"
              onClick={() => {
                toggleDrawer(true)
              }}
            >
              <img
                src={authUser.isAuth ? authUser.user.avatar_url : AVATAR}
                style={$avatar}
                alt="avatar"
              />
            </ButtonBase>
          ) : (
            width > 900 && (
              <View style={$loginBtnContainer}>
                <ButtonLibbre
                  onPress={() => {
                    navigateTo("Login")
                  }}
                  preset="filled"
                  style={$btnRegister}
                  textStyle={{
                    fontFamily: typography.fonts.montserrat.bold,
                    fontSize: 16,
                    lineHeight: 18,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: colors.MAIN_DARK_BLUE,
                  }}
                >
                  INGRESAR
                </ButtonLibbre>
                <View style={{ margin: 5 }} />
                <ButtonLibbre
                  onPress={() => {
                    navigateTo("Register")
                  }}
                  preset="filled"
                  style={$btnRegister}
                  textStyle={{
                    fontFamily: typography.fonts.montserrat.bold,
                    fontSize: 16,
                    lineHeight: 18,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: colors.MAIN_DARK_BLUE,
                  }}
                >
                  REGISTRAR
                </ButtonLibbre>
              </View>
            )
          )}
          <DrawerWeb toggleDrawer={toggleDrawer} open={openDrawer} />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const $loginBtnContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
}
const $btnRegister: ViewStyle = {
  height: 40,
  minHeight: 40,
  borderRadius: 30,
  maxWidth: 155,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $avatar: React.CSSProperties = {
  height: 45,
  width: 45,
  borderRadius: 100,
  objectFit: "cover",
}

const $logo: React.CSSProperties = {
  height: 45,
}

const $searchIcon: React.CSSProperties = { height: 40 }

const $searchInput: React.CSSProperties = {
  marginLeft: 10,
  maxWidth: 300,
  height: 40,
  borderRadius: 7,
  backgroundColor: colors.black,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.WHITE,
  color: colors.WHITE,
  padding: 15,
}

export default ResponsiveAppBar
