import React from "react"
import { useNavigate, useLocation } from "@app/local_modules/react-router-dom"
import { goBack as goBackApp, navigate as navigationApp } from "@app/navigators"
import { isWeb } from "@app/utils/isWeb"

interface Navigation {
  navigate(path: string, params?: Record<string, unknown>): void
}

interface Router {
  params: Record<string, unknown>
}

interface Params {
  [key: string]: any
}

export const useNavigation = (navigation?: Navigation, router?: Router) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isWebApp = isWeb()
  const params = isWebApp ? location?.state : (router?.params as Params)

  const navigateTo = (path: string, params?: Params) => {
    let paramsWeb = ["videoId"]
    let pathAndParams = `/${path}?`;

    if (params) {
      paramsWeb = Object.keys(params)
      for (let i = 0; i < paramsWeb.length; i++) {
        pathAndParams += `${paramsWeb[i]}=${params[paramsWeb[i]]}`
      }
    }
    
    // Si viene mas de un param hay que iterarlo y conseguir los object keys como hice con el primero
    // const paramTest = { videoId: 1, searchText: "search ejemplo", serieId: 2 }
    // Ej: navigate(`/${path}?${paramsWeb[0]}=${params[paramsWeb[0]]}&${paramsWeb[1]}=${params[paramsWeb[1]]}&${paramsWeb[2]}=${params[paramsWeb[2]]}`)
    // params[paramsWeb[0]] === params.videoId
    isWebApp
      ? params 
        // ?navigate(`/${path}?${paramsWeb[0]}=${params[paramsWeb[0]]}`)
        ?navigate(pathAndParams)
        : navigate(`/${path}`)
      : navigation
      ? navigation.navigate(path, params)
      : navigationApp(path, params)
  }

  const goBack = (number = -1) => {
    isWebApp ? navigate(number) : goBackApp()
  }

  return {
    navigateTo,
    params,
    goBack,
  }
}