import React, { useEffect, useState } from "react"
import { fetchCategories } from "@app/services/api/categories"
import { fetchPresenters } from "@app/services/api/presenters"
import type { Video } from "@app/interfaces/video.interface"
import type { Serie } from "@app/interfaces/serie.interface"
import { Category } from "@app/interfaces/category.interface"
import { Presenter } from "@app/interfaces/presenter.interface"
import { fetchSearchEntities } from "@app/services/api/search"
import { Document } from "@app/interfaces/document.interface"
import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"

async function obtenerPresentersResponse() {
  try {
    const PresentersResponse = await fetchPresenters()

    const presenters = PresentersResponse.data.data

    return presenters
  } catch (error) {
    console.log(error)
    return []
  }
}

async function obtenerCategoriesResponse() {
  try {
    const categoriesResponse = await fetchCategories()

    const categories = categoriesResponse.data.data

    return categories
  } catch (error) {
    console.log(error)
    return []
  }
}

const useExplorer = () => {
  const auth = useAppSelector((state) => state.auth)
  const favorites = useAppSelector((state) => state.favorites)
  const [favStates, setFavStates] = useState({})
  const dispatch = useAppDispatch()
  const { showModal, hideModal } = useModal()
  const [categories, setCategories] = useState<Category[]>([])
  const [presenters, setPresenters] = useState<Presenter[]>([])
  const [isFirstSearch, setIsFirstSearch] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<{
    videos: Video[]
    documents: Document[]
    series: Serie[]
  }>({
    videos: [],
    documents: [],
    series: [],
  })
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [selectedPresenter, setSelectedPresenter] = React.useState(null)

  const backgroundNotFound = require("../../../../assets/images/backgroundNotFound.png")
  const backgroundBlue = require("../../../../assets/images/backgroundBlue.png")

  useEffect(() => {
    obtenerPresentersResponse().then((presenters) => {
      setPresenters(presenters)
    })
  }, [])

  useEffect(() => {
    obtenerCategoriesResponse().then((categories) => {
      setCategories(categories)
    })
  }, [])

  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (entityId: number, type: string) => {
    dispatch(addNewFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const removeFavorite = (entityId: number, type: string) => {
    dispatch(deleteFavorite(entityId, type, () => handleFavState(entityId, type)))
  }

  const handleRemoveFilters = () => {
    setSelectedCategory(null)
    setSelectedPresenter(null)
    setSearchResults({
      videos: [],
      documents: [],
      series: [],
    })
  }

  const exploreResults = async (id: number, type: string) => {
    setIsLoading(true)

    if (type === "category") {
      setSelectedCategory(id)
    }
    if (type === "presenter") {
      setSelectedPresenter(id)
    }

    try {
      const searchResponse = await fetchSearchEntities({
        ...(type === "presenter"
          ? { presenters: [id] }
          : selectedPresenter && { presenters: [selectedPresenter] }),
        ...(type === "category"
          ? { categories: [id] }
          : selectedCategory && { categories: [selectedCategory] }),
      })

      setSearchResults(searchResponse.data.data)
      setIsLoading(false)
      setIsFirstSearch(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  return {
    handleRemoveFilters,
    presenters,
    categories,
    selectedCategory,
    selectedPresenter,
    exploreResults,
    isLoading,
    searchResults,
    isFirstSearch,
    auth,
    favStates,
    addFavorite,
    removeFavorite,
    isInFavorites,
    showModal,
    hideModal,
    backgroundNotFound,
    backgroundBlue,
  }
}

export default useExplorer
