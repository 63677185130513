export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyC2QMqtYVXrqrXi9oyJgilNLf2FerV_WpY",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "libbre-prod.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "faro-prod-60c5a",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "faro-prod-60c5a.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "853357115144",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:853357115144:web:fb263be04ff65c87b35ebd",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
