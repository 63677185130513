/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react"
import { setIsLoading } from "@app/store/slices/app"
import { useAppDispatch, useAppSelector } from "@app/store/hooks/useRedux"
import { useFormik } from "formik"
import * as Yup from "yup"
import axiosInt from "@app/services/axios"

const useFeedback = () => {
  const [isVisible, setVisible] = useState(false)
  const [typeModal, setTypeModal] = useState("")
  const { isLoading } = useAppSelector((state) => state.app)
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      comment: "",
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .min(10, "Mínimo 5 caracteres")
        .max(50, "Máximo 50 caracteres")
        .trim()
        .required("Este campo es requerido"),
      email: Yup.string()
        .email("Email no válido")
        .min(8, "Mínimo 8 caracteres")
        .max(50, "Máximo 50 caracteres")
        .trim()
        .required("Este campo es requerido"),
      comment: Yup.string()
        .min(10, "Mínimo 10 caracteres")
        .max(600, "Máximo 600 caracteres")
        .trim()
        .required("Este campo es requerido"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        dispatch(setIsLoading(true))

        await axiosInt.post("/api/feedback", {
          fullName: values.fullName,
          email: values.email,
          comment: values.comment,
        })
        setVisible(true)
        setTypeModal("success")
        setStatus({ success: true })
        setSubmitting(true)
        dispatch(setIsLoading(false))
      } catch (error) {
        console.log("Error enviar feedback:", error)
        dispatch(setIsLoading(false))
        setVisible(true)
        setTypeModal("error")
        setStatus({ success: false })
        if (error.code) {
          setErrors(error.code)
        }
      }
    },
  })

  return {
    isLoading,
    isVisible,
    setVisible,
    typeModal,
    setTypeModal,
    formik,
  }
}

export default useFeedback
