// React components and hooks:
import React, { FC } from "react"

// Navigator:
import { AppStackScreenProps } from "@app/navigators"

// Screens:
import { Screen } from "@app/components"

// Components:
import RegisterForm from "./components/RegisterForm"
import { ViewStyle } from "react-native"
import { colors } from "@app/theme"

// Interface:
interface RegisterScreenProps extends AppStackScreenProps<"Register"> {}

// Component:
const RegisterScreen: FC<RegisterScreenProps> = () => {
  return (
    <Screen
      preset="scroll"
      contentContainerStyle={$screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
      backgroundColor={colors.MAIN_DARK_BLUE}
    >
      <RegisterForm />
    </Screen>
  )
}

export default RegisterScreen

const $screenContentContainer: ViewStyle = {
  flex: 1,
  backgroundColor: colors.WHITE,
}
