import * as React from "react"
import { ComponentType } from "react"
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native"

export type IconTypes = keyof typeof iconRegistry

interface IconProps extends TouchableOpacityProps {
  /**
   * The name of the icon
   */
  icon: IconTypes

  /**
   * An optional tint color for the icon
   */
  color?: string

  /**
   * An optional size for the icon. If not provided, the icon will be sized to the icon's resolution.
   */
  size?: number

  /**
   * Style overrides for the icon image
   */
  style?: StyleProp<ImageStyle>

  /**
   * Style overrides for the icon container
   */
  containerStyle?: StyleProp<ViewStyle>

  /**
   * An optional function to be called when the icon is pressed
   */
  onPress?: TouchableOpacityProps["onPress"]
}

/**
 * A component to render a registered icon.
 * It is wrapped in a <TouchableOpacity /> if `onPress` is provided, otherwise a <View />.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-Icon.md)
 */
export function Icon(props: IconProps) {
  const {
    icon,
    color,
    size,
    style: $imageStyleOverride,
    containerStyle: $containerStyleOverride,
    ...WrapperProps
  } = props

  const isPressable = !!WrapperProps.onPress
  const Wrapper: ComponentType<TouchableOpacityProps> = WrapperProps?.onPress
    ? TouchableOpacity
    : View

  return (
    <Wrapper
      accessibilityRole={isPressable ? "imagebutton" : undefined}
      {...WrapperProps}
      style={$containerStyleOverride}
    >
      <Image
        style={[
          $imageStyle,
          color && { tintColor: color },
          size && { width: size, height: size },
          $imageStyleOverride,
        ]}
        source={iconRegistry[icon]}
      />
    </Wrapper>
  )
}

export const iconRegistry = {
  back: require("../../assets/icons/component5.png"),
  bell: require("../../assets/icons/bell.png"),
  caretLeft: require("../../assets/icons/caretLeft.png"),
  caretRight: require("../../assets/icons/caretRight.png"),
  check: require("../../assets/icons/check.png"),
  clap: require("../../assets/icons/clap.png"),
  community: require("../../assets/icons/community.png"),
  components: require("../../assets/icons/components.png"),
  debug: require("../../assets/icons/debug.png"),
  github: require("../../assets/icons/github.png"),
  heart: require("../../assets/icons/heartFilledIcon.png"),
  heartWeb: require("../../assets/icons/heartFilledIcon-3x.png"),
  heartOutlined: require("../../assets/icons/favDrawerIcon.png"),
  heartOutlinedWeb: require("../../assets/icons/favDrawerIconWeb.png"),
  hidden: require("../../assets/icons/hidden.png"),
  ladybug: require("../../assets/icons/ladybug.png"),
  lock: require("../../assets/icons/lock.png"),
  menu: require("../../assets/icons/menu.png"),
  more: require("../../assets/icons/more.png"),
  pin: require("../../assets/icons/pin.png"),
  podcast: require("../../assets/icons/podcast.png"),
  settings: require("../../assets/icons/settings.png"),
  slack: require("../../assets/icons/slack.png"),
  view: require("../../assets/icons/view.png"),
  x: require("../../assets/icons/x.png"),
  magnify: require("../../assets/icons/searchDrawerIcon.png"),
  expandDown: require("../../assets/icons/expand-down.png"),
  plus: require("../../assets/icons/plus.png"),
  plusCircleOutline: require("../../assets/icons/plus-circle-outline.png"),
  home: require("../../assets/icons/homeDrawerIcon.png"),
  filter: require("../../assets/icons/filterIcon.png"),
  account: require("../../assets/icons/profileDrawerIcon.png"),
  bookmarkAddOutline: require("../../assets/icons/bookmark-plus-outline.png"),
  bookmarkCheck: require("../../assets/icons/bookmark-check.png"),
  bookmarkMultiple: require("../../assets/icons/bookmark-multiple.png"),
  drawer: require("../../assets/icons/drawerIconWhite.png"),
  arrowDownCircle: require("../../assets/icons/arrowDownIcon.png"),
  leftArrowFilledCircle: require("../../assets/icons/leftArrowFilledCircle.png"),
  leftArrowFilledCircleWeb: require("../../assets/icons/leftArrowFilledCircleWeb.png"),
  email: require("../../assets/icons/email.png"),
  document: require("../../assets/icons/docIcon.png"),
  edit: require("../../assets/icons/iconEdit.png"),
  camera: require("../../assets/icons/cameraIcon.png"),
  facebook: require("../../assets/icons/uil_facebook-f.png"),
  instagram: require("../../assets/icons/ri_instagram-line.png"),
  youtube: require("../../assets/icons/ri_youtube-line.png"),
  arrowBack: require("../../assets/icons/arrowBackCircle.png"),
  emailIcon: require("../../assets/icons/mailIcon.png"),
  explorerIcon: require("../../assets/icons/explorerIcon.png"),
  arrowDownFilled: require("../../assets/icons/arrowDownFilled.png"),
  arrowUpFilled: require("../../assets/icons/arrowUpFilled.png"),
  tiktok: require("../../assets/icons/tiktok-logo.png")
}

const $imageStyle: ImageStyle = {
  resizeMode: "contain",
}
