const es = {
  updateScreen: {
    updateTitle: "Nueva version disponible",
    updateText1: "Existe una nueva versión de la aplicación.",
    updateText2: "Presiona el botón y actualiza a la ultima versión",
    updateBtn: "Actualizar",
    maintenanceTitle: "Estamos en mantenimiento",
    maintenanceBtn: "Salir de la app",
  },
  profile: {
    editProfile: "Editar perfil",
    logOut: "Cerrar sesión",
    logIn: "Iniciar sesión",
    anonymous: "Anónimo",
  },
  drawerCustom: {
    home: "Inicio",
    search: "Buscar",
    activity: "Actividad",
    profile: "Perfil",
    logOut: "Cerrar sesión",
    logIn: "Iniciar sesión",
    anonymous: "Anónimo",
    explorer: "Explorar",
  },
  common: {
    ok: "OK!",
    cancel: "Cancel",
    back: "Back",
    logOut: "Cerrar sesión", // @demo remove-current-line
  },
  autocompleteComponent: {
    noResults: "No hay resultados",
  },
  welcomeScreen: {
    postscript:
      "psst  — This probably isn't what your app looks like. (Unless your designer handed you these screens, and in that case, ship it!)",
    readyForLaunch: "Your app, almost ready for launch!",
    exciting: "(ohh, this is exciting!)",
    letsGo: "Let's go!", // @demo remove-current-line
  },
  errorScreen: {
    title: "Something went wrong!",
    friendlySubtitle:
      "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
    reset: "RESET APP",
    traceTitle: "Error from %{name} stack", // @demo remove-current-line
  },
  emptyStateComponent: {
    generic: {
      heading: "So empty... so sad",
      content: "No data found yet. Try clicking the button to refresh or reload the app.",
      button: "Let's try this again",
    },
  },
  // @demo remove-block-start
  errors: {
    invalidEmail: "Invalid email address.",
  },
  loginScreen: {
    title: "Hola!",
    welcome: "Bienvenido a nuestra plataforma digital",
    emailFieldLabel: "Email",
    passwordFieldLabel: "Contraseña",
    emailFieldPlaceholder: "Ingresa tu email",
    passwordFieldPlaceholder: "Ingresa tu contraseña",
    forgotPassword: "¿Olvidaste tu contraseña?",
    tapToSignIn: "Iniciar sesión",
    tapToRegister: "Registrarse",
  },
  homeScreen: {
    greetings: "Hola",
    anonymous: "Anónimo",
  },
  forgotPasswordScreen: {
    titleForm: "Recuperación de contraseña",
    labelEmail: "Ingrese su email",
    labelRepeatEmail: "Vuelva a escribir su email",
    emailPlaceholder: "Ingrese su email",
  },
  sendEmailSuccessScreen: {
    header: "Correo electrónico",
    sendEmail: "Correo enviado!",

    titleh3: "Accedé al enlace enviado para comenzar el proceso de recuperación",
    btn1: "Aún no recibí el correo",
    btn2: "Finalizar",
  },
  playerScreen: {
    documentAttachmentLabel: "Documento adjunto:",
    downloadBtn: "Descargar",
  },
  searchScreen: {
    confirmText: "Confirmar",
    removeAllText: "Quitar todo",
    cleanText: "Limpiar",
    searchInputPlaceholder: "Búsqueda",
    selectCategoriesTitle: "Categorías",
    selectCategoriesInputText: "Buscar por categorías...",
    selectCategoriesPlaceholderText: "Buscar categorías",
    selectedCategoriesText: "seleccionadas",
    selectPresenterTitle: "Presentadores",
    selectPresenterInputText: "Buscar por presentador...",
    selectPresenterPlaceholderText: "Buscar presentadores",
    selectedPresenterText: "seleccionadas",
    applyFilterTextBtn: "Aplicar",
    cancelBtnText: "Quitar",
  },
  registerScreen: {
    // header:
    titleScreen: "Registrarme",

    // labels:
    avatarFieldLabel: "Avatar:",
    nameFieldLabel: "Nombre",
    lastNameFieldLabel: "Apellido",
    emailFieldLabel: "Email",
    passwordFieldLabel: "Contraseña",
    passwordConfirmFieldLabel: "Confirmar contraseña",

    // placeholders:
    nameFieldPlaceHolder: "Ingresa tu nombre",
    lastNameFieldPlaceHolder: "Ingresa tu apellido",
    emailFieldPlaceHolder: "Ingresa tu email",
    // Option 1:
    passwordSecurityEntryPlaceholder: "********",

    // Option 2:
    passwordFieldPlaceHolder: "Ingresa tu contraseña",
    passwordConfirmFieldPlaceHolder: "Repita su contraseña",

    // button create new user:
    tapToConfirm: "Confirmar",
  },
  feedbackScreen: {
    // header:
    titleScreen: "Contacto",

    // labels:
    fullNameFieldLabel: "Nombre Completo",
    emailFieldLabel: "Correo Electrónico",
    comentarioFieldLabel: "Comentario",

    // placeholder
    fullNameFieldPlaceHolder: "Ingresa tu nombre y apellido",
    emailFieldPlaceHolder: "Ingresa tu email",
    comentarioFieldPlaceHolder: "Ingresa tu comentario...",

    // button
    tapToConfirm: "Confirmar",
    tapToCancel: "Cancelar",
  },

  demoNavigator: {
    componentsTab: "Categorías",
    debugTab: "Perfil",
    communityTab: "Home",
    userFavorites: "Favoritos",
    userProfile: "Perfil",
  },
  tabNavigator: {
    home: "Inicio",
    search: "Buscar",
    favorites: "Actividad",
    profile: "Perfil",
    explorer: "Explorar",
  },
  demoCommunityScreen: {
    title: "Connect with the community",
    tagLine:
      "Plug in to Infinite Red's community of React Native engineers and level up your app development with us!",
    joinUsOnSlackTitle: "Join us on Slack",
    joinUsOnSlack:
      "Wish there was a place to connect with React Native engineers around the world? Join the conversation in the Infinite Red Community Slack! Our growing community is a safe space to ask questions, learn from others, and grow your network.",
    joinSlackLink: "Join the Slack Community",
    makeIgniteEvenBetterTitle: "Make Ignite even better",
    makeIgniteEvenBetter:
      "Have an idea to make Ignite even better? We're happy to hear that! We're always looking for others who want to help us build the best React Native tooling out there. Join us over on GitHub to join us in building the future of Ignite.",
    contributeToIgniteLink: "Contribute to Ignite",
    theLatestInReactNativeTitle: "The latest in React Native",
    theLatestInReactNative: "We're here to keep you current on all React Native has to offer.",
    reactNativeRadioLink: "React Native Radio",
    reactNativeNewsletterLink: "React Native Newsletter",
    reactNativeLiveLink: "React Native Live",
    chainReactConferenceLink: "Chain React Conference",
    hireUsTitle: "Hire Infinite Red for your next project",
    hireUs:
      "Whether it's running a full project or getting teams up to speed with our hands-on training, Infinite Red can help with just about any React Native project.",
    hireUsLink: "Send us a message",
  },
  demoShowroomScreen: {
    jumpStart: "Components to jump start your project!",
    lorem2Sentences:
      "Nulla cupidatat deserunt amet quis aliquip nostrud do adipisicing. Adipisicing excepteur elit laborum Lorem adipisicing do duis.",
    demoHeaderTxExample: "Yay",
    demoViaTxProp: "Via `tx` Prop",
    demoViaSpecifiedTxProp: "Via `{{prop}}Tx` Prop",
  },
  demoDebugScreen: {
    howTo: "HOW TO",
    title: "Debug",
    tagLine:
      "Congratulations, you've got a very advanced React Native app template here.  Take advantage of this boilerplate!",
    reactotron: "Send to Reactotron",
    reportBugs: "Report Bugs",
    demoList: "Demo List",
    demoPodcastList: "Demo Podcast List",
    androidReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running, run adb reverse tcp:9090 tcp:9090 from your terminal, and reload the app.",
    iosReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    macosReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    webReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    windowsReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
  },
  demoPodcastListScreen: {
    title: "Usuarios 2.0ss",
    onlyFavorites: "Only Show Favorites",
    favoriteButton: "Favorite",
    unfavoriteButton: "Unfavorite",
    accessibility: {
      cardHint:
        "Double tap to listen to the episode. Double tap and hold to {{action}} this episode.",
      switch: "Switch on to only show favorites",
      favoriteAction: "Toggle Favorite",
      favoriteIcon: "Episode not favorited",
      unfavoriteIcon: "Episode favorited",
      publishLabel: "Published {{date}}",
      durationLabel: "Duration: {{hours}} hours {{minutes}} minutes {{seconds}} seconds",
    },
    noFavoritesEmptyState: {
      heading: "This looks a bit empty",
      content:
        "No favorites have been added yet. Tap the heart on an episode to add it to your favorites!",
    },
  },
  // @demo remove-block-end
}

export default es
export type Translations = typeof es
