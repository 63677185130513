import React, { FC, useState } from "react"
import { View, Image, ImageStyle, ViewStyle, TextStyle, TouchableOpacity } from "react-native"
import { Text, Button, Icon } from "@app/components"
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
  DrawerItem,
} from "@react-navigation/drawer"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { AVATAR_PLACEHOLDER } from "@app/utils/constants"
import { typography, colors } from "@app/theme"
import auth from "@app/services/firebase/auth/Auth"
import { translate } from "@app/i18n"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import { useNavigation } from "@app/navigators/utils"
import { Link } from "@react-navigation/native"

export const CustomDrawer: FC<DrawerContentComponentProps> = (props) => {
  const { navigateTo } = useNavigation()
  const authUser = useAppSelector((state) => state.auth)
  const user = useAppSelector((state) => state.auth.user)
  const { showModal, hideModal } = useModal()
  const [currentScreen, setCurrentScreen] = useState<string>("Home")
  const avatarAnonymous = require("../../../assets/images/profileImage.png")
  return (
    <View style={$drawerContainerStyle}>
      <View style={$profileContainer}>
        <View style={$profilePictureContainer}>
          <Image
            style={$profilePicture}
            source={
              authUser.isAnonymous
                ? avatarAnonymous
                : { uri: user.avatar_url || AVATAR_PLACEHOLDER }
            }
          />
        </View>
        <View style={$profileNameContainer}>
          {authUser.isAnonymous ? (
            <Text style={$profileName} tx="drawerCustom.anonymous" />
          ) : (
            <Text style={$profileName}>{`${user.name} ${user.last_name}`}</Text>
          )}
        </View>
      </View>
      <DrawerContentScrollView {...props}>
        <View style={$drawerItemContainer}>
          <DrawerItem
            label={translate("drawerCustom.home")}
            focused={currentScreen === "Home"}
            onPress={() => {
              props.navigation.navigate("Home")
              setCurrentScreen("Home")
            }}
            style={$style}
            labelStyle={$labelStyle}
            icon={({ focused }) => (
              <Icon
                icon="home"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            )}
            activeTintColor={colors.SECONDARY_VIOLET}
          />
          <DrawerItem
            label={translate("drawerCustom.search")}
            focused={currentScreen === "Search"}
            onPress={() => {
              props.navigation.navigate("Search")
              setCurrentScreen("Search")
            }}
            style={$style}
            labelStyle={$labelStyle}
            icon={({ focused }) => (
              <Icon
                icon="magnify"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            )}
            activeTintColor={colors.SECONDARY_VIOLET}
          />
          <DrawerItem
            label={translate("drawerCustom.activity")}
            focused={currentScreen === "Activity"}
            onPress={() => {
              if (authUser.isAnonymous) {
                showModal(<ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />)
              } else {
                props.navigation.navigate("Activity")
                setCurrentScreen("Activity")
              }
            }}
            style={$style}
            labelStyle={$labelStyle}
            icon={({ focused }) => (
              <Icon
                icon="heartOutlined"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            )}
            activeTintColor={colors.SECONDARY_VIOLET}
          />
          <DrawerItem
            label={translate("drawerCustom.profile")}
            focused={currentScreen === "Profile"}
            onPress={() => {
              props.navigation.navigate("Profile")
              setCurrentScreen("Profile")
            }}
            style={$style}
            labelStyle={$labelStyle}
            icon={({ focused }) => (
              <Icon
                icon="account"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            )}
            activeTintColor={colors.SECONDARY_VIOLET}
          />
          <DrawerItem
            label={translate("drawerCustom.explorer")}
            focused={currentScreen === "Explorer"}
            onPress={() => {
              props.navigation.navigate("Explorer")
              setCurrentScreen("Explorer")
            }}
            style={$style}
            labelStyle={$labelStyle}
            icon={({ focused }) => (
              <Icon
                icon="explorerIcon"
                size={26}
                color={focused ? colors.SECONDARY_VIOLET : colors.SECONDARY_VIOLET}
                style={$marginIcons}
              />
            )}
            activeTintColor={colors.SECONDARY_VIOLET}
          />
        </View>
      </DrawerContentScrollView>
      <View style={$linksContainer}>
        <TouchableOpacity onPress={() => navigateTo("Nosotros")}>
          <Text style={$linksTextStyle}>Nosotros</Text>
        </TouchableOpacity>
        <Text style={[$linksTextStyle, { marginHorizontal: 10 }]}>-</Text>
        <TouchableOpacity onPress={() => navigateTo("FAQ")}>
          <Text style={$linksTextStyle}>FAQ</Text>
        </TouchableOpacity>
      </View>
      <View style={$buttonsContainer}>
        {authUser.isAnonymous ? (
          <Button
            onPress={() => props.navigation.navigate("Login")}
            preset="filled"
            style={$logInBtn}
            textStyle={$logInBtnText}
            pressedStyle={$logInBtnPressed}
            tx="drawerCustom.logIn"
          />
        ) : (
          <Button
            onPress={() => {
              auth().signOut()
              props.navigation.navigate("Home")
            }}
            preset="filled"
            style={$logOutBtn}
            textStyle={$logOutBtnText}
            pressedStyle={$logOutBtnPressed}
            tx="drawerCustom.logOut"
          />
        )}
      </View>
    </View>
  )
}

const $linksContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "center",
}

const $linksTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 16.5,
  lineHeight: 18,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.SECONDARY_VIOLET,
}

const $drawerContainerStyle: ViewStyle = { backgroundColor: colors.SHADES_OF_BLACK, height: "100%" }

const $labelStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 20,
  lineHeight: 32,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $marginIcons: ImageStyle = {
  marginLeft: 15,
}

const $style: ViewStyle = {
  height: 60,
  justifyContent: "center",
  marginLeft: 15,
  borderRadius: 3,
}

const $profileContainer: ViewStyle = {
  height: 180,
  marginTop: 50,
  justifyContent: "center",
  alignSelf: "center",
}

const $profilePictureContainer: ViewStyle = {
  borderRadius: 100,
  alignItems: "center",
}

const $profilePicture: ImageStyle = {
  borderRadius: 100,
  width: 109,
  height: 109,
}

const $profileNameContainer: ViewStyle = {
  marginTop: 10,
}

const $profileName: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 20,
  lineHeight: 36,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $drawerItemContainer: ViewStyle = { paddingHorizontal: 10 }

const $buttonsContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 0.1,
}

const $logOutBtn: ViewStyle = {
  width: 224,
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $logOutBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $logOutBtnPressed: ViewStyle = {
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $logInBtn: ViewStyle = {
  width: 224,
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $logInBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $logInBtnPressed: ViewStyle = {
  backgroundColor: colors.LIGHT_GRAY,
}
