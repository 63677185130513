/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  // DarkTheme,
  DefaultTheme,
  NavigationContainer,
  NavigatorScreenParams,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { StackScreenProps } from "@react-navigation/stack"
import React from "react"
// import { useColorScheme } from "react-native"
import Config from "../config"
import { LoginScreen } from "@app/screens/Auth/Login"
import { DrawerNavigator, TabParamList } from "./DrawerNavigator"
import { EditProfile } from "@app/screens/Profile/EditProfile"
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { PlayerScreen } from "@app/screens/Player"
import { ExplorerScreen } from "@app/screens/Explorer"
import RegisterScreen from "@app/screens/Auth/Register"
import { LoadingSplash } from "@app/screens/LoadingSplash"
import ForgotPassword from "@app/screens/Auth/ForgotPassword"
import { User } from "@app/interfaces/user.interface"
import SendEmailSuccess from "@app/screens/Auth/SendEmailSuccess"
import { DocumentScreen } from "@app/screens/Document"
import { SerieScreen } from "@app/screens/Series"
import { NosotrosScreen } from "@app/screens/Nosotros"
import { FAQScreen } from "@app/screens/FAQ"
/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
  Home: undefined
  Login: undefined
  Register: undefined
  LoadingSplash: undefined
  ForgotPassword: undefined
  SendEmailSuccess: undefined
  TabNavigator: NavigatorScreenParams<TabParamList>
  EditProfile: { user: User }
  Profile: NavigatorScreenParams<TabParamList>
  UserFavorites: NavigatorScreenParams<TabParamList>
  Player: { videoId: number }
  Explorer: { videoId: number }
  Document: { documentId: number }
  Serie: { serieId: number }
  Nosotros: undefined
  FAQ: undefined
}
const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<
  AppStackParamList,
  T
>

const Stack = createNativeStackNavigator<AppStackParamList>()

const AppStack = function AppStack() {
  const authUser = useAppSelector((state) => state.auth)

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={authUser.isAuth === null ? "LoadingSplash" : "Home"}
    >
      {authUser.isAuth === null ? (
        <Stack.Screen name="LoadingSplash" component={LoadingSplash} />
      ) : authUser.isAuth ? (
        <>
          <Stack.Screen name="TabNavigator" component={DrawerNavigator} />
          <Stack.Screen name="EditProfile" component={EditProfile} />
          <Stack.Screen name="Player" component={PlayerScreen} initialParams={{ videoId: 0 }} />
          <Stack.Screen
            name="Document"
            component={DocumentScreen}
            initialParams={{ documentId: 0 }}
          />
          <Stack.Screen name="Serie" component={SerieScreen} initialParams={{ serieId: 0 }} />
          <Stack.Screen name="Nosotros" component={NosotrosScreen} />
          <Stack.Screen name="FAQ" component={FAQScreen} />
        </>
      ) : (
        <>
          <Stack.Screen name="TabNavigator" component={DrawerNavigator} />
          <Stack.Screen name="Player" component={PlayerScreen} initialParams={{ videoId: 0 }} />
          <Stack.Screen
            name="Document"
            component={DocumentScreen}
            initialParams={{ documentId: 0 }}
          />
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Register" component={RegisterScreen} />
          <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
          <Stack.Screen name="SendEmailSuccess" component={SendEmailSuccess} />
          <Stack.Screen name="Serie" component={SerieScreen} initialParams={{ serieId: 0 }} />
          <Stack.Screen name="Nosotros" component={NosotrosScreen} />
          <Stack.Screen name="FAQ" component={FAQScreen} />
        </>
      )}
    </Stack.Navigator>
  )
}

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = function AppNavigator(props: NavigationProps) {
  // TODO: activar cuando tengamos todas las pantallas para modo oscuro
  // const colorScheme = useColorScheme()
  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

  return (
    <NavigationContainer ref={navigationRef} theme={DefaultTheme} {...props}>
      <AppStack />
    </NavigationContainer>
  )
}
