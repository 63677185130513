import { useState, useRef } from "react"
import { useIsMounted } from "@app/utils/isMounted"
import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { Video } from "@app/interfaces/video.interface"
import { Presenter } from "@app/interfaces/presenter.interface"
import { Category } from "@app/interfaces/category.interface"
import { fetchVideoById, fetchSearchVideos } from "@app/services/api/videos"
import { fetchVideoProgress } from "@app/services/api/video-progress"
import { StatusBar, Linking, Alert } from "react-native"
import { addNewFavorite, deleteFavorite } from "@app/store/actions/favorites"
import axiosInt from "@app/services/axios"
import { addOrUpdateHistoryAction } from "@app/store/actions/history"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"

interface JWPlayerVideoResponseType {
  description?: string
  feet_instance_id?: string
  kind?: string
  playlist?: Array<PlaylistType>
  title?: "string"
}

interface PlaylistType {
  description?: string
  duration?: number
  image?: string
  images?: Array<ImagesType>
  link?: string
  mediaid?: string
  pubdate?: number
  sources?: Array<SourcesType>
  title?: string
  tracks?: Array<TracksType>
  variations?: unknown
}

interface SourcesType {
  file?: string
  filesize?: number
  framerate?: number
  height?: number
  label?: string
  type?: string
  width?: number
}

interface TracksType {
  file?: string
  kind?: string
  label?: string
}

interface ImagesType {
  src?: string
  type?: string
  width?: number
}

const usePlayer = () => {
  const isMounted = useIsMounted()
  const history = useAppSelector((state) => state.history)
  const { hideModal, showModal } = useModal()
  const playerRef = useRef<any>(null)
  const dispatch = useAppDispatch()
  const { id } = useAppSelector((state) => state.auth.user)
  const authUser = useAppSelector((state) => state.auth)
  const favorites = useAppSelector((state) => state.favorites)
  const [video, setVideo] = useState<Video>(null)
  const [videosRelated, setVideosRelated] = useState<Video[]>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [JWPlayer, setJWPlayer] = useState(null)
  const [refPlayerWeb, setRefPlayerWeb] = useState(null)
  const [favStates, setFavStates] = useState({})
  const [isPlaying, setIsPlaying] = useState(false)
  const [docPublished, setDocPublished] = useState(null)
  const handleFavState = (id: number, type: string) => {
    setFavStates((prev) => {
      const state = prev[type] ? prev[type][id] : false
      return { ...prev, [type]: { [id]: !state } }
    })
  }

  const initializePlayer = async (videoId: number) => {
    if (isMounted()) {
      try {
        setIsLoading(true)
        const responseVideo = await fetchVideoById(videoId)
        const categoriesIds = responseVideo.data.data.categories.map((category) => category.id)
        const responseVideosRelated = await fetchSearchVideos(null, null, categoriesIds, 6)
        setVideosRelated(responseVideosRelated.data.data.filter(videoActual => videoActual.id !== videoId))
        const fetchVideo = responseVideo.data.data
        setVideo(fetchVideo)
        let videoProgress = {
          progress: 0,
        }
        setDocPublished(fetchVideo.documents.filter(x => x.status === 'published'))
        if (!authUser.isAnonymous) {
          const responseVideoProgress = await fetchVideoProgress(id, fetchVideo.id)
          videoProgress = responseVideoProgress.data.data
        }

        const responseJWPlayer = await axiosInt.get<JWPlayerVideoResponseType>(
          `https://cdn.jwplayer.com/v2/media/${fetchVideo.player_id}`,
        )

        if (responseJWPlayer.data.playlist[0]) {
          const JWPlayerVideosObject = formatJWPlayerResponse(
            responseJWPlayer.data.playlist[0],
            videoProgress?.progress || 0,
          )
          setJWPlayer(JWPlayerVideosObject)
        } else {
          setJWPlayer({
            autostart: false,
            startTime: 0,
            sources: [],
            tracks: [],
          })
        }
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setJWPlayer({
          autostart: false,
          startTime: 0,
          sources: [],
          tracks: [],
        })
        setIsLoading(false)
      }
    }
  }

  const formatJWPlayerResponse = (res: PlaylistType, videoProgress: number) => {
    let startTime = videoProgress

    if (videoProgress >= res.duration) {
      startTime = 0
    }

    let response = {
      autostart: false,
      startTime,
      starttime: startTime,
      sources: [],
      tracks: [],
      image: res.image,
    }

    if (res.sources) {
      res.sources.forEach((source) => {
        if (source.file && source.label && source.type) {
          if (source.type.includes("video")) {
            response = {
              ...response,
              sources: [
                ...response.sources,
                {
                  file: source.file,
                  label: source.label,
                  default: source.label === "720p",
                },
              ],
            }
          }
        }
      })
    }

    if (res.tracks) {
      res.tracks.forEach((track) => {
        if (track.file && track.label && track.kind) {
          if (track.kind === "captions") {
            response = {
              ...response,
              tracks: [
                ...response.tracks,
                { file: track.file, kind: track.kind, label: track.label },
              ],
            }
          }
        }
      })
    }

    const sorted = response.sources.sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }
      if (b.label < a.label) {
        return -1
      }
      return 0
    })

    response = {
      ...response,
      sources: sorted,
    }

    return response
  }

  const saveOrUpdateVideoToHistory = (entityId: number, type: string) => {
    dispatch(addOrUpdateHistoryAction(entityId, type))
  }

  const isInHistory = (entityId: number, type: string) => {
    const found = history.userHistory.find((history) => {
      switch (type) {
        case "video":
          return history.video?.id === entityId
        case "document":
          return history.document?.id === entityId
        case "serie":
          return history.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const isInFavorites = (entityId: number, type: string) => {
    const found = favorites.favorites.find((favorite) => {
      switch (type) {
        case "video":
          return favorite.video?.id === entityId
        case "document":
          return favorite.document?.id === entityId
        case "serie":
          return favorite.serie?.id === entityId
        default:
          return false
      }
    })

    if (found) return true
    return false
  }

  const addFavorite = (id: number, type: string) => {
    dispatch(addNewFavorite(id, type, () => handleFavState(id, type)))
  }

  const removeFavorite = (id: number, type: string) => {
    dispatch(deleteFavorite(id, type, () => handleFavState(id, type)))
  }

  const getPresentersNames = (presenters: Array<Presenter>) => {
    let names = ""
    presenters.forEach((presenter, index) => {
      if (index !== presenters.length - 1) {
        names = names + `${presenter.first_name} ${presenter.last_name}, `
      } else {
        names = names + `${presenter.first_name} ${presenter.last_name}`
      }
    })
    return names || "-"
  }

  const getCategoriesNames = (categories: Array<Category>) => {
    let names = ""
    categories.forEach((category, index) => {
      if (index !== categories.length - 1) {
        names = names + category.name + ", "
      } else {
        names = names + category.name
      }
    })
    return names || "-"
  }

  const playerMountedCallback = async ({ player }) => {
    setRefPlayerWeb(player)
  }

  // DOC: las funciones "on" necesitan ser async para le componente
  const onTime = async () => {
    //
  }

  const onFullScreen = async () => {
    StatusBar.setHidden(true)
  }

  const onFullScreenExit = async () => {
    StatusBar.setHidden(false)
  }

  const onPlay = async () => {
    setIsPlaying(true)
  }

  const onPause = async () => {
    //
  }

  const onDownloadDocument = async (url: string) => {
    const supported = await Linking.canOpenURL(url)
    if (supported) {
      await Linking.openURL(url)
    } else {
      Alert.alert(`URL invalida: ${url}`)
    }
  }

  return {
    playerRef,
    initializePlayer,
    video,
    userId: id,
    getCategoriesNames,
    getPresentersNames,
    onTime,
    onFullScreen,
    onFullScreenExit,
    onPlay,
    onDownloadDocument,
    isLoading,
    addFavorite,
    removeFavorite,
    isInFavorites,
    JWPlayer,
    saveOrUpdateVideoToHistory,
    onPause,
    authUser,
    showModal,
    hideModal,
    playerMountedCallback,
    refPlayerWeb,
    favStates,
    isInHistory,
    videosRelated,
    isPlaying,
    docPublished
  }
}

export default usePlayer
