import React from "react"
import ForgotPassword from "@app/screens/Auth/ForgotPassword"
import { LoadingSplash } from "@app/screens/LoadingSplash"
import { LoginScreen } from "@app/screens/Auth/Login"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { HomeScreen } from "@app/screens/Home"
import { PlayerScreen } from "@app/screens/Player"
import RegisterScreen from "@app/screens/Auth/Register"
import { Activity } from "@app/screens/Activity"
import SendEmailSuccess from "@app/screens/Auth/SendEmailSuccess"
import { EditProfile } from "@app/screens/Profile/EditProfile"
import { SearchScreen } from "@app/screens/Search"
import { ExplorerScreen } from "@app/screens/Explorer/index.web"
import { Profile } from "@app/screens/Profile"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import ResponsiveAppBar from "../components/ResponsiveAppBar"
import { DocumentScreen } from "@app/screens/Document"
import { SerieScreen } from "@app/screens/Series"
import { NosotrosScreen } from "@app/screens/Nosotros"
import { FAQScreen } from "@app/screens/FAQ"
import FeedBackScreen from "@app/screens/Feedback/index.web"

export const WebNavigator = function WebNavigator() {
  const location = useLocation()

  const isAppBarVisible = (pathname: string) => {
    if (
      pathname === "/Login" ||
      pathname === "/Register" ||
      pathname === "/SendEmailSuccess" ||
      pathname === "/ForgotPassword"
    ) {
      return false
    }
    return true
  }

  const authUser = useAppSelector((state) => state.auth)
  return authUser.isAuth === null ? (
    <Routes>
      <Route path="/" element={<LoadingSplash navigation={undefined} route={undefined} />} />
    </Routes>
  ) : authUser.isAuth ? (
    <>
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Navigate to="/Home" replace />} />
        <Route path="/Home" element={<HomeScreen navigation={undefined} route={undefined} />} />
        <Route path="/Profile" element={<Profile navigation={undefined} route={undefined} />} />
        <Route
          path="/EditProfile"
          element={<EditProfile navigation={undefined} route={undefined} />}
        />
        <Route path="/Player" element={<PlayerScreen navigation={undefined} route={undefined} />} />
        <Route path="/Serie" element={<SerieScreen navigation={undefined} route={undefined} />} />
        <Route
          path="/Document"
          element={<DocumentScreen navigation={undefined} route={undefined} />}
        />
        <Route path="/Activity" element={<Activity navigation={undefined} route={undefined} />} />
        <Route path="/Search" element={<SearchScreen navigation={undefined} route={undefined} />} />
        <Route
          path="/Explorer"
          element={<ExplorerScreen navigation={undefined} route={undefined} />}
        />
        <Route
          path="/Nosotros"
          element={<NosotrosScreen navigation={undefined} route={undefined} />}
        />
        <Route path="/FAQ" element={<FAQScreen navigation={undefined} route={undefined} />} />
        <Route path="/Contacto" element={<FeedBackScreen navigation={undefined} route={undefined} />} />
      </Routes>
    </>
  ) : (
    <>
      {location && isAppBarVisible(location.pathname) && <ResponsiveAppBar />}
      <Routes>
        <Route path="/" element={<Navigate to="/Home" replace />} />
        <Route path="/Home" element={<HomeScreen navigation={undefined} route={undefined} />} />
        <Route path="/Login" element={<LoginScreen navigation={undefined} route={undefined} />} />
        <Route path="/Profile" element={<Profile navigation={undefined} route={undefined} />} />
        <Route path="/Player" element={<PlayerScreen navigation={undefined} route={undefined} />} />
        <Route path="/Serie" element={<SerieScreen navigation={undefined} route={undefined} />} />
        <Route
          path="/Document"
          element={<DocumentScreen navigation={undefined} route={undefined} />}
        />
        <Route path="/Search" element={<SearchScreen navigation={undefined} route={undefined} />} />
        <Route
          path="/Explorer"
          element={<ExplorerScreen navigation={undefined} route={undefined} />}
        />
        <Route path="/ForgotPassword" element={<ForgotPassword navigation={undefined} />} />
        <Route
          path="/Register"
          element={<RegisterScreen navigation={undefined} route={undefined} />}
        />
        <Route
          path="/SendEmailSuccess"
          element={<SendEmailSuccess navigation={undefined} route={undefined} />}
        />
        <Route
          path="/Nosotros"
          element={<NosotrosScreen navigation={undefined} route={undefined} />}
        />
        <Route path="/FAQ" element={<FAQScreen navigation={undefined} route={undefined} />} />
        <Route path="/Contacto" element={<FeedBackScreen navigation={undefined} route={undefined} />} />
      </Routes>
    </>
  )
}
