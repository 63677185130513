/**
 * These are configuration settings for the production environment.
 *
 * Do not include API secrets in this file or anywhere in your JS.
 *
 * https://reactnative.dev/docs/security#storing-sensitive-info
 */
export default {
  API_URL: "https://us-central1-faro-prod-60c5a.cloudfunctions.net/api",
  IOS_API_KEY: "251/6lNPI0OZnHft0b9H6q/OCkST9r0I4YsdoshAFuJpBEgj",
  ANDROID_API_KEY: "JMw2dR382Ene/rrBdYcg5etnF/WpOjdCJHLcArItFctlvgrc",
}
