import React, { FC, useEffect } from "react"
import {
  View,
  ViewStyle,
  ImageBackground,
  ImageStyle,
  TextStyle,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import { Button, Text, Screen, Icon, TextField } from "@app/components"
import { AppStackScreenProps } from "@app/navigators/AppNavigator"
import { useNavigation } from "@app/navigators/utils"
import { typography, colors, spacing } from "@app/theme"
import useProfile from "../hooks/use-profile"
import { useIsMounted } from "@app/utils/isMounted"
import { isWeb } from "@app/utils/isWeb"

const backgroundImage = require("../../../../assets/images/backgroundBlue.png")

export const EditProfile: FC<AppStackScreenProps<"EditProfile">> = () => {
  const { goBack } = useNavigation()
  const isMounted = useIsMounted()
  const { user, avatar, form, onPressCamera, setAvatar, image, setImage, isLoading } = useProfile({
    goBack,
  })

  useEffect(() => {
    if (isMounted()) {
      if (user.avatar_url) {
        setAvatar({ uri: user.avatar_url })
      }
    }
  }, [])

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={[]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.MAIN_DARK_BLUE}
    >
      <ImageBackground source={backgroundImage} style={$imgBackground} resizeMode="stretch">
        <View style={$headerContainer}>
          <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
            <Icon icon="arrowBack" size={37} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            <Text text="Editar perfil" style={$textHeader} />
          </View>
        </View>
        <View style={$background}>
          <View style={$backgroundCircleAvatar}>
            <ImageBackground
              source={avatar}
              style={$profileImage}
              resizeMode="cover"
              imageStyle={$avatarImage}
            >
              {image ? (
                <TouchableOpacity
                  onPress={() => {
                    setImage(null)
                    setAvatar({ uri: user.avatar_url })
                  }}
                >
                  <View style={$cameraIconContainer}>
                    <Icon icon="x" size={24} color={colors.MAIN_DARK_BLUE} />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={() => onPressCamera()}>
                  {isWeb() && (
                    <input
                      id="file-upload"
                      type="file"
                      style={$inputStyle}
                      accept="image/png, image/jpeg"
                    />
                  )}
                  <View style={$cameraIconContainer}>
                    <Icon icon="camera" size={24} color={colors.MAIN_DARK_BLUE} />
                  </View>
                </TouchableOpacity>
              )}
            </ImageBackground>
          </View>
        </View>
        <View style={$formContainer}>
          <View style={$inputFormContainer}>
            <View style={$inputLabelContainer}>
              <Text text="Nombre" style={$inputLabelText} />
            </View>
            <TextField
              onChangeText={form.handleChange("name")}
              onBlur={form.handleBlur("name")}
              value={form.values.name}
              autoCapitalize="none"
              autoCorrect={false}
              helper={form.touched.name && form.errors.name}
              status={form.touched.name && form.errors.name ? "error" : null}
              placeholder="Nombre"
              style={$placeholderText}
              inputWrapperStyle={$inputWrapper}
            />
          </View>
          <View style={$inputFormContainer}>
            <View style={$inputLabelContainer}>
              <Text text="Apellido" style={$inputLabelText} />
            </View>
            <TextField
              onChangeText={form.handleChange("last_name")}
              onBlur={form.handleBlur("last_name")}
              value={form.values.last_name}
              autoCapitalize="none"
              autoCorrect={false}
              helper={form.touched.last_name && form.errors.last_name}
              status={form.touched.last_name && form.errors.last_name ? "error" : null}
              placeholder="Apellido"
              style={$placeholderText}
              inputWrapperStyle={$inputWrapper}
            />
          </View>
        </View>
        <View style={$btnContent}>
          {isLoading ? (
            <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
          ) : (
            <>
              <View style={$editBtnContainer}>
                <Button
                  onPress={form.handleSubmit}
                  text="Guardar"
                  preset="filled"
                  style={$editBtn}
                  pressedStyle={$editBtnPressed}
                  textStyle={$editBtnText}
                  pressedTextStyle={$editBtnTextPressed}
                />
              </View>
              <Button
                onPress={() => goBack()}
                text="Cancelar"
                preset="filled"
                style={$logOutBtn}
                pressedStyle={$logOutBtnPressed}
                textStyle={$logOutBtnText}
                pressedTextStyle={$logOutBtnTextPressed}
              />
            </>
          )}
        </View>
      </ImageBackground>
    </Screen>
  )
}

const $inputStyle = {
  display: "none",
}

const $loader: ViewStyle = {
  marginVertical: 25,
}

const $avatarImage: ImageStyle = { borderRadius: 100 }

const $inputFormContainer: ViewStyle = { marginBottom: 24 }

const $screenContentContainer = {
  flexGrow: 1,
  backgroundColor: colors.MAIN_DARK_BLUE,
  backgroundImage: `url(${backgroundImage})`,
}

const $inputLabelContainer: ViewStyle = {
  marginBottom: 10,
}

const $inputLabelText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  color: colors.WHITE,
  fontSize: 15,
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $cameraIconContainer: ViewStyle = {
  width: 32,
  height: 32,
  backgroundColor: colors.MAIN_AQUAMARINE,
  borderRadius: 100,
  alignSelf: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  top: 5,
}

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $headerContainer: ViewStyle = {
  height: 140,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
}

const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.LIGHT_GRAY,
  minHeight: 10,
  backgroundColor: colors.transparent,
}
const $placeholderText: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  color: colors.WHITE,
  fontSize: 15,
}

const $editBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $editBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $editBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $editBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $logOutBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $logOutBtnPressed: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $logOutBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $logOutBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $profileImage: ImageStyle = {
  width: 109,
  height: 109,
  borderRadius: 100,
  justifyContent: "flex-end",
}

const $editBtnContainer: ViewStyle = { marginBottom: 27 }

const $backgroundCircleAvatar: ViewStyle = {
  width: 145,
  height: 145,
  backgroundColor: colors.MAIN_DARK_BLUE,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
}

const $background: ViewStyle = {
  height: 203,
  flex: 0.1,
  alignItems: "center",
}

const $formContainer: ViewStyle = {
  flex: 0.6,
  paddingVertical: 10,
  paddingHorizontal: 24,
}

const $imgBackground: ImageStyle = {
  flexGrow: 1,
  width: "auto",
  height: "auto",
  paddingBottom: spacing.large,
}

const $btnContent: ViewStyle = {
  paddingHorizontal: 30,
  flex: 0.3,
  justifyContent: "flex-end",
  marginBottom: 30,
}
