/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { CSSProperties, Fragment } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "./css/carouselSlider.css"
import { colors, spacing, typography } from "@app/theme"
import {
  ViewStyle,
  View,
  ImageBackground,
  TouchableOpacity,
  ImageStyle,
  TextStyle,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from "react-native"
import { Slider } from "@app/interfaces/slider.interface"
import { Grid } from "@mui/material"
import { Icon } from "@app/components"
import useHome from "../hooks/use-home"
import { Text } from "@app/components"
import { SliderContent } from "./SliderContent"
import { Document } from "@app/interfaces/document.interface"
import { ModalAnonymous } from "@app/components/ModalAnonymous"

const BACKGROUND_HOME = require("../../../../assets/images/web/background-home.png")

export const CarouselSliders = ({ sliders, navigateTo }) => {
  const {
    auth,
    history,
    favorites,
    showModal,
    hideModal,
    favStates,
    addFavorite,
    removeFavorite,
    isInFavorites,
  } = useHome()

  const handleNavigate = (id: number, type: string) => {
    switch (type) {
      case "video":
        navigateTo("Player", { videoId: id })
        break
      case "document":
        navigateTo("Document", { documentId: id })
        break
      case "serie":
        navigateTo("Serie", { serieId: id })
        break
      default:
        break
    }
  }

  const $screenContentContainerWeb: CSSProperties = {
    flex: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    paddingBottom: spacing.large,
    backgroundImage: `url(${BACKGROUND_HOME})`,
    backgroundSize: sliders.length >= 5 ? "contain" : "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "initial",
    backgroundPositionX: "center",
    width: "100%",
  }

  return (
    <Grid container sx={$screenContentContainerWeb}>
      {!auth.isAnonymous && (
        <Fragment>
          <Grid style={$container} container justifyContent="center">
            <Grid item xs={12}>
              <div style={$titleSliderPadding}>
                <p style={$sliderTitle} className="break-word">
                  Favoritos
                </p>
              </div>
              {favorites.favorites.length > 0 ? (
                <Swiper
                  style={$swiper}
                  navigation={true}
                  modules={[Navigation]}
                  slidesPerView="auto"
                  spaceBetween={10}
                >
                  {favorites.favorites.length > 0 &&
                    favorites.favorites.map((favorite, index) => {
                      let type = null
                      let entity = null

                      if (favorite.video) {
                        type = "video"
                        entity = favorite.video
                      } else if (favorite.document) {
                        type = "document"
                        entity = favorite.document
                      } else if (favorite.serie) {
                        type = "serie"
                        entity = favorite.serie
                      }

                      return (
                        <SwiperSlide
                          key={index}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleNavigate(entity.id, type)}
                        >
                          <SliderContent entity={entity} navigateTo={navigateTo} type={type} />
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
              ) : (
                <p style={$emptySliderText}>Aun no hay videos en favoritos</p>
              )}
            </Grid>
          </Grid>

          <Grid style={$container} container justifyContent="center">
            <Grid item xs={12}>
              <div style={$titleSliderPadding}>
                <p style={$sliderTitle} className="break-word">
                  Historial
                </p>
              </div>
              {history.userHistory.length > 0 ? (
                <Swiper
                  style={$swiper}
                  navigation={true}
                  modules={[Navigation]}
                  slidesPerView="auto"
                  spaceBetween={10}
                >
                  {history.userHistory.length > 0 &&
                    history.userHistory.map((userHistory, index) => {
                      let type = null
                      let entity = null

                      if (userHistory.video) {
                        type = "video"
                        entity = userHistory.video
                      } else if (userHistory.document) {
                        type = "document"
                        entity = userHistory.document
                      } else if (userHistory.serie) {
                        type = "serie"
                        entity = userHistory.serie
                      }

                      return (
                        <SwiperSlide
                          key={index}
                          // eslint-disable-next-line react-native/no-inline-styles
                          style={{
                            backgroundColor: colors.transparent,
                          }}
                          onClick={() => {
                            handleNavigate(entity.id, type)
                          }}
                        >
                          <SliderContent entity={entity} navigateTo={navigateTo} type={type} />
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
              ) : (
                <p style={$emptySliderText}>Aun no hay videos en el historial</p>
              )}
            </Grid>
          </Grid>
        </Fragment>
      )}

      {sliders.length > 0 &&
        sliders.map((slider: Slider, index) => {
          const sliderType = slider.type
          let entities = []
          if (sliderType === "video") {
            entities = [...slider.videos, ...slider.series]
          } else {
            entities = [...slider.documents]
          }

          if (sliderType === "video") {
            return (
              <Grid key={index} style={$container} container justifyContent="center">
                <Grid item xs={12}>
                  <div style={$titleSliderPadding}>
                    <p style={$sliderTitle} className="break-word">
                      {slider.name}
                    </p>
                  </div>
                  <Swiper
                    style={$swiper}
                    navigation={true}
                    modules={[Navigation]}
                    slidesPerView="auto"
                    spaceBetween={10}
                  >
                    {entities.length > 0 &&
                      entities.map((entity, index) => {
                        let type = null
                        if (entity.player_id) {
                          type = "video"
                        } else {
                          type = "serie"
                        }

                        return (
                          <SwiperSlide
                            key={index}
                            style={{
                              backgroundColor: colors.transparent,
                            }}
                            onClick={() => {
                              handleNavigate(entity.id, type)
                            }}
                          >
                            <SliderContent entity={entity} navigateTo={navigateTo} type={type} />
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </Grid>
              </Grid>
            )
          }

          if (sliderType === "document") {
            return (
              <Grid key={index} style={$container} container justifyContent="center">
                <Grid item xs={12} className="document-slider">
                  <div style={$titleSliderPadding}>
                    <p style={$sliderTitle} className="break-word">
                      {slider.name}
                    </p>
                  </div>
                  <Swiper
                    style={$swiper}
                    navigation={true}
                    modules={[Navigation]}
                    slidesPerView="auto"
                    spaceBetween={10}
                  >
                    {entities.length > 0 &&
                      entities.map((doc: Document, index) => {
                        const type = "document"
                        return (
                          <SwiperSlide
                            key={index}
                            style={{ backgroundColor: "transparent" }}
                            onClick={() => {
                              handleNavigate(doc.id, type)
                            }}
                          >
                            <Grid
                              container
                              style={{
                                minHeight: 200,
                                backgroundColor: colors.WHITE,
                                backgroundAttachment: "local",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: 10,
                                padding: 15,
                                maxWidth: 470,
                              }}
                            >
                              <Grid item xs={5}>
                                <View style={$documentThumbnailContainer}>
                                  <ImageBackground
                                    source={{ uri: doc.thumbnail }}
                                    resizeMode="cover"
                                    style={$documentThumbnail}
                                    imageStyle={$documentThumbnailImg}
                                  >
                                    {/* TODO: Agregar funcionalidad de favoritos de documentos */}
                                    {auth.isAnonymous ? (
                                      <TouchableOpacity
                                        onPress={() =>
                                          showModal(
                                            <ModalAnonymous
                                              hideModal={hideModal}
                                              navigateTo={navigateTo}
                                            />,
                                            null,
                                            600,
                                          )
                                        }
                                      >
                                        <div style={$favoriteDocContainer}>
                                          <Icon
                                            icon="heartOutlined"
                                            size={23}
                                            color={colors.WHITE}
                                          />
                                        </div>
                                      </TouchableOpacity>
                                    ) : favStates[type] && favStates[type][doc.id] ? (
                                      <TouchableWithoutFeedback>
                                        <div style={$favoriteDocContainer}>
                                          <ActivityIndicator
                                            size={16}
                                            color={colors.MAIN_AQUAMARINE}
                                          />
                                        </div>
                                      </TouchableWithoutFeedback>
                                    ) : isInFavorites(doc.id, type) ? (
                                      <TouchableOpacity
                                        onPress={() => removeFavorite(doc.id, type)}
                                      >
                                        <div style={$favoriteDocContainer}>
                                          <Icon
                                            icon="heartWeb"
                                            size={20}
                                            color={colors.MAIN_AQUAMARINE}
                                          />
                                        </div>
                                      </TouchableOpacity>
                                    ) : (
                                      <TouchableOpacity onPress={() => addFavorite(doc.id, type)}>
                                        <div style={$favoriteDocContainer}>
                                          <Icon
                                            icon="heartOutlined"
                                            size={23}
                                            color={colors.WHITE}
                                          />
                                        </div>
                                      </TouchableOpacity>
                                    )}
                                  </ImageBackground>
                                </View>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={7}
                                justifyContent="flex-start"
                                style={{ padding: 10 }}
                              >
                                <Grid item xs={12}>
                                  <Text text={doc.title} style={$documentTitle} numberOfLines={2} />
                                </Grid>
                                <Grid item xs={12} style={$categoriesContainer}>
                                  {doc.categories &&
                                    doc.categories.length > 0 &&
                                    doc.categories.map((category, index) => (
                                      <View key={index} style={$categoryContainer}>
                                        <View style={$categoryNameContainer}>
                                          <Text text={category.name} style={$categoryNameText} />
                                        </View>
                                      </View>
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                  <Text
                                    text={doc.description}
                                    style={$documentDescription}
                                    numberOfLines={2}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </Grid>
              </Grid>
            )
          }

          return null
        })}
    </Grid>
  )
}

// New Styles
const $titleSliderPadding: CSSProperties = { paddingLeft: 25 }

const $categoryNameText: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: 14,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.GRAY,
}

const $categoryNameContainer: ViewStyle = {
  borderWidth: 1,
  justifyContent: "center",
  alignContent: "center",
  borderColor: colors.LIGHT_GRAY,
  borderRadius: 3,
  paddingHorizontal: 2,
  paddingVertical: 1,
}

const $categoriesContainer: ViewStyle = { display: "flex", flexDirection: "row", flexWrap: "wrap" }

const $categoryContainer: ViewStyle = { paddingHorizontal: 2, paddingBottom: 3 }

const $documentTitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 18,
  lineHeight: 20,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  color: colors.MAIN_DARK_BLUE,
  textAlign: "left",
}

const $documentDescription: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 14,
  lineHeight: 26,
  fontWeight: "300",
  fontStyle: "normal",
  letterSpacing: 0,
  color: colors.MAIN_DARK_BLUE,
  textAlign: "left",
}

const $documentThumbnailContainer: ViewStyle = {
  borderRadius: 20,
  height: 170,
  width: "100%",
  maxWidth: 140,
  backgroundColor: colors.WHITE,
}

const $documentThumbnail: ViewStyle = {
  width: "100%",
  height: 170,
  borderRadius: 20,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  paddingBottom: 13,
  paddingRight: 13,
}

const $favoriteDocContainer: CSSProperties = {
  height: 35,
  width: 35,
  borderRadius: 100,
  backgroundColor: "rgba(255, 255, 255, 0.19)",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  display: "flex",
  backdropFilter: "blur(5px)",
}

const $documentThumbnailImg: ImageStyle = {
  borderRadius: 20,
}

// Old styles

const $emptySliderText: CSSProperties = {
  marginTop: 60,
  marginBottom: 60,
  textAlign: "center",
  fontFamily: typography.fonts.montserrat.light,
  fontSize: 18,
  fontWeight: "300",
  fontStyle: "normal",
  letterSpacing: 0,
  color: colors.WHITE,
}

const $swiper: CSSProperties = {
  backgroundColor: "transparent",
  paddingRight: "48px",
  paddingLeft: "48px",
  width: "calc(100% - 96px)",
}

const $container: CSSProperties = { width: "100%", overflow: "hidden" }

const $sliderTitle: CSSProperties = {
  fontFamily: "Montserrat",
  fontSize: 25,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}
