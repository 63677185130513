import React, { FC, useEffect } from "react"
import { View, Modal, ViewStyle, TextStyle, Image, ImageStyle, BackHandler } from "react-native"
import { Button, Text } from "@app/components"
import { colors, spacing, typography } from "@app/theme"
import useUpdateModal from "./hooks/use-update"
import { isWeb } from "@app/utils/isWeb"

export const UpdateModalScreen: FC = (_props) => {
  const {
    initializeFirestore,
    updateBtn,
    maintenanceText,
    openModal,
    content,
    updateIcon,
    maintenanceIcon,
  } = useUpdateModal()

  useEffect(() => {
    initializeFirestore()
  }, [])

  const $modalView: ViewStyle = {
    alignItems: "center",
    width: "90%",
    backgroundColor: colors.background,
    borderRadius: 10,
    elevation: 4,
    margin: 20,
    padding: 24,
    shadowColor: colors.palette.neutral900,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    ...(isWeb() && { maxWidth: 580 }),
  }

  const UpdateAppContent: FC = (_props) => {
    return (
      <View>
        <View style={$spacingBottom}>
          <Text tx="updateScreen.updateTitle" style={$titleText} />
        </View>
        <View style={$imageUpdateContainer}>
          <Image source={updateIcon} style={$imageUpdateStyle} resizeMode="contain" />
        </View>
        <View style={$updateText1Container}>
          <Text tx="updateScreen.updateText1" style={$updateText1} />
        </View>
        <View style={$updateText2Container}>
          <Text tx="updateScreen.updateText2" style={$updateText2} />
        </View>
        <View>
          <Button
            tx="updateScreen.updateBtn"
            onPress={updateBtn}
            preset="reversed"
            style={$updateBtn}
            textStyle={$updateBtnText}
            pressedTextStyle={$updateBtnTextPressed}
            pressedStyle={$pressedUpdateBtn}
          />
        </View>
      </View>
    )
  }

  const MaintenanceAppContent: FC = (_props) => {
    return (
      <View>
        <View style={$spacingBottom}>
          <Text tx="updateScreen.maintenanceTitle" style={$titleText} />
        </View>
        <View style={$imageMaintenanceContainer}>
          <Image source={maintenanceIcon} style={$imageMaintenanceStyle} resizeMode="contain" />
        </View>
        <View style={$spacingBottom}>
          <Text text={maintenanceText} style={$textMaintenance} />
        </View>
        {!isWeb() && (
          <View>
            <Button
              tx="updateScreen.maintenanceBtn"
              onPress={() => BackHandler.exitApp()}
              preset="filled"
              style={$exitMaintenanceBtn}
              textStyle={$exitMaintenanceBtnText}
            />
          </View>
        )}
      </View>
    )
  }

  const $container: ViewStyle = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 999,
    opacity: 1,
    backgroundColor: colors.palette.overlay50,
    display: openModal ? "flex" : "none",
  }

  return (
    <View style={$container}>
      <Modal animationType="slide" transparent={true} visible={openModal}>
        <View style={$modalCenteredView}>
          <View style={$modalView}>
            {content === null ? null : content === "maintenance" ? (
              <MaintenanceAppContent />
            ) : (
              content === "forceUpdate" && <UpdateAppContent />
            )}
          </View>
        </View>
      </Modal>
    </View>
  )
}

const $updateText2Container: ViewStyle = { marginBottom: 35 }

const $updateText1Container: ViewStyle = { marginBottom: 15 }

const $updateText1: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 22,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $updateText2: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 22,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $imageUpdateContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 40,
}

const $imageUpdateStyle: ImageStyle = { height: 98, width: 98 }

const $imageMaintenanceContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 22,
}

const $imageMaintenanceStyle: ImageStyle = { height: 79, width: 79 }

const $exitMaintenanceBtn: ViewStyle = {
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $exitMaintenanceBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $textMaintenance: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 15,
  lineHeight: 19,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

const $titleText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 35,
  lineHeight: 43,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.SECONDARY_VIOLET,
}

const $spacingBottom: ViewStyle = { marginBottom: spacing.large }

const $updateBtn: ViewStyle = {
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $pressedUpdateBtn: ViewStyle = {
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $updateBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $updateBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $modalCenteredView: ViewStyle = {
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
}
