import React, { FC } from "react"
import { ViewStyle, View, TouchableOpacity, TextStyle, ImageBackground } from "react-native"
import { Screen, Text, Icon } from "@app/components"
import { spacing, typography, colors } from "@app/theme"
import { useNavigation } from "@app/navigators/utils"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { isWeb } from "@app/utils/isWeb"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { Footer } from "../Home/components/Footer"
import { AppStackScreenProps } from "@app/navigators"

const BACKGROUND = require("../../../assets/images/web/bg-player.png")

export const NosotrosScreen: FC<AppStackScreenProps<"Nosotros">> = ({ route }) => {
  const { goBack } = useNavigation(null, route)
  const { height } = useWindowDimensions()

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    minHeight: height - APP_BAR_HEIGHT,
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["top"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <ContainerResponsive>
        <View style={$headerContainer}>
          <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            {isWeb() ? (
              <Text text="Volver" style={$textHeader} />
            ) : (
              <Text text="Nosotros" style={$textHeader} />
            )}
          </View>
        </View>
      </ContainerResponsive>
      <ImageBackground
        style={$imageBgContainer}
        source={BACKGROUND}
        resizeMode={isWeb() ? "stretch" : "cover"}
      >
        <ContainerResponsive padding={10}>
          <View style={$container}>
            <View style={$titleContainer}>
              <Text style={$textTitle}>SOBRE NOSOTROS</Text>
            </View>
            <Text style={$textBody}>
              Libbre es una plataforma digital con contenido educativo, informativo, de divulgación
              y de entretenimiento adaptado a las distintas edades y niveles. {"\n"}
            </Text>
            <Text style={$textBody}>
              Libbre busca que las personas comprendan los valores constitutivos de una sociedad
              libre como los derechos individuales, la responsabilidad por los actos propios, el
              respeto a la diversidad de opiniones y a la libertad de expresarlas; los mecanismos de
              funcionamiento de las instituciones que rigen la vida comunitaria, la comprensión de
              los fenómenos sociales, políticos y educativos que permitan ir en la dirección de una
              sociedad libre. {"\n"}
            </Text>
            <Text style={$textBody}>
              Queremos ser una plataforma líder, que brinde herramientas discursivas e ideológicas a
              las personas que comulgan con las ideas de la libertad y pro occidentales, pero
              también buscamos seducir con una narrativa clara, calma, contenedora y simple a
              aquellos que no comulgan con estas ideas y mostrarles otra versión de la realidad y
              otra información disponible. {"\n"}
            </Text>
            <Text style={$textBody}>
              Nos preocupamos por nosotros y por otros. No solo a través del televisor, el celular,
              las redes sociales, también queremos alcanzar la mayor cantidad de escuelas,
              organizando charlas con docentes y padres para hacerles conocer el producto,
              brindándoles contenido gratuito.
            </Text>
          </View>
        </ContainerResponsive>
      </ImageBackground>
      {isWeb() && <Footer />}
    </Screen>
  )
}

const $container: ViewStyle = { paddingTop: 25, paddingBottom: 40 }

const $titleContainer: ViewStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  width: "100%",
  alignItems: "center",
  paddingVertical: 20,
}

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textTitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 20,
  lineHeight: 26,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $textBody: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 17.5,
  lineHeight: 28,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
  ...(isWeb() && { paddingBottom: 15 }),
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $imageBgContainer: ViewStyle = {
  paddingTop: isWeb() ? spacing.medium : 0,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}
