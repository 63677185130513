import React, { FC } from "react"
import {
  View,
  Image,
  ViewStyle,
  TextStyle,
  Dimensions,
  TouchableOpacity,
  ImageStyle,
} from "react-native"
import { Text, Icon } from "@app/components"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { typography, colors } from "@app/theme"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { DrawerHeaderProps } from "@react-navigation/drawer"
import { AVATAR_PLACEHOLDER } from "@app/utils/constants"

const { width } = Dimensions.get("window")

export const CustomHeader: FC<DrawerHeaderProps> = ({ navigation, route, options }) => {
  const user = useAppSelector((state) => state.auth.user)
  const authUser = useAppSelector((state) => state.auth)
  const insets = useSafeAreaInsets()
  const libbreLogo = require("../../../assets/images/LogotipoOne.png")
  const avatarAnonymous = require("../../../assets/images/profileImage.png")

  const $container: ViewStyle = {
    height: 60 + insets.top,
    width,
    paddingTop: insets.top,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.SHADES_OF_BLACK,
  }

  return (
    <View style={$container}>
      <View style={$menuContainer}>
        <TouchableOpacity onPress={() => navigation.openDrawer()}>
          <View style={$menuIconContainer}>
            <Icon icon="drawer" size={30} color={colors.WHITE} />
          </View>
        </TouchableOpacity>

        {route.name === "Home" ? (
          <Image source={libbreLogo} style={$logo} />
        ) : (
          <View style={$labelScreenContainer}>
            <Text style={$headerText}>{options.drawerLabel}</Text>
          </View>
        )}
      </View>
      {route.name === "Home" && (
        <TouchableOpacity onPress={() => navigation.navigate("Profile")}>
          <View style={$profileImageContainer}>
            <Image
              source={
                authUser.isAnonymous
                  ? avatarAnonymous
                  : { uri: user.avatar_url || AVATAR_PLACEHOLDER }
              }
              style={$profileImage}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  )
}

const $headerText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 26,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $menuContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}

const $menuIconContainer: ViewStyle = { paddingHorizontal: 20 }

const $logo: ImageStyle = {
  width: 87,
  height: 32.8,
  aspectRatio: 87 / 32,
  marginLeft: 5,
}

const $labelScreenContainer: ViewStyle = { marginLeft: 5 }

const $profileImageContainer: ViewStyle = {
  marginRight: 25,
}

const $profileImage: ImageStyle = {
  borderRadius: 100,
  width: 50,
  height: 50,
  shadowColor: colors.SHADES_OF_BLACK,
  shadowOffset: {
    width: 0,
    height: 35,
  },
  shadowRadius: 42,
  shadowOpacity: 3,
  marginBottom: "6.4%",
}
