import React, { FC, Fragment, useEffect } from "react"
import { ViewStyle, View, Dimensions, TextStyle, ImageBackground, ImageStyle } from "react-native"
import { Screen, Text } from "@app/components"
import { TabScreenProps } from "@app/navigators/DrawerNavigator"
import { colors, typography } from "@app/theme"
import { BackDrop } from "@app/components/BackDrop"
import useHome from "./hooks/use-home"
import { useNavigation } from "@app/navigators/utils"
import { CarouselBanner } from "./components/CarouselBanner"
import { isWeb } from "@app/utils/isWeb"
import { CarouselSliders } from "./components/CarouselSliders"
import { Footer } from "./components/Footer"

const { width } = Dimensions.get("window")
const BACKGROUND_HOME = require("../../../assets/images/background-home-mobile.png")

export const HomeScreen: FC<TabScreenProps<"Home">> = ({ navigation }) => {
  const { isLoading, sliders, banners, initializeHome, user, auth, isMounted } = useHome()

  const { navigateTo } = useNavigation(navigation)

  useEffect(() => {
    if (isMounted() && sliders.length === 0 && banners.length === 0) {
      initializeHome()
    }
  }, [])

  if (isLoading) {
    return (
      <Screen
        preset="scroll"
        safeAreaEdges={["bottom"]}
        backgroundColor={colors.SHADES_OF_BLACK}
        contentContainerStyle={!isWeb() ? $screenContentContainer : $screenContentContainerWeb}
      >
        <BackDrop />
      </Screen>
    )
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={[]}
      contentContainerStyle={!isWeb() ? $screenContentContainer : $screenContentContainerWeb}
    >
      {isWeb() && <CarouselBanner navigateTo={navigateTo} banners={banners} width={width} />}

      <ImageBackground
        source={BACKGROUND_HOME}
        resizeMode="cover"
        style={{ backgroundColor: colors.SHADES_OF_BLACK }}
        imageStyle={$imageStyleHeight}
      >
        {!isWeb() && (
          <Fragment>
            <View style={$greetingsTextContainer}>
              <Text tx="homeScreen.greetings" style={$greetingsText} />
              {auth.isAnonymous ? (
                <Text text="!" style={$greetingsText} />
              ) : (
                <Text style={$greetingsText}>{` ${user.name}!`}</Text>
              )}
            </View>
            <CarouselBanner navigateTo={navigateTo} banners={banners} width={width} />
          </Fragment>
        )}
        <CarouselSliders navigateTo={navigateTo} sliders={sliders} width={width} />
      </ImageBackground>
      {isWeb() && <Footer />}
    </Screen>
  )
}

const $imageStyleHeight: ImageStyle = { height: 800 }

const $screenContentContainer: ViewStyle = {
  flexGrow: 1,
  paddingBottom: 30,
}

// TODO: Ver el background, ponerlo como cover en cierto tamaño y cuando pase de ese ponerlo en contain

const $screenContentContainerWeb = {
  flex: 1,
  backgroundColor: colors.SHADES_OF_BLACK,
  width: "100%",
}

const $greetingsTextContainer: ViewStyle = {
  height: 70,
  alignItems: "center",
  flexDirection: "row",
  paddingHorizontal: 15,
}

const $greetingsText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 35,
  lineHeight: 40,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}
