import { IDispatch } from "@app/store/interface"
import { fetchSliders } from "@app/services/api/sliders"
import { fetchBanners } from "@app/services/api/banners"
import { setHomeDataReducer } from "@app/store/slices/home"

export const getHomeDataAction = () => async (dispatch: IDispatch) => {
  try {
    dispatch(
      setHomeDataReducer({
        banners: [],
        sliders: [],
        isLoading: true,
      }),
    )
    const responseSliders = await fetchSliders()
    const responseBanners = await fetchBanners()
    dispatch(
      setHomeDataReducer({
        banners: responseBanners.data.data,
        sliders: responseSliders.data.data,
        isLoading: false,
      }),
    )
  } catch (err) {
    dispatch(
      setHomeDataReducer({
        banners: [],
        sliders: [],
        isLoading: false,
      }),
    )
  }
}
