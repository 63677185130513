/* eslint-disable react-native/no-inline-styles */
import React, { FC, Fragment } from "react"
import {
  ViewStyle,
  View,
  TouchableOpacity,
  ImageBackground,
  TextStyle,
  ActivityIndicator,
} from "react-native"

import { Grid, Chip } from "@mui/material"
import { Screen, Text, Icon, Button as NativeButton } from "@app/components"
import { spacing, colors, typography } from "@app/theme"
import { AppStackScreenProps } from "@app/navigators"
import { APP_BAR_HEIGHT, THUMBNAIL_PLACEHOLDER } from "@app/utils/constants"
import { translate as t } from "@app/i18n"
import { useNavigation } from "@app/navigators/utils"
import { isWeb } from "@app/utils/isWeb"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import useExplorer from "./hooks/use-explorer"
import { Footer } from "../Home/components/Footer"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import { VideoSerieComponent } from "./components/VideoSerieComponent"

const backgroundImage = require("../../../assets/images/web/background-screens.png")

export const ExplorerScreen: FC<AppStackScreenProps<"Explorer">> = ({ navigation }) => {
  const { navigateTo, goBack } = useNavigation(navigation)
  const { height } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const [showAllCategories, setShowAllCategories] = React.useState(false)
  const [showAllPresenters, setShowAllPresenters] = React.useState(false)

  const {
    handleRemoveFilters,
    presenters,
    categories,
    selectedCategory,
    selectedPresenter,
    exploreResults,
    isLoading,
    searchResults,
    auth,
    isInFavorites,
    addFavorite,
    removeFavorite,
    favStates,
    hideModal,
    showModal,
  } = useExplorer()

  const $screenContentContainerWeb = {
    flexGrow: 1,
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${backgroundImage})`,
    minHeight: height - APP_BAR_HEIGHT,
    paddingBottom: spacing.large,
    paddingTop: insets.top,
    backgroundSize: "100% 100%",
    backgroundAttachment: "fixed",
    backgroundPosition: "center, center",
  }

  const handleShowAllCategories = () => {
    setShowAllCategories(true)
  }
  const handleShowAllPreseters = () => {
    setShowAllPresenters(true)
  }
  const visibleCategories = showAllCategories ? categories : categories.slice(0, 6)
  const visiblePresenters = showAllPresenters ? presenters : presenters.slice(0, 6)

  return (
    <>
      <Screen
        preset="scroll"
        safeAreaEdges={["bottom"]}
        contentContainerStyle={isWeb() ? $screenContentContainerWeb : $screenContentContainer}
      >
        <View style={$headerContainer}>
          <ContainerResponsive>
            <View style={$headerContainer}>
              <TouchableOpacity onPress={async () => goBack()} style={$backBtn}>
                <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
              </TouchableOpacity>
              <View style={$textHeaderContainer}>
                {isWeb() ? (
                  <Text
                    text="Volver"
                    style={{
                      fontFamily: typography.fonts.montserrat.bold,
                      fontSize: 16,
                      lineHeight: 21,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: colors.WHITE,
                    }}
                  />
                ) : (
                  <Text text={"Explorar"} style={$textHeader} />
                )}
              </View>
            </View>
          </ContainerResponsive>
        </View>

        <ContainerResponsive md={11} lg={11} xl={10}>
          <Grid container>
            <Grid item container xs={12} md={6} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <View style={{ paddingVertical: 40 }}>
                  <Text text="Explora nuestros videos" style={$firstSearchText1} />
                </View>
              </Grid>
              <View style={$categoriesContainer}>
                <View>
                  <Text text="Categorías" style={$categoriesText} />
                </View>
              </View>

              <Grid item container>
                {visibleCategories.map((category) => (
                  <Grid key={`category-${category.id}`} item>
                    <View style={{ paddingHorizontal: 5, paddingVertical: 5 }}>
                      <Chip
                        sx={{
                          fontFamily: typography.fonts.montserrat.normal,
                          fontSize: 15,
                          color:
                            selectedCategory === category.id ? colors.MAIN_DARK_BLUE : colors.WHITE,
                          backgroundColor:
                            selectedCategory === category.id ? colors.MAIN_AQUAMARINE : null,
                          "&:hover": {
                            color: colors.WHITE,
                          
                          },
                          borderRadius: 1,
                          borderWidth: 2,
                          borderColor: colors.WHITE,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "fit-content",
                        }}
                        label={category.name}
                        variant="outlined"
                        onClick={() => exploreResults(category.id, "category")}
                      />
                    </View>
                  </Grid>
                ))}

                <Grid container direction="column">
                  <Grid style={$showMoreContainer}>
                    {!showAllCategories && (
                      <TouchableOpacity onPress={handleShowAllCategories}>
                        <Text text="Ver más" style={$ShowMoreStyle} />
                      </TouchableOpacity>
                    )}
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <View style={$presentersContainer}>
                    <View>
                      <Text text="Presentadores" style={$presentersText} />
                    </View>
                  </View>

                  <Grid container>
                    {visiblePresenters.map((presenter) => (
                      <Grid key={`presenter-${presenter.id}`} item>
                        <View style={{ paddingHorizontal: 5, paddingVertical: 5 }}>
                          <Chip
                            sx={{
                              color:
                                selectedPresenter === presenter.id
                                  ? colors.MAIN_DARK_BLUE
                                  : colors.WHITE,
                              backgroundColor:
                                selectedPresenter === presenter.id ? colors.MAIN_AQUAMARINE : null,
                              fontFamily: typography.fonts.montserrat.light,
                              fontSize: 15,
                              width: "fit-content",
                              borderRadius: 1,
                              borderWidth: 2,
                              borderColor: colors.WHITE,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            label={`${presenter.first_name} ${presenter.last_name}`}
                            variant="outlined"
                            onClick={() => exploreResults(presenter.id, "presenter")}
                          />
                        </View>
                      </Grid>
                    ))}

                    <Grid container direction="column">
                      <Grid style={$showMoreContainer2}>
                        {!showAllPresenters && (
                          <View style={$showMoreButtonContainer}>
                            <TouchableOpacity onPress={handleShowAllPreseters}>
                              <Text text="Ver más" style={$ShowMoreStyle} />
                            </TouchableOpacity>
                          </View>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <View>
                <NativeButton
                  text={t("searchScreen.cleanText")}
                  onPress={handleRemoveFilters}
                  preset="reversed"
                  style={$removeFiltersBtn}
                  textStyle={$removeBtnText}
                  pressedTextStyle={$removeBtnTextPressed}
                />
              </View>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center" sx={{ paddingLeft: 3, paddingBottom: 15 }}>
                {isLoading ? (
                  <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
                ) : searchResults.documents.length <= 0 &&
                  searchResults.series.length <= 0 &&
                  searchResults.videos.length <= 0 ? (
                  <View style={$videosContainer}>
                    <View>
                      <Text
                        text={
                          (selectedCategory && selectedPresenter) ||
                          selectedCategory ||
                          selectedPresenter
                            ? "No se encontró ningún video para los filtros seleccionados"
                            : "Selecciona alguno de los filtros para iniciar la visualización"
                        }
                        style={$categoriesText}
                      />
                    </View>
                  </View>
                ) : (
                  <Fragment>
                    {searchResults.videos.length > 0 && (
                      <VideoSerieComponent
                        items={searchResults.videos}
                        navigateTo={navigateTo}
                        type="video"
                      />
                    )}

                    {searchResults.series.length > 0 && (
                      <VideoSerieComponent
                        items={searchResults.series}
                        navigateTo={navigateTo}
                        type="serie"
                      />
                    )}

                    {searchResults.documents.length > 0 && (
                      <Grid item xs={12}>
                        <Text text="Documentos" style={$categoriesText} />
                      </Grid>
                    )}

                    {searchResults.documents.length > 0 &&
                      searchResults.documents.map((doc) => {
                        const type = "document"
                        return (
                          <View
                            key={doc.id}
                            style={{
                              minHeight: 220,
                              flexBasis: "50%",
                              padding: 4,
                              alignContent: "center",
                              alignItems: "center",
                              paddingHorizontal: 15,
                              paddingBottom: 15,
                              paddingTop: 20,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => navigateTo("Document", { documentId: doc.id })}
                              style={{
                                backgroundColor: colors.WHITE,
                                borderRadius: 12,
                                minHeight: 220,
                                width: "100%",
                              }}
                            >
                              <ImageBackground
                                source={{ uri: doc.thumbnail }}
                                resizeMode="cover"
                                style={{
                                  width: "100%",
                                  height: 100,
                                  borderRadius: 11,
                                  justifyContent: "flex-end",
                                }}
                                imageStyle={{ borderRadius: 11 }}
                              >
                                {auth.isAnonymous ? (
                                  <TouchableOpacity
                                    onPress={() => {
                                      showModal(
                                        <ModalAnonymous
                                          hideModal={hideModal}
                                          navigateTo={navigateTo}
                                        />,
                                      )
                                    }}
                                    style={$favoriteContainerTouchDocument}
                                  >
                                    <View>
                                      <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                                    </View>
                                  </TouchableOpacity>
                                ) : favStates[type] && favStates[type][doc.id] ? (
                                  <View style={$favoriteContainerTouchDocument}>
                                    <View>
                                      <ActivityIndicator size={16} color={colors.MAIN_AQUAMARINE} />
                                    </View>
                                  </View>
                                ) : isInFavorites(doc.id, type) ? (
                                  <TouchableOpacity
                                    onPress={() => removeFavorite(doc.id, type)}
                                    style={$favoriteContainerTouchDocument}
                                  >
                                    <View>
                                      <Icon icon="heart" size={14} color={colors.MAIN_AQUAMARINE} />
                                    </View>
                                  </TouchableOpacity>
                                ) : (
                                  <TouchableOpacity
                                    onPress={() => addFavorite(doc.id, type)}
                                    style={$favoriteContainerTouchDocument}
                                  >
                                    <View>
                                      <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                                    </View>
                                  </TouchableOpacity>
                                )}
                              </ImageBackground>
                              <View style={{ paddingVertical: 5, paddingHorizontal: 7 }}>
                                <View style={{ paddingTop: 5, paddingBottom: 3 }}>
                                  <Text
                                    text={doc.title}
                                    numberOfLines={2}
                                    style={{
                                      fontFamily: typography.fonts.montserrat.bold,
                                      fontSize: 18,
                                      fontStyle: "normal",
                                      fontWeight: "bold",
                                      lineHeight: 20,
                                      letterSpacing: 0,
                                      textAlign: "left",
                                      color: colors.MAIN_DARK_BLUE,
                                    }}
                                  />
                                </View>
                                <View
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {doc.categories.length > 0 &&
                                    doc.categories.map((category) => (
                                      <View
                                        key={category.id}
                                        style={{
                                          borderWidth: 1,
                                          borderStyle: "solid",
                                          borderRadius: 5,
                                          borderColor: colors.LIGHT_GRAY,
                                          paddingHorizontal: 3,
                                          marginHorizontal: 1,
                                          marginVertical: 1,
                                        }}
                                      >
                                        <Text
                                          numberOfLines={1}
                                          style={{
                                            fontFamily: typography.fonts.montserrat.normal,
                                            fontSize: 12,
                                            lineHeight: 15,
                                            fontWeight: "300",
                                            fontStyle: "normal",
                                            letterSpacing: 0,
                                            color: colors.LIGHT_GRAY,
                                            textAlign: "left",
                                          }}
                                          preset="formLabel"
                                        >
                                          {category.name}
                                        </Text>
                                      </View>
                                    ))}
                                </View>
                                <View style={{ paddingVertical: 8 }}>
                                  <View>
                                    <Text
                                      text={doc.description}
                                      numberOfLines={2}
                                      style={{
                                        fontFamily: typography.fonts.montserrat.normal,
                                        fontSize: 13,
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: 15,
                                        letterSpacing: 0,
                                        textAlign: "left",
                                        color: colors.SHADES_OF_BLACK,
                                      }}
                                    />
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        )
                      })}
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ContainerResponsive>
      </Screen>
      <Footer />
    </>
  )
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }
const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 27,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  marginLeft: -5,
  paddingBottom: 20,
  paddingTop: 10,
  color: colors.WHITE,
}
const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}
const $firstSearchText1: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 30,
  lineHeight: 28,
  fontStyle: "normal",
  letterSpacing: 0,
  display: "flex",
  justifyContent: "flex-start",
  textAlign: "center",
  fontWeight: "bold",
  color: colors.WHITE,
}
const $favoriteContainerTouchDocument: ViewStyle = {
  width: 32,
  height: 32,
  borderRadius: 16,
  margin: 5,
  backgroundColor: colors.MEDIUM_GRAY,
  alignSelf: "flex-end",
  alignItems: "center",
  justifyContent: "center",
}
const $showMoreButtonContainer: ViewStyle = {
  paddingTop: 10,
  paddingBottom: 10,
}

const $ShowMoreStyle: TextStyle = {
  color: colors.MAIN_AQUAMARINE,
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  fontSize: 15,
}

const $categoriesText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 20,
  lineHeight: 28,
  fontStyle: "normal",
  letterSpacing: 0,
  display: "flex",
  fontWeight: "bold",
  color: colors.WHITE,
}
const $presentersText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 20,
  lineHeight: 28,
  fontStyle: "normal",
  letterSpacing: 0,
  display: "flex",
  fontWeight: "bold",
  color: colors.WHITE,
}

const $categoriesContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingBottom: 20,
}
const $videosContainer: ViewStyle = {
  paddingLeft: 10,
  paddingTop: 40,
  display: "flex",
}
const $presentersContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingTop: 40,
  paddingBottom: 20,
}
const $showMoreContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingTop: 10,
  paddingBottom: 5,
  paddingLeft: 7,
}
const $showMoreContainer2: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingBottom: 5,
  paddingLeft: 7,
}

const $removeFiltersBtn: ViewStyle = {
  width: 150,
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $loader: ViewStyle = {
  marginVertical: 100,
}

const $removeBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
  fontWeight: "bold",
}

const $removeBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $screenContentContainer: ViewStyle = {
  paddingTop: spacing.small,
  paddingBottom: spacing.medium,
  flexGrow: 1,
}
