import { useAppSelector, useAppDispatch } from "@app/store/hooks/useRedux"
import { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { launchCamera, launchImageLibrary } from "react-native-image-picker"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { Alert } from "react-native"
import { useIsMounted } from "@app/utils/isMounted"
import { editUser } from "@app/services/api/users"
import { uploadImageToFirebase } from "@app/utils/firebase/uploadImage"
import { verifyAuth } from "@app/store/actions/auth"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { ModalShowInfo } from "@app/components/ModaShowInfo"
import { isWeb } from "@app/utils/isWeb"

const useProfile = ({ goBack = null }) => {
  const user = useAppSelector((state) => state.auth.user)
  const isMounted = useIsMounted()
  const dispatch = useAppDispatch()
  const { showModal, hideModal } = useModal()
  const { showActionSheetWithOptions } = useActionSheet()
  const backgroundLoginImage = {} // LO DEJE ASI PARA QUE SE ELIMINARA LA FOTO DEL HEADER
  const profileImage = require("../../../../assets/images/profileImage.png")
  const [avatar, setAvatar] = useState(profileImage)
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [inputWeb, setInputWeb] = useState(null)

  useEffect(() => {
    if (isWeb()) {
      if (inputWeb) {
        inputWeb.addEventListener("change", (e: any) => {
          if (e.target.files[0].size > 5242880) {
            showModal(
              ModalShowInfo({
                hideModal,
                type: "error",
                message: "La imagen debe pesar menos de 5mb",
              }),
              null,
              588,
            )
            return
          }
          const url = URL.createObjectURL(e.target.files[0])
          setAvatar({ uri: url })
          setImage(e.target.files[0])
        })
      } else {
        setInputWeb(document.getElementById("file-upload"))
      }
    }
  }, [inputWeb])

  const form = useFormik({
    initialValues: {
      name: user.name,
      last_name: user.last_name,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required("Nombre es requerido"),
      last_name: Yup.string().max(255).required("Apellido es requerido"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        setIsLoading(true)
        let newBody = {
          ...user,
          ...values,
        }
        delete newBody.id
        delete newBody.rol
        delete newBody.submit

        if (image) {
          const url = await uploadImageToFirebase(image, user.id)
          newBody = {
            ...newBody,
            avatar_url: url || user.avatar_url,
          }
        }

        await editUser(user.id, newBody)
        dispatch(verifyAuth())
        setStatus({ success: true })
        setSubmitting(false)
        setIsLoading(false)
        showModal(
          ModalShowInfo({
            hideModal,
            callBack: () => {
              goBack && goBack()
            },
            type: "success",
            message: "Usuario modificado con éxito",
          }),
          null,
          isWeb() ? 588 : null,
        )
      } catch (err) {
        console.error(err)
        if (isMounted()) {
          setStatus({ success: false })
          setErrors({ submit: err.code })
          setSubmitting(false)
        }
        setIsLoading(false)
      }
    },
  })

  const onPressCamera = () => {
    if (isWeb() && inputWeb) {
      inputWeb.click()
      return
    }
    const options = ["Abrir cámara", "Abrir galería", "Cancelar"]
    const destructiveButtonIndex = 2
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      async (selectedIndex: number) => {
        switch (selectedIndex) {
          case 0:
            {
              const response = await launchCamera({
                saveToPhotos: true,
                mediaType: "photo",
                includeBase64: false,
              })
              if (!response.didCancel) {
                setAvatar({ uri: response.assets[0].uri })
                setImage(response)
              }
              if (response.errorCode) {
                Alert.alert("Ocurrió un error inesperado")
              }
            }
            break
          case 1:
            {
              const response = await launchImageLibrary({
                selectionLimit: 1,
                mediaType: "photo",
                includeBase64: false,
                quality: 0.5,
              })
              if (!response.didCancel) {
                setAvatar({ uri: response.assets[0].uri })
                setImage(response)
              }
              if (response.errorCode) {
                Alert.alert("Ocurrió un error inesperado")
              }
            }
            break
          case cancelButtonIndex:
            break
        }
      },
    )
  }

  return {
    user,
    backgroundLoginImage,
    avatar,
    form,
    onPressCamera,
    setAvatar,
    image,
    setImage,
    isLoading,
  }
}

export default useProfile
