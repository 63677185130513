/* eslint-disable @typescript-eslint/no-unused-vars */
// Hooks:
import { useState, useEffect } from "react"

// Firebase:
import { uploadImageToFirebase } from "@app/utils/firebase/uploadImage"
import auth from "@app/services/firebase/auth/Auth"

// Redux:
import { setIsLoading } from "@app/store/slices/app"
import { useAppDispatch, useAppSelector } from "@app/store/hooks/useRedux"

// Formik:
import { useFormik } from "formik"
import * as Yup from "yup"

// Axios:
import axiosInt from "@app/services/axios"

// Subida de imagenes:
import { useActionSheet } from "@expo/react-native-action-sheet"
import { launchCamera, launchImageLibrary } from "react-native-image-picker"
import { Alert } from "react-native"
import { isWeb } from "@app/utils/isWeb"
import { useModal } from "@app/components/CustomComponents/Modal/modal-context"
import { ModalShowInfo } from "@app/components/ModaShowInfo"
import { AUTH_HEADERS } from "@app/utils/constants"

// ************************************************************************************

// Manejo de errores de la cámara:
const errorMessageCamera = {
  camera_unavailable: "La cámara no está disponible",
  permission: "La aplicación no posee los permisos necesarios para acceder a la cámara",
  others: "Ocurrió un error al abrir la cámara",
}

// Manejo de errores de la galería:
const errorMessageGallery = {
  permission: "La aplicación no posee los permisos necesarios para acceder a la galería",
  others: "Ocurrió un error al abrir la galería",
}

// ************************************************************************************

const useRegister = () => {
  const AVATAR_DEFAULT = require("../../../../../assets/images/profileImage.png")
  // Hook para el manejo de estados de las imágenes:
  const [image, setImage] = useState(null)
  const [avatar, setAvatar] = useState(AVATAR_DEFAULT)
  // Para el manejo del ícono de la contraseña:
  const [isAuthPasswordHidden, setIsAuthPasswordHidden] = useState(true)

  // Manejo de las ventanas modales:
  const [isVisible, setVisible] = useState(false)
  const [isActiveModalEmail, setActiveModalEmail] = useState(false)
  const [typeModal, setTypeModal] = useState("")
  const [emailRegister, setEmailRegister] = useState(null)
  const { isLoading } = useAppSelector((state) => state.app)

  // Acciones al store
  const dispatch = useAppDispatch()

  // Menú de opciones para la subida de imágenes:
  const { showActionSheetWithOptions } = useActionSheet()

  const [inputWeb, setInputWeb] = useState(null)
  const { showModal, hideModal } = useModal()

  useEffect(() => {
    if (isWeb()) {
      if (inputWeb) {
        inputWeb.addEventListener("change", (e: any) => {
          if (e.target.files[0] && e.target.files[0].size > 5242880) {
            showModal(
              ModalShowInfo({
                hideModal,
                type: "error",
                message: "La imagen debe pesar menos de 5mb",
              }),
            )
            return
          }
          const url = URL.createObjectURL(e.target.files[0])
          setAvatar({ uri: url })
          setImage(e.target.files[0])
        })
      } else {
        setInputWeb(document.getElementById("file-upload"))
      }
    }
  }, [inputWeb])

  // Subida de imágenes:
  const selectUploadType = () => {
    if (isWeb() && inputWeb) {
      inputWeb.click()
      return
    }
    const options = ["Abrir cámara", "Abrir galería", "Cancelar"]
    const destructiveButtonIndex = 2
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      async (selectedIndex: number) => {
        switch (selectedIndex) {
          case 0:
            {
              const response = await launchCamera({
                saveToPhotos: true,
                mediaType: "photo",
                includeBase64: false,
              })
              if (response.errorCode) {
                return Alert.alert(errorMessageCamera[response.errorCode])
              }
              if (!response.didCancel) {
                setAvatar({ uri: response.assets[0].uri })
                setImage(response)
              }
            }
            break
          case 1:
            {
              const response = await launchImageLibrary({
                selectionLimit: 1,
                mediaType: "photo",
                includeBase64: false,
                quality: 0.5,
              })
              if (!response.didCancel) {
                setAvatar({ uri: response.assets[0].uri })
                setImage(response)
              }
              if (response.errorCode) {
                Alert.alert(errorMessageGallery[response.errorCode])
              }
            }
            break
          case cancelButtonIndex:
            break
        }
      },
    )
  }

  const formik = useFormik({
    initialValues: {
      avatar_url: null,
      name: "",
      last_name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(50, "Máximo 50 caracteres").trim().required("Este campo es requerido"),
      last_name: Yup.string()
        .max(50, "Máximo 50 caracteres")
        .trim()
        .required("Este campo es requerido"),
      email: Yup.string()
        .email("Email no válido")
        .min(8, "Mínimo 8 caracteres")
        .max(50, "Máximo 50 caracteres")
        .trim()
        .required("Este campo es requerido"),
      password: Yup.string()
        .min(6, "Mínimo 6 caracteres")
        .max(14, "Máx. 14 caracteres")
        .trim()
        .required("Este campo es requerido"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        dispatch(setIsLoading(true))
        let authID = null
        let responsePost = null
        let idUser = null

        //* Firebase:
        try {
          // 1) Creo un usuario en firebase:
          const responseCreateFirebase = await auth().createUserWithEmailAndPassword(
            values.email,
            values.password,
          )

          // 2) Envío el email de verificación
          await responseCreateFirebase.user.sendEmailVerification()

          // 3) Obtengo el auth_id del usuario creado en firebase para insertarlo en la db
          authID = responseCreateFirebase.user.uid
        } catch (error) {
          if (error?.message.includes("email-already-in-use")) {
            setVisible(true)
            setActiveModalEmail(true)
            setTypeModal("error-email")
            console.log("Este email ya existe", error.message)
          } else {
            setVisible(true)
            setTypeModal("error")
            console.log("Error al crear user en firebase", error)
          }
        }

        //* POST Y PUT en base de datos:
        try {
          // 4) Inserto el usuario en la base de datos:
          responsePost = await axiosInt.post("/api/users/register", {
            ...values,
            auth_id: authID,
          })

          idUser = responsePost.data.data.id
          setEmailRegister(await responsePost.data.data.email)

          // 6) Muestro un mensaje exitoso:
          console.log(`Usuario ${responsePost.data.data.email} creado`)
          setVisible(true)
          setTypeModal("success")
        } catch (error) {
          console.log(
            `No se creó usuario. authID del usuario creado en firebase que debe ser borrado: ${authID}`,
          )
          console.log("Error: ", error?.message)
        }

        // 7) Si está definida la imagen actualizo el campo avatar
        if (image) {
          // 8) Obtengo el id del usuario creado en la base de datos para renombrar el avatar:
          const URL_FIREBASE = await uploadImageToFirebase(image, idUser)

          if (idUser) {
            await axiosInt.put(
              `/api/users/${idUser}`,
              {
                ...values,
                avatar_url: URL_FIREBASE,
              },
              await AUTH_HEADERS(),
            )
          }
        }
        // 5) Empiezo el ciclo de envío
        setStatus({ success: true })
        setSubmitting(true)
        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        if (!isActiveModalEmail) {
          setTypeModal("error")
          setVisible(true)
        }
        console.log("Error general:", error)
        setStatus({ success: false })
        setErrors(error.code)
      }
    },
  })

  return {
    isLoading,
    isVisible,
    setVisible,
    typeModal,
    setTypeModal,
    emailRegister,
    image,
    isAuthPasswordHidden,
    setImage,
    setIsAuthPasswordHidden,
    selectUploadType,
    avatar,
    setAvatar,
    AVATAR_DEFAULT,
    formik,
  }
}

export default useRegister
