import React, { FC } from "react"
import { View, ViewStyle, TouchableOpacity, ImageBackground, TextStyle } from "react-native"
import { Text, Screen, Icon } from "@app/components"
import { TabScreenProps } from "@app/navigators/DrawerNavigator"
import { colors, typography } from "@app/theme"
import useActivity from "./hooks/use-activity"
import { FavoritesComponent } from "./components/FavoritesComponent"
import { HistoryComponent } from "./components/HistoryComponent"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { Grid } from "@mui/material"
import { $isActiveComponent, $isNotActiveComponent } from "./styles"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { Footer } from "../Home/components/Footer"
import { useNavigation } from "@app/navigators/utils"
import { isWeb } from "@app/utils/isWeb"
// Imagen de fondo:
const backgroundImage = require("../../../assets/images/web/background-activity.png")

export const Activity: FC<TabScreenProps<"Activity">> = ({ navigation }) => {
  const { goBack } = useNavigation()
  const {
    favorites,
    isFavoritesActive,
    onPressActivityBtn,
    removeFavorite,
    history,
    isInFavorites,
    favStates,
  } = useActivity()

  const { height } = useWindowDimensions()

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.MAIN_DARK_BLUE,
    minHeight: height - APP_BAR_HEIGHT,
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["bottom"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <View style={$headerContainer}>
        <ContainerResponsive>
          <View style={$headerContainer}>
            <TouchableOpacity onPress={() => goBack()} style={$backBtn}>
              <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
            </TouchableOpacity>
            <View style={$textHeaderContainer}>
              {isWeb() ? (
                <Text text="Volver" style={$textHeader} />
              ) : (
                <Text text="Reproductor" style={$textHeader} />
              )}
            </View>
          </View>
        </ContainerResponsive>
      </View>
      <ImageBackground
        source={backgroundImage}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ width: "100%", minHeight: height - APP_BAR_HEIGHT }}
        resizeMode="cover"
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10} xl={8}>
            <View style={$activityBtnContainer}>
              <TouchableOpacity
                onPress={onPressActivityBtn}
                style={[$activityBtn, isFavoritesActive && $activityBtnActive]}
              >
                {isFavoritesActive ? (
                  <Text text="Favoritos" style={$isActiveComponent} />
                ) : (
                  <Text text="Favoritos" style={$isNotActiveComponent} />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={onPressActivityBtn}
                style={[$activityBtn, !isFavoritesActive && $activityBtnActive]}
              >
                {!isFavoritesActive ? (
                  <Text text="Historial" style={$isActiveComponent} />
                ) : (
                  <Text text="Historial" style={$isNotActiveComponent} />
                )}
              </TouchableOpacity>
            </View>
            {isFavoritesActive ? (
              <FavoritesComponent
                favorites={favorites}
                removeFavorite={removeFavorite}
                navigation={navigation}
                favStates={favStates}
              />
            ) : (
              <HistoryComponent
                history={history}
                isInFavorites={isInFavorites}
                navigation={navigation}
              />
            )}
          </Grid>
        </Grid>
      </ImageBackground>
      <Footer />
    </Screen>
  )
}

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $backBtn: ViewStyle = { paddingRight: 10 }

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}

const $activityBtnContainer: ViewStyle = {
  flexDirection: "row",
  width: "100%",
  height: 38,
  alignItems: "center",
  justifyContent: "space-around",
}

const $activityBtnActive: ViewStyle = {
  borderBottomWidth: 3,
  borderBottomColor: colors.MAIN_AQUAMARINE,
}

const $activityBtn: ViewStyle = {
  width: "50%",
  height: 80,
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 10,
  paddingBottom: 10,
}
