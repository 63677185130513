import React, { CSSProperties } from "react"
import { TextStyle } from "react-native"
import { Text } from "@app/components"
import { Link } from "react-router-dom"
import { typography, colors } from "@app/theme"

export const NavigateBtnFooter = ({ isAnonymous }) => {
  return (
    <>
      <Link to="/Home" relative="path" style={$links}>
        <Text text="Inicio" style={$pagesText} />
      </Link>
      <Link to="/Search" relative="path" style={$links}>
        <Text text="Búsqueda" style={$pagesText} />
      </Link>
      {!isAnonymous && (
        <Link to="/Activity" relative="path" style={$links}>
          <Text text="Actividad" style={$pagesText} />
        </Link>
      )}
    </>
  )
}

const $pagesText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 16,
  lineHeight: 20,
  fontWeight: "400",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $links: CSSProperties = {
  textDecoration: "inherit",
  color: "inherit",
  marginBottom: 10,
}
