/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect } from "react"
import { colors, typography } from "@app/theme"
import { ActivityIndicator, TextStyle, Platform, ViewStyle } from "react-native"
import { Button, Icon, Text, TextField } from "@app/components"
import { useNavigation } from "@app/navigators/utils"
import useFeedback from "./hooks/use-feedback"
import { ModalFeedback } from "./components/Modal.web"
import { Grid } from "@mui/material"

const FeedBackScreen = ({ navigation, route }) => {
  const FOOTER_IMAGE = require("../../../assets/images/web/background-activity.png")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const $container: CSSProperties = {
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${FOOTER_IMAGE})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "100vh",
    padding: "1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: '5%',
  }

  const { goBack } = useNavigation()
  const {
    isLoading,
    isVisible,
    setVisible,
    typeModal,
    setTypeModal,
    formik: { values, handleBlur, handleChange, handleSubmit, errors, touched },
  } = useFeedback()

  return (
    <>
      <ModalFeedback
        isVisible={isVisible}
        setVisible={setVisible}
        typeModal={typeModal}
        setTypeModal={setTypeModal}
      />
      <div style={$container}>
        <Grid container justifyContent="space-between" alignItems="center" style={$gridBack}>
          <Grid item xs={2} sm={1}>
            <Icon icon="back" style={iconBack} onPress={() => goBack()} />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" style={$gridTitle}>
          <Grid item xs={12} md={8} style={$textCenter}>
            <Text tx="feedbackScreen.titleScreen" style={$formTitle} />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" justifyContent="center" style={$gridInputs}>
          <Grid item xs={12} md={5}>
            <Text style={$labelInput} tx="feedbackScreen.fullNameFieldLabel" />
            <TextField
              style={$inputStyle}
              onChangeText={handleChange("fullName")}
              onBlur={handleBlur("fullName")}
              value={values.fullName}
              helper={touched.fullName && errors.fullName}
              status={touched.fullName && errors.fullName ? "error" : null}
              placeholderTextColor={colors.GRAY}
              placeholderTx="feedbackScreen.fullNameFieldPlaceHolder"
              inputWrapperStyle={$inputWrapper}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Text style={$labelInput} tx="feedbackScreen.emailFieldLabel" />
            <TextField
              style={$inputStyle}
              onChangeText={handleChange("email")}
              onBlur={handleBlur("email")}
              autoCapitalize="none"
              autoCorrect={false}
              helper={touched.email && errors.email}
              value={values.email}
              status={touched.email && errors.email ? "error" : null}
              placeholderTextColor={colors.GRAY}
              placeholderTx="feedbackScreen.emailFieldPlaceHolder"
              inputWrapperStyle={$inputWrapper}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" justifyContent="center" style={$gridComment}>
          <Grid item xs={12} md={10}>
            <Text style={$labelInput} tx="feedbackScreen.comentarioFieldLabel" />
            <TextField
              style={$inputStyle}
              onChangeText={handleChange("comment")}
              onBlur={handleBlur("comment")}
              autoCapitalize="none"
              autoCorrect={false}
              helper={touched.comment && errors.comment}
              value={values.comment}
              status={touched.comment && errors.comment ? "error" : null}
              placeholderTextColor={colors.GRAY}
              placeholderTx="feedbackScreen.comentarioFieldPlaceHolder"
              inputWrapperStyle={$inputWrapperComments}
              multiline
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" style={$gridSubmit}>
          {isLoading ? (
            <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
          ) : (
            <Grid container spacing={3} justifyContent="center" item xs={12} md={6}>
              <Grid item xs={6} sm={5}>
                <Button
                  style={$buttonCancel}
                  textStyle={$textButtonCancel}
                  preset="filled"
                  tx="feedbackScreen.tapToCancel"
                  onPress={() => goBack()}
                />
              </Grid>
              <Grid item xs={6} sm={5}>
                <Button
                  style={$buttonConfirm}
                  textStyle={$textButtonConfirm}
                  preset="filled"
                  tx="feedbackScreen.tapToConfirm"
                  onPress={handleSubmit}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

export default FeedBackScreen

const iconBack = {
  width: 40,
  height: 40,
  cursor: "pointer",
}

const $formTitle = {
  fontWeight: "bold",
  fontStyle: "normal",
  fontSize: Platform.OS === "web" ? "2.5rem" : 48,
  textAlign: "center",
  color: colors.WHITE,
} as TextStyle

const $labelInput = {
  fontSize: Platform.OS === "web" ? "1rem" : 16,
  fontFamily: typography.fonts.montserrat.bold,
  color: colors.WHITE,
  textAlign: "center",
} as TextStyle

const $inputStyle: TextStyle = {
  backgroundColor: colors.MAIN_DARK_BLUE,
  color: colors.WHITE,
}

const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $inputWrapperComments: ViewStyle = {
  height: 120,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $buttonConfirm = {
  width: "90%",
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

const $buttonCancel = {
  width: "90%",
  height: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $textButtonConfirm = {
  fontFamily: "Montserrat",
  fontSize: Platform.OS === "web" ? "1rem" : 16,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
} as TextStyle

const $textButtonCancel = {
  fontFamily: "Montserrat",
  fontSize: Platform.OS === "web" ? "1rem" : 16,
  fontWeight: "bold",
  textAlign: "center",
  color: colors.WHITE,
} as TextStyle

const $loader = {
  margin: "2%",
}

const $gridSubmit = { marginTop: "4em", paddingLeft: '10px' }

const $gridComment = { marginTop: "1em", width: '91%' }

const $gridInputs = { padding: "0 2%", width: '95%' }

const $gridBack = { padding: "1em", width: "100%" }

const $gridTitle = { marginBottom: "6%", paddingTop: '2%' }

const $textCenter = { textAlign: "center" }