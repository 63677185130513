import { colors, typography } from "@app/theme"
import { ViewStyle, ImageStyle, TextStyle } from "react-native"

export const $videoContainer: ViewStyle = {
  marginLeft: "4.4%",
  marginRight: "4.4%",
  marginTop: "2%",
  marginBottom: "2%",
}

export const $separator: ViewStyle = {
  height: 1,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.PALE_GREY,
  backgroundColor: colors.PALE_GREY,
}

export const $btnWatch: ViewStyle = {
  width: 85,
  height: 37,
  minHeight: 34,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
  alignContent: "flex-start",
  alignSelf: "flex-start",
}

export const $containerBtnWatch: ViewStyle = {
  justifyContent: "flex-start",
  alignContent: "flex-start",
  alignSelf: "flex-start",
  paddingLeft: '10%'
}

export const $descriptionTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  fontWeight: "500",
  fontSize: 13.5,
  fontStyle: "normal",
  lineHeight: 15,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

export const $titleTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontWeight: "600",
  fontSize: 15,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

export const $backgroundImage: ImageStyle = {
  width: 97,
  height: 97,
  justifyContent: "flex-end",
  marginVertical: 20,
  alignItems: "flex-end",
  paddingRight: 3,
  paddingBottom: 3
}

export const $rows: ViewStyle = {
  minHeight: 97,
  flexDirection: "row",
  alignItems: "center",
}

export const $btnWatchPressedStyle: ViewStyle = {
  width: 82,
  height: 34,
  minHeight: 34,
  borderRadius: 30,
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: colors.MAIN_AQUAMARINE,
}

export const $videoStyle: ImageStyle = {
  height: 25,
  width: 25,
  backgroundColor: colors.DARK_GRAY,
  borderRadius: 33,
  justifyContent: "center",
  alignItems: "center",
}

export const $btnWatchTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.black,
}

export const $btnWatchPressedTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.SHADES_OF_BLACK,
}

export const $isActiveComponent: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  color: colors.MAIN_AQUAMARINE,
}

export const $isNotActiveComponent: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontWeight: "bold",
  color: colors.WHITE,
}
