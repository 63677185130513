import React, { FC, useEffect } from "react"
import {
  View,
  ViewStyle,
  ImageBackground,
  ImageStyle,
  TextStyle,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import { colors, typography } from "@app/theme"
import { Button, Text, Icon, TextField } from "@app/components"
import { isWeb } from "@app/utils/isWeb"
import { useIsMounted } from "@app/utils/isMounted"
import useProfile from "../screens/Profile/hooks/use-profile"
import useRegister from "../screens/Auth/Register/hooks/useRegister"

interface ModalEditProfileProps {
  hideModal: any
  navigateTo: any
}

export const ModalEditProfile: FC<ModalEditProfileProps> = ({ hideModal }) => {
  const isMounted = useIsMounted()
  const { selectUploadType } = useRegister()

  const { user, avatar, form, onPressCamera, setAvatar, image, setImage, isLoading } = useProfile(
    {},
  )

  useEffect(() => {
    if (isMounted()) {
      if (user.avatar_url) {
        setAvatar({ uri: user.avatar_url })
      }
    }
  }, [])

  return (
    <>
      <View style={$exitModalContainer}>
        <TouchableOpacity onPress={() => hideModal()}>
          <Icon icon="x" size={15} />
        </TouchableOpacity>
      </View>

      <View style={$modalContainerSuccess}>
        <View style={$headerContainer}>
          <View style={$titleContainer}>
            <Text text="Editar Perfil" style={$titleText} />
          </View>
        </View>

        <View style={$backgroundCircleAvatar}>
          <ImageBackground
            source={avatar}
            style={$profileImage}
            resizeMode="cover"
            imageStyle={$avatarImage}
          >
            {image ? (
              <TouchableOpacity
                onPress={() => {
                  setImage(null)
                  setAvatar({ uri: user.avatar_url })
                }}
              >
                <View style={$cameraIconContainer}>
                  <Icon icon="x" size={24} color={colors.MAIN_DARK_BLUE} />
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => onPressCamera()}>
                <View style={$iconCamera}>
                  {isWeb() && (
                    <input
                      id="file-upload"
                      type="file"
                      style={$inputStyleImage}
                      accept="image/png, image/jpeg"
                    />
                  )}
                  <Icon icon="camera" size={24} onPress={selectUploadType} />
                </View>
              </TouchableOpacity>
            )}
          </ImageBackground>
        </View>

        <View style={$formContainer}>
          <View style={$inputFormContainer}>
            <TextField
              onChangeText={form.handleChange("name")}
              onBlur={form.handleBlur("name")}
              value={form.values.name}
              autoCapitalize="none"
              autoCorrect={false}
              helper={form.touched.name && form.errors.name}
              HelperTextProps={{ style: $helperTextStyle }}
              status={form.touched.name && form.errors.name ? "error" : null}
              placeholder="Nombre"
              inputWrapperStyle={$inputWrapper}
              style={$placeholderStyle}
            />
          </View>

          <View style={$inputFormContainer}>
            <TextField
              /* onChangeText={form.handleChange("last_name")} */
              onBlur={form.handleBlur("last_name")}
              value={form.values.last_name}
              autoCapitalize="none"
              autoCorrect={false}
              helper={form.touched.last_name && form.errors.last_name}
              HelperTextProps={{ style: $helperTextStyle }}
              status={form.touched.last_name && form.errors.last_name ? "error" : null}
              placeholder="Apellido"
              inputWrapperStyle={$inputWrapper}
              style={$placeholderStyle}
            />
          </View>
          <View style={$btnContent}>
            {isLoading ? (
              <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
            ) : (
              <>
                <View style={$editBtnContainer}>
                  <Button
                    onPress={form.handleSubmit}
                    text="Guardar"
                    preset="filled"
                    style={$editBtn}
                    textStyle={$editBtnText}
                  />
                </View>
                <Button
                  onPress={() => hideModal()}
                  text="Cancelar"
                  preset="filled"
                  style={$logOutBtn}
                  textStyle={$logOutBtnText}
                />
              </>
            )}
          </View>
        </View>
      </View>
    </>
  )
}

// CONTAINER GENERAL
const $modalContainerSuccess: ViewStyle = {
  backgroundColor: colors.WHITE,
  borderRadius: 12,
  justifyContent: "center",
  paddingTop: 15,
  paddingBottom: 25,
  paddingHorizontal: 10,
  height: 600,
}

// CONTAINER ICONO EXIT
const $exitModalContainer: ViewStyle = {
  marginTop: 8,
  marginLeft: 550,
}

// CONTAINER DEL HEADER
const $headerContainer: ViewStyle = {
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
}

// CONTAINER TITULO EDITAR PERFIL
const $titleContainer: ViewStyle = { marginBottom: 20, width: "330px" }
// CSS TIRULO EDITAR PERFIL
const $titleText: TextStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 28,
  fontWeight: "bold",
  marginTop: 25,
}

// CONTAINER FOTO DE PERFIL
const $backgroundCircleAvatar: ViewStyle = {
  width: 145,
  height: 145,
  backgroundColor: "white",
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  marginLeft: "115px",
  marginBottom: "30px",
}
// CSS FOTO DE PERFIL
const $avatarImage: ImageStyle = { borderRadius: 100 }
const $cameraIconContainer: ViewStyle = {
  width: 32,
  height: 32,
  backgroundColor: colors.MAIN_AQUAMARINE,
  borderRadius: 100,
  alignSelf: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  top: 5,
}

const $inputStyleImage = {
  display: "none",
}
const $iconCamera: ImageStyle = {
  width: 32,
  height: 32,
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-10%",
  position: "relative",
  borderRadius: 18,
  backgroundColor: colors.MAIN_AQUAMARINE,
}
const $profileImage: ImageStyle = {
  width: 109,
  height: 109,
  borderRadius: 100,
  justifyContent: "flex-end",
}

// CONTAINER FORM
const $formContainer: ViewStyle = {
  flex: 1,
  paddingVertical: 10,
  paddingHorizontal: 24,
  width: "330px",
  display: "flex",
  justifyContent: "center",
}
// CSS INPUTS DEL FORM
const $inputFormContainer: ViewStyle = {
  marginBottom: 24,
}
const $placeholderStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  fontWeight: "400",
  fontSize: 15,
  color: colors.MAIN_BLUE,
}
const $helperTextStyle: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
}
const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.LIGHT_GRAY,
  minHeight: 10,
  backgroundColor: colors.WHITE,
  width: "330px",
}

// CONTAINER BOTONES
const $btnContent: ViewStyle = {
  display: "flex",
  justifyContent: "center",
  marginHorizontal: "100px",
  marginLeft: 0,
  width: "330px",
}
// CSS DE LOS BOTONES
const $editBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
}
const $editBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_BLUE,
}
const $logOutBtn: ViewStyle = {
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}
const $logOutBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}
const $editBtnContainer: ViewStyle = {
  marginBottom: 27,
  paddingTop: 27,
}

// LOADER
const $loader: ViewStyle = {
  marginVertical: 25,
}
