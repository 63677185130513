/* eslint-disable react-native/no-inline-styles */
import React, { FC, Fragment, useEffect, useState } from "react"
import {
  ViewStyle,
  View,
  TouchableOpacity,
  TextStyle,
  ImageStyle,
  Image,
  ActivityIndicator,
} from "react-native"

import {
  Autocomplete,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  TextField as MaterialTextField,
  Grid,
} from "@mui/material"

import {
  Button as NativeButton,
  TextField as NativeTextField,
  Screen,
  Text,
  Icon,
} from "@app/components"
import { spacing, colors, typography } from "@app/theme"
import { AppStackScreenProps } from "@app/navigators"
import { APP_BAR_HEIGHT, THUMBNAIL_PLACEHOLDER } from "@app/utils/constants"
import useSearch from "./hooks/use-search"
import { translate as t } from "@app/i18n"
import { useNavigation } from "@app/navigators/utils"
import { isWeb } from "@app/utils/isWeb"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Footer } from "../Home/components/Footer"
import { fetchCategories } from "@app/services/api/categories"
import { fetchPresenters } from "@app/services/api/presenters"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { VideoSerieComponent } from "./components/VideoSerieComponent"
import { DocumentComponent } from "./components/DocumentComponent"

const backgroundImage = require("../../../assets/images/web/background-screens.png")
const backgroundNotFound = require("../../../assets/images/web/background-notFound.png")

export const SearchScreen: FC<AppStackScreenProps<"Player">> = ({ navigation, route }) => {
  const { navigateTo, goBack, params } = useNavigation(navigation)
  const [searchValueParam, setSearchValueParam] = useState(false)
  const { height } = useWindowDimensions()
  const [open, setOpen] = React.useState(false)
  const [applyFilter, setApplyFilter] = useState(false)
  const [selected, setSelected] = useState({
    categories: [],
    presenters: [],
  })
  const [suggestions, setSuggestions] = useState([])
  const insets = useSafeAreaInsets()

  const {
    isLoading,
    title,
    initializeSearch,
    debouncedTitleHandler,
    onFiltersChange,
    isFirstSearch,
    filterModal,
    resultsSearch,
  } = useSearch()

  useEffect(() => {
    // aca obtengo las opciones de la api
    const fetchOptions = async () => {
      const categoriesResponse = await fetchCategories()
      const presentersResponse = await fetchPresenters()

      // las proceso y les doy el formato
      const processedCategories = categoriesResponse.data.data.map((category) => ({
        label: category.name,
        value: category.id,
      }))
      const processedPresenters = presentersResponse.data.data.map((presenter) => ({
        label: `${presenter.first_name} ${presenter.last_name}`,
        value: presenter.id,
      }))

      // guardo las opciones en este estado
      setSuggestions([
        {
          title: "Categorías",
          options: processedCategories,
        },
        {
          title: "Presentadores",
          options: processedPresenters,
        },
      ])
    }
    fetchOptions()
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleApplyFilters = () => {
    setApplyFilter((prev) => !prev)
    handleClose()
  }

  // Función para quitar filtros
  const handleRemoveFilters = () => {
    setSelected({
      categories: [],
      presenters: [],
    })
    handleClose()
  }

  useEffect(() => {
    initializeSearch()
  }, [])

  useEffect(() => {
    if (params?.searchValue && searchValueParam === false) {
      onFiltersChange(params.searchValue, true)
      setSearchValueParam(true)
    } else {
      onFiltersChange(title)
    }
  }, [title, applyFilter])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const MagnifyIconAccessory = () => (
    <View style={$magnifyIconContainer}>
      <Icon icon="magnify" size={24} color={colors.WHITE} />
    </View>
  )

  const $screenContentContainerWeb = {
    flexGrow: 1,
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${backgroundImage})`,
    minHeight: height - APP_BAR_HEIGHT,
    paddingBottom: 130,
    paddingTop: insets.top,
    backgroundSize: "100% 100%",
    backgroundAttachment: "fixed",
    backgroundPosition: "center, center",
  }

  return (
    <>
      <Screen
        preset="scroll"
        safeAreaEdges={["bottom"]}
        contentContainerStyle={isWeb() ? $screenContentContainerWeb : $screenContentContainer}
      >
        <View style={$headerContainer}>
          <ContainerResponsive>
            <View style={$headerContainer}>
              <TouchableOpacity onPress={async () => goBack()} style={$backBtn}>
                <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
              </TouchableOpacity>
              <View style={$headerContainer}>
                {isWeb() ? (
                  <Text
                    text="Volver"
                    style={{
                      fontFamily: typography.fonts.montserrat.bold,
                      fontSize: 16,
                      lineHeight: 21,
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: colors.WHITE,
                    }}
                  />
                ) : (
                  <Text text={route?.name} style={$textHeader} />
                )}
              </View>
            </View>
          </ContainerResponsive>
        </View>
        {filterModal}
        <Dialog fullWidth={false} maxWidth={"xs"} open={open} onClose={handleClose}>
          <View style={$exitModalContainer}>
            <TouchableOpacity
              onPress={() => {
                handleCloseDialog()
              }}
            >
              <Icon icon="x" size={15} />
            </TouchableOpacity>
          </View>

          <DialogContent>
            <View style={$selectContainers}>
              <Stack spacing={3} sx={{ width: 300 }}>
                <Autocomplete
                  multiple
                  options={suggestions[0]?.options || []} // Categorías
                  getOptionLabel={(option) => option.label}
                  onChange={(_, values) => setSelected({ ...selected, categories: values })}
                  value={selected.categories}
                  renderInput={(params) => (
                    <MaterialTextField {...params} label="Filtrar por categorías" />
                  )}
                />
                <Autocomplete
                  multiple
                  options={suggestions[1]?.options || []} // Presentadores
                  getOptionLabel={(option) => option.label}
                  onChange={(_, values) => setSelected({ ...selected, presenters: values })}
                  value={selected.presenters}
                  renderInput={(params) => (
                    <MaterialTextField {...params} label="Filtrar por presentadores" />
                  )}
                />
              </Stack>
            </View>
          </DialogContent>

          <DialogActions>
            <View style={$modalBtnContainer}>
              <View style={$modalBtnView}>
                <NativeButton
                  tx="searchScreen.applyFilterTextBtn"
                  onPress={handleApplyFilters}
                  preset="reversed"
                  style={$applyBtn}
                  textStyle={$applyBtnText}
                  pressedStyle={$applyBtnPressed}
                  pressedTextStyle={$applyBtnTextPressed}
                />
              </View>
              <View style={$modalBtnView}>
                <NativeButton
                  text={t("searchScreen.removeAllText")}
                  onPress={handleRemoveFilters}
                  preset="reversed"
                  style={$removeFiltersBtn}
                  textStyle={$removeBtnText}
                  pressedStyle={$removeBtnPressed}
                  pressedTextStyle={$removeBtnTextPressed}
                />
              </View>
            </View>
          </DialogActions>
        </Dialog>
        <ContainerResponsive xs={11} md={11} lg={10} xl={9}>
          <Grid container>
            <Grid item xs={12} sx={{ pt: 5, pb: 5 }}>
              <Text text="Búsqueda" style={$textHeader} />
            </Grid>
            <Grid item xs sx={{ width: "100%" }}>
              <View style={{ display: "flex", width: "100%" }}>
                <NativeTextField
                  onChangeText={debouncedTitleHandler}
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholderTx="searchScreen.searchInputPlaceholder"
                  inputWrapperStyle={$inputWrapper}
                  placeholderTextColor={colors.GRAY}
                  style={$searchText}
                  LeftAccessory={MagnifyIconAccessory}
                />
              </View>
            </Grid>
            <Grid item sx={{ display: "flex", alignSelf: "center" }}>
              <TouchableOpacity onPress={handleClickOpen}>
                <View style={$btnFilter}>
                  <Icon icon="filter" size={24} style={$btnFilterStyle} />
                </View>
              </TouchableOpacity>
            </Grid>
          </Grid>

          {isLoading ? (
            <ActivityIndicator color={colors.SECONDARY_VIOLET} size="large" style={$loader} />
          ) : isFirstSearch ? (
            <View style={$firstSearchContainer}>
              <View style={$firstSearchIconContainer}>
                <Icon color="white" icon="magnify" size={25} style={$firstSearchIcon} />
              </View>
              <View>
                <View>
                  <Text text="Encontrá tus videos" style={$firstSearchText1} />
                </View>
                <View style={$firstSearchContainerText}>
                  <Text text="Realiza tu búsqueda y" style={$firstSearchTextDesc1} />
                  <Text text=" filtra " style={$firstSearchTextDescBold} />
                  <Text text="por las categorías que quieras" style={$firstSearchTextDesc1} />
                </View>
              </View>
            </View>
          ) : resultsSearch.documents.length > 0 ||
            resultsSearch.series.length > 0 ||
            resultsSearch.videos.length > 0 ? (
            <View>
              <View style={$searchBarContainer}>
                <View style={$searchInputContainer}>
                  <Text text="Resultados de la búsqueda" style={$textHeader2} />
                </View>
              </View>
              {resultsSearch.videos.length > 0 && (
                <VideoSerieComponent
                  items={resultsSearch.videos}
                  navigateTo={navigateTo}
                  type="video"
                />
              )}

              {resultsSearch.documents.length > 0 && (
                <DocumentComponent items={resultsSearch.documents} navigateTo={navigateTo} />
              )}

              {resultsSearch.series.length > 0 && (
                <VideoSerieComponent
                  items={resultsSearch.series}
                  navigateTo={navigateTo}
                  type="serie"
                />
              )}
            </View>
          ) : (
            <Fragment>
              <View style={$searchInputContainer}>
                <View style={$textNotFoundContainer}>
                  <Text text="Resultados de la búsqueda" style={$textHeader2} />
                  <Text text="Ups!" style={$upsText} />

                  <View style={$notFoundContainerText3}>
                    <Text
                      text="Al parecer no hay resultados relacionados con tu búsqueda"
                      style={$notFoundText3}
                    />
                  </View>
                  <View style={$notFoundContainerText4}>
                    <Text text="Intenta nuevamente con otra palabra." style={$notFoundText4} />
                  </View>
                </View>
              </View>
              <View>
                <Image
                  source={backgroundNotFound}
                  resizeMode="stretch"
                  style={$notFoundBackgroundImage}
                />
              </View>
            </Fragment>
          )}
        </ContainerResponsive>
      </Screen>
      <Footer />
    </>
  )
}

const $backBtn: ViewStyle = { paddingRight: 10 }

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}

const $textNotFoundContainer: ViewStyle = { paddingTop: 50 }

const $searchInputContainer: ViewStyle = {
  width: "90%",
  flexGrow: 1,
}

const $exitModalContainer: ViewStyle = {
  marginTop: 8,
  marginLeft: 375,
}
const $firstSearchContainerText: ViewStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 12,
}
const $firstSearchTextDescBold: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $searchText: TextStyle = {
  alignSelf: "center",
  fontFamily: typography.fonts.montserrat.normal,
  color: "white",
}

const $firstSearchIconContainer: ViewStyle = { flexDirection: "row" }

const $firstSearchIcon: ImageStyle = {
  paddingBottom: -10,
}

const $firstSearchText1: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 48,
  lineHeight: 28,
  fontStyle: "normal",
  letterSpacing: 0,
  paddingLeft: 30,
  display: "flex",
  justifyContent: "flex-start",
  textAlign: "center",
  fontWeight: "bold",
  color: colors.WHITE,
}

const $loader: ViewStyle = {
  marginVertical: 100,
}

const $notFoundBackgroundImage: ImageStyle = { width: "100%" }

const $btnFilterStyle = {
  tintColor: "white",
}
const $firstSearchContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  marginBottom: 20,
  marginTop: 45,
}

const $notFoundText3: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $firstSearchTextDesc1: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 17,
  lineHeight: 28,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  marginBottom: 200,
  color: colors.WHITE,
}

const $notFoundContainerText3: ViewStyle = { marginTop: 14 }

const $notFoundText4: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 17,
  lineHeight: 24,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  fontWeight: "bold",
  color: colors.WHITE,
}

const $notFoundContainerText4: ViewStyle = { marginTop: 10, marginBottom: 15 }

const $magnifyIconContainer: ViewStyle = {
  justifyContent: "center",
  alignSelf: "center",
  paddingLeft: 16,
}

const $inputWrapper: ViewStyle = {
  height: 48,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.WHITE,
  backgroundColor: colors.transparent,
}

const $btnFilter: ViewStyle = {
  width: 40,
  height: 40,
  borderStyle: "solid",
  borderWidth: 2,
  borderColor: colors.WHITE,
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "10px",
}

const $searchBarContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
  marginTop: 45,
}

const $modalBtnView: ViewStyle = {
  paddingHorizontal: 24,
}

const $modalBtnContainer: ViewStyle = {
  width: "100%",
}

const $removeFiltersBtn: ViewStyle = {
  width: "100%",
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.SECONDARY_VIOLET,
}

const $removeBtnPressed: ViewStyle = {
  width: "100%",
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.DARK_GRAY,
}

const $removeBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
  fontWeight: "bold",
}

const $removeBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $applyBtn: ViewStyle = {
  width: "100%",
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_AQUAMARINE,
  marginBottom: 13,
}

const $applyBtnPressed: ViewStyle = {
  width: "100%",
  height: 51,
  minHeight: 51,
  borderRadius: 30,
  backgroundColor: colors.MAIN_DARK_BLUE,
}

const $applyBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
  fontWeight: "bold",
}

const $applyBtnTextPressed: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.WHITE,
}

const $selectContainers: ViewStyle = {
  flex: 1,
  width: "100%",
  marginBottom: 18,
  paddingHorizontal: 24,
}

const $screenContentContainer: ViewStyle = {
  paddingTop: spacing.small,
  paddingBottom: spacing.medium,
  flexGrow: 1,
}

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 27,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  marginLeft: -5,
  paddingBottom: 20,
  paddingTop: 10,
  color: colors.WHITE,
}
const $upsText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 48,
  lineHeight: 21,
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  paddingBottom: 20,
  paddingTop: 20,
  color: colors.WHITE,
}
const $textHeader2: TextStyle = {
  flexGrow: 1,
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 20,
  lineHeight: 1,
  fontStyle: "normal",
  letterSpacing: 0,
  paddingBottom: 23,
  color: colors.WHITE,
}
