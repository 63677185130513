/* eslint-disable react-native/no-inline-styles */
import React, { FC, Fragment } from "react"
import { Grid } from "@mui/material"
import {
  View,
  ViewStyle,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  ImageStyle,
  TextStyle,
  Image,
} from "react-native"
import { Serie } from "@app/interfaces/serie.interface"
import { Icon, Text } from "@app/components"
import { ModalAnonymous } from "@app/components/ModalAnonymous"
import useSeries from "../hooks/useSeries"
import { colors, typography } from "@app/theme"
import { isWeb } from "@app/utils/isWeb"

interface Props {
  serie: Serie
  navigateTo: any
}

export const SerieContainer: FC<Props> = ({ serie, episodePublished, navigateTo }) => {
  const { addFavorite, removeFavorite, auth, isInFavorites, showModal, hideModal, favStates } =
    useSeries()
  const type = "serie"

  const handleNavigate = (id: number, type: string) => {
    switch (type) {
      case "video":
        navigateTo("Player", { videoId: id })
        break
      case "document":
        navigateTo("Document", { documentId: id })
        break
      case "serie":
        navigateTo("Serie", { serieId: id })
        break
      default:
        break
    }
  }

  return (
    <Grid container spacing={3} sx={{ pt: 3, pb: 4 }}>
      <Grid item xs={12} md={4}>
        <View style={$serieThumbnailContainer}>
          <ImageBackground
            source={{ uri: serie.thumbnail }}
            resizeMode="cover"
            style={$serieThumbnail}
            imageStyle={$serieThumbnailImg}
          >
            {auth.isAnonymous ? (
              <TouchableOpacity
                onPress={() =>
                  showModal(
                    <ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />,
                    null,
                    600,
                  )
                }
              >
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartOutlined" size={23} color={colors.WHITE} />
                </View>
              </TouchableOpacity>
            ) : favStates[type] && favStates[type][serie.id] ? (
              <TouchableWithoutFeedback>
                <View style={$favoriteDocContainer}>
                  <ActivityIndicator size={16} color={colors.MAIN_AQUAMARINE} />
                </View>
              </TouchableWithoutFeedback>
            ) : isInFavorites(serie.id, type) ? (
              <TouchableOpacity onPress={() => removeFavorite(serie.id, type)}>
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartWeb" size={20} color={colors.MAIN_AQUAMARINE} />
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => addFavorite(serie.id, type)}>
                <View style={$favoriteDocContainer}>
                  <Icon icon="heartOutlined" size={23} color={colors.SHADES_OF_BLACK} />
                </View>
              </TouchableOpacity>
            )}
          </ImageBackground>
        </View>
        <View style={{ paddingHorizontal: 25, paddingVertical: 30 }}>
          <View style={{ paddingBottom: 25 }}>
            <Text text={serie.title} style={$titleText} />
          </View>
          <View style={{ paddingBottom: 10 }}>
            <Text text={serie.description} style={$descriptionText} />
          </View>
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {serie.categories.length > 0 &&
              serie.categories.map((category) => (
                <View key={category.id} style={{ paddingVertical: 5, paddingHorizontal: 3 }}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: 4,
                      borderColor: colors.GRAY,
                      backgroundColor: colors.GRAY,
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    <Text
                      text={category.name}
                      style={{
                        fontFamily: typography.fonts.montserrat.normal,
                        fontSize: 12,
                        fontWeight: "500",
                        fontStyle: "normal",
                        lineHeight: 14,
                        letterSpacing: 0,
                        textAlign: "left",
                        color: isWeb() ? colors.WHITE : colors.LIGHT_GRAY,
                      }}
                    />
                  </View>
                </View>
              ))}
          </View>
        </View>
      </Grid>
      <Grid item container spacing={3} xs={12} md={8} sx={{ height: "fit-content", with: "100%" }}>
        {episodePublished.length > 0 &&
          episodePublished.map((episode) => (
            <Fragment key={episode.id}>
              <Grid item xs={4}>
                <div
                  onClick={() => handleNavigate(episode.id, "video")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    source={{ uri: episode.thumbnail }}
                    resizeMode="cover"
                    style={{ width: "100%", aspectRatio: 16 / 9, borderRadius: 12 }}
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <View
                  onClick={() => console.log("click")}
                  style={{ paddingBottom: 25, cursor: "pointer" }}
                >
                  <Text text={episode.title} style={$titleText} />
                </View>
                <View style={{ paddingBottom: 10 }}>
                  <Text text={episode.description} style={$descriptionText} numberOfLines={3} />
                </View>
              </Grid>
            </Fragment>
          ))}
      </Grid>
    </Grid>
  )
}

const $favoriteDocContainer: ViewStyle = {
  height: 50,
  width: 50,
  borderRadius: 100,
  backgroundColor: colors.LIGHT_GRAY,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
}
const $serieThumbnail: ViewStyle = {
  width: "100%",
  // height: 150,
  aspectRatio: 16 / 9,
  borderRadius: 12,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  paddingBottom: 13,
  paddingRight: 13,
  // maxWidth: 230,
}

const $serieThumbnailImg: ImageStyle = {
  borderRadius: 12,
}

const $serieThumbnailContainer: ViewStyle = {
  alignItems: "flex-start",
  paddingLeft: 25,
}

const $titleText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 24,
  lineHeight: 26,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $descriptionText: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 16,
  lineHeight: 18,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}
