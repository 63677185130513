import React, { FC, ReactNode, ReactElement } from "react"
import { Grid } from "@mui/material"

interface Props {
  children: string | ReactNode | ReactElement | null
  padding?: number
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const ContainerResponsive: FC<Props> = ({ children, xs, sm, md, lg, xl }) => {
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item xs={xs || 11} sm={sm || 11} md={md || 10} lg={lg || 10} xl={xl || 8}>
        {children}
      </Grid>
    </Grid>
  )
}
