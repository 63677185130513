import axios from "@app/services/axios"
import { Video } from "@app/interfaces/video.interface"
import { Serie } from "@app/interfaces/serie.interface"
import { Document } from "@app/interfaces/document.interface"

type orderDirection = "ASC" | "DESC"

interface Params {
  title?: string
  presenters?: number[]
  categories?: number[]
  status?: string
  limit?: number
  fromDate?: Date
  page?: number
  direction?: orderDirection
}

export const fetchSearchEntities = async ({
  title,
  presenters,
  categories,
  limit,
  fromDate,
  page,
  direction,
}: Params) => {
  const response = await axios.post<{
    data: { videos: Video[]; series: Serie[]; documents: Document[] }
    status: number
  }>(`/api/search`, {
    ...(title && { title }),
    ...(presenters && { presenters }),
    ...(categories && { categories }),
    ...(limit && { limit }),
    ...(fromDate && { fromDate }),
    ...(page && { page }),
    ...(direction && { direction }),
  })
  return response
}
