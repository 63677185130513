import React, { useEffect, FC, useRef } from "react"
import useSeries from "./hooks/useSeries"
import { colors, typography, spacing } from "@app/theme"
import { BackDrop } from "@app/components/BackDrop"
import { isWeb } from "@app/utils/isWeb"
import { View, TouchableOpacity, ViewStyle, TextStyle, ImageBackground } from "react-native"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { Icon, Text, Screen } from "@app/components"
import { useNavigation } from "@app/navigators/utils"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { AppStackScreenProps } from "@app/navigators"
import { SerieContainer } from "./components/SerieContainer"
import { OtherSeriesContainer } from "./components/OtherSeriesContainer"
import { Footer } from "../Home/components/Footer"
import { useLocation } from "react-router-dom"

const BACKGROUND = require("../../../assets/images/web/bg-player.png")

export const SerieScreen: FC<AppStackScreenProps<"Serie">> = ({ route }) => {
  const { goBack,navigateTo } = useNavigation(null, route)
  const location = useLocation()
  const serieId: string = location.search.slice(9, location.search.length)

  const { isLoading, serie, initializeSerie, seriesRelated, saveOrUpdateToHistory, auth, episodePublished } =
    useSeries()
  const { height } = useWindowDimensions()
  const dataFetch = useRef(false)


  useEffect(() => {
    initializeSerie(Number(serieId))
    if (dataFetch.current) return
    dataFetch.current = true
    if (!auth.isAnonymous) {
      saveOrUpdateToHistory(Number(serieId), "serie")
    }
  }, [serieId])

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    minHeight: height - APP_BAR_HEIGHT,
  }

  if (isLoading) {
    return (
      <Screen
        preset="scroll"
        safeAreaEdges={["top"]}
        contentContainerStyle={$screenContentContainer}
        backgroundColor={colors.SHADES_OF_BLACK}
      >
        <BackDrop />
      </Screen>
    )
  }

  console.log('episode published', episodePublished)

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["top"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <ContainerResponsive>
        <View style={$headerContainer}>
          <TouchableOpacity onPress={async () => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            {isWeb() ? (
              <Text text="Volver" style={$textHeader} />
            ) : (
              <Text text="Documento" style={$textHeader} />
            )}
          </View>
        </View>
      </ContainerResponsive>
      <ImageBackground
        style={$imageBgContainer}
        source={BACKGROUND}
        resizeMode={isWeb() ? "stretch" : "cover"}
      >
        <ContainerResponsive padding={!isWeb() && 16} xs={11} md={11} lg={10} xl={8}>
          {serie && <SerieContainer serie={serie} episodePublished={episodePublished} navigateTo={navigateTo} />}
          {seriesRelated.length > 0 && (
            <OtherSeriesContainer items={seriesRelated} navigateTo={navigateTo} type="serie" />
          )}
        </ContainerResponsive>
      </ImageBackground>
      <Footer />
    </Screen>
  )
}

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}

const $backBtn: ViewStyle = { paddingRight: 10 }

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $imageBgContainer: ViewStyle = {
  paddingTop: isWeb() ? spacing.medium : 0,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}
