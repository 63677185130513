import React, { FC } from "react"
import {
  View,
  ViewStyle,
  ImageStyle,
  TouchableOpacity,
  ImageBackground,
  TextStyle,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native"
import { Text, Icon } from "@app/components"
import { colors, typography } from "@app/theme"
import usePlayer from "../hooks/use-player"
import { Grid } from "@mui/material"
import { Document } from "@app/interfaces/document.interface"
import { ModalAnonymous } from "@app/components/ModalAnonymous"

interface DocumentExistProps {
  documents: Document[]
  navigateTo: any
}

export const CardDocument: FC<DocumentExistProps> = ({ documents, navigateTo }) => {
  const {
    onDownloadDocument,
    authUser,
    isInFavorites,
    showModal,
    hideModal,
    removeFavorite,
    addFavorite,
    favStates,
  } = usePlayer()
  const type = "document"

  return (
    <Grid
      container
      spacing={5}
      justifyContent="flex-start"
      direction="row"
      alignContent="flex-end"
      sx={{ pt: 5, pb: 1 }}
    >
      <Grid item xs={12}>
        <Text text="Documentos adjuntos" style={$docHeaderText} />
      </Grid>
      {documents.map((doc) => (
        <Grid key={doc.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
          <View style={$cardDocument}>
            <TouchableOpacity onPress={() => navigateTo("Document", { documentId: doc.id })}>
              <ImageBackground
                source={{ uri: doc.thumbnail }}
                resizeMode="cover"
                style={$sliderCardStyle}
                imageStyle={$sliderCardImage}
              >
                {authUser.isAnonymous ? (
                  <TouchableOpacity
                    onPress={() => {
                      showModal(<ModalAnonymous hideModal={hideModal} navigateTo={navigateTo} />)
                    }}
                    style={$favoriteContainer}
                  >
                    <View>
                      <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                    </View>
                  </TouchableOpacity>
                ) : favStates[type] && favStates[type][doc.id] ? (
                  <TouchableWithoutFeedback>
                    <View style={$favoriteContainer}>
                      <ActivityIndicator size={14} color={colors.MAIN_AQUAMARINE} />
                    </View>
                  </TouchableWithoutFeedback>
                ) : isInFavorites(doc.id, type) ? (
                  <TouchableOpacity
                    onPress={() => removeFavorite(doc.id, type)}
                    style={$favoriteContainer}
                  >
                    <View>
                      <Icon icon="heart" size={14} color={colors.MAIN_AQUAMARINE} />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => addFavorite(doc.id, type)}
                    style={$favoriteContainer}
                  >
                    <View>
                      <Icon icon="heartOutlined" size={16} color={colors.WHITE} />
                    </View>
                  </TouchableOpacity>
                )}
              </ImageBackground>
            </TouchableOpacity>
            <View style={$titleDescriptionContainer}>
              <Text style={$documentTitleText}>{doc.title}</Text>
              <View style={$categoriesContainer}>
                {doc.categories.map((category) => (
                  <View key={category.id} style={$categoryContainer}>
                    <View style={$categoryNameContainer}>
                      <Text text={category.name} style={$categoryNameText} />
                    </View>
                  </View>
                ))}
              </View>
              <View style={$categoryDescriptionContainer}>
                <Text style={$descriptionText} numberOfLines={2} text={doc.description} />
              </View>
            </View>
            <TouchableOpacity onPress={() => onDownloadDocument(doc.document_url)}>
              <View style={$downloadBtnContainer}>
                <Text text="Descargar" style={$downloadBtnText} />
                <View style={{ paddingLeft: 5 }}>
                  <Icon icon="caretRight" size={20} color={colors.WHITE} />
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </Grid>
      ))}
    </Grid>
  )
}

const $docHeaderText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 20,
  lineHeight: 22,
  fontWeight: "500",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

const $downloadBtnContainer: ViewStyle = {
  width: "100%",
  height: 45,
  backgroundColor: colors.SECONDARY_VIOLET,
  borderBottomLeftRadius: 18,
  borderBottomRightRadius: 18,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
}

const $categoryDescriptionContainer: ViewStyle = {
  paddingVertical: 10,
}

const $categoryNameContainer: ViewStyle = {
  borderWidth: 1,
  justifyContent: "center",
  alignContent: "center",
  borderColor: colors.LIGHT_GRAY,
  borderRadius: 3,
  paddingHorizontal: 2,
  paddingVertical: 1,
  backgroundColor: colors.palette.neutral200
}

const $categoryContainer: ViewStyle = { paddingHorizontal: 2, paddingBottom: 3 }

const $categoriesContainer: ViewStyle = { display: "flex", flexDirection: "row", flexWrap: "wrap" }

const $titleDescriptionContainer: ViewStyle = { paddingHorizontal: 10, paddingTop: 10 }

const $downloadBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: 20,
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
  marginLeft: 15,
}

const $documentTitleText: TextStyle = {
  fontFamily: typography.fonts.montserrat.semiBold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: 19,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.MAIN_DARK_BLUE,
  paddingBottom: 8,
}

const $categoryNameText: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: 14,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.MAIN_DARK_BLUE,
}

const $descriptionText: TextStyle = {
  fontFamily: typography.fonts.montserrat.medium,
  fontSize: 13,
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: 15,
  letterSpacing: 0,
  textAlign: "left",
  color: colors.SECONDARY_BLACK,
}

const $cardDocument: ViewStyle = {
  borderRadius: 20,
  minHeight: 310,
  width: "100%",
  backgroundColor: colors.WHITE,
  display: "flex",
  justifyContent: "space-between",
}

const $sliderCardStyle: ViewStyle = {
  width: "100%",
  height: 150,
  borderRadius: 20,
  justifyContent: "flex-end",
  alignContent: "flex-end",
}

const $sliderCardImage: ImageStyle = {
  borderRadius: 18,
}

const $favoriteContainer: ViewStyle = {
  width: 32,
  height: 32,
  borderRadius: 16,
  margin: 9,
  backgroundColor: colors.MEDIUM_GRAY,
  alignSelf: "flex-end",
  alignItems: "center",
  justifyContent: "center",
}
