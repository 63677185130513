/* eslint-disable react-native/no-inline-styles */
import React, { Fragment } from "react"
import { View, ImageBackground } from "react-native"
import { Text, Button, Icon } from "@app/components"
import { colors, typography } from "@app/theme"
import { useNavigation } from "@app/navigators/utils"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import {
  $backgroundImage,
  $btnWatch,
  $btnWatchPressedStyle,
  $btnWatchPressedTextStyle,
  $btnWatchTextStyle,
  $descriptionTextStyle,
  $separator,
  $titleTextStyle,
  $videoContainer,
  $videoStyle,
} from "../styles"
import { Grid } from "@mui/material"
import { UserHistory } from "@app/interfaces/user_history.interface"

export const HistoryComponent = ({ history, navigation, isInFavorites }) => {
  const { navigateTo } = useNavigation(navigation)
  const { width } = useWindowDimensions()

  const convertVideoType = (type: string): string => {
    switch (type) {
      case "video":
        return "Video"
      case "document":
        return "Documento"
      case "serie":
        return "Serie"
      default:
        return ""
    }
  }

  const handleNavigate = (id: number, type: string) => {
    switch (type) {
      case "video":
        navigateTo("Player", { videoId: id })
        break
      case "document":
        navigateTo("Document", { documentId: id })
        break
      case "serie":
        navigateTo("Serie", { serieId: id })
        break
      default:
        break
    }
  }

  return (
    <View style={$videoContainer}>
      {history.userHistory.map((userHistory: UserHistory) => {
        let entity = null
        let type = null
        if (userHistory.video) {
          type = "video"
          entity = userHistory.video
        } else if (userHistory.document) {
          type = "document"
          entity = userHistory.document
        } else if (userHistory.serie) {
          type = "serie"
          entity = userHistory.serie
        }
        return (
          <Fragment key={userHistory.id}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <ImageBackground
                  style={$backgroundImage}
                  imageStyle={{ borderRadius: 7.3 }}
                  source={{ uri: entity.thumbnail }}
                  resizeMode="cover"
                >
                  <View style={$videoStyle}>
                    {isInFavorites(entity.id, type) ? (
                      <Icon icon="heart" color={colors.MAIN_AQUAMARINE} size={9} />
                    ) : (
                      <Icon icon="heartOutlined" color={colors.WHITE} size={11} />
                    )}
                  </View>
                </ImageBackground>
              </Grid>
              <Grid
                item
                xs={width >= 655 ? 8 : 7}
                container
                justifyContent="center"
                direction="column"
                sx={{ paddingLeft: 2 }}
              >
                <View
                  style={{
                    marginBottom: 6,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text text={entity.title} numberOfLines={2} style={$titleTextStyle} />
                  <div
                    // eslint-disable-next-line react-native/no-color-literals
                    style={{
                      marginLeft: 10,
                      backgroundColor: "rgba(255, 255, 255, 0.19)",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: 5,
                      paddingTop: 1,
                      paddingBottom: 1,
                      display: "flex",
                      alignItems: "center",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <Text
                      // TODO: Ver como conseguir el tipo
                      text={convertVideoType(type)}
                      style={{
                        fontFamily: typography.fonts.montserrat.normal,
                        color: colors.WHITE,
                        fontSize: 12,
                        lineHeight: 13,
                      }}
                    />
                  </div>
                </View>
                {width >= 650 && (
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        text={entity.description}
                        numberOfLines={2}
                        style={$descriptionTextStyle}
                      />
                    </View>
                  </View>
                )}
              </Grid>
              <Grid
                item
                xs={width >= 655 ? 2 : 1}
                container
                justifyContent="flex-end"
                alignContent="center"
              >
                <Button
                  onPress={() => handleNavigate(entity.id, type)}
                  text="Ver"
                  preset="reversed"
                  style={$btnWatch}
                  pressedStyle={$btnWatchPressedStyle}
                  textStyle={$btnWatchTextStyle}
                  pressedTextStyle={$btnWatchPressedTextStyle}
                />
              </Grid>
            </Grid>
            <View style={$separator} />
          </Fragment>
        )
      })}
    </View>
  )
}
