import React, { FC } from "react"
import Player from "@app/components/JWPlayer/Player"
import { Grid } from "@mui/material"
import { PlayerProps } from "../interfaces/player.interface"

export const PlayerWrapper: FC<PlayerProps> = ({
  video,
  JWPlayer,
  onPlay,
  onPause,
  playerMountedCallback,
  onTime,
}) => {
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item xs={12} md={10} lg={8} xl={6}>
        {video && JWPlayer !== null ? (
          <Player
            onPlay={onPlay}
            playlist={JWPlayer}
            onPause={onPause}
            didMountCallback={playerMountedCallback}
            onTime={onTime}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}
