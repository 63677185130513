import { IDispatch } from "@app/store/interface"
import { deleteOneHistory, fetchHistoryById, saveOrUpdateHistory } from "@app/services/api/history"
import {
  setHistoryReducer,
  addOrSaveHistoryReducer,
  removeHistoryReducer,
} from "@app/store/slices/history"
import { RootState } from "@app/store"

export const getUserHistoryAction = () => (dispatch: IDispatch, state: () => RootState) => {
  const userId = state().auth.user.id
  fetchHistoryById(userId)
    .then((res) => {
      dispatch(setHistoryReducer({ userHistory: res.data.data }))
    })
    .catch((err) => console.error(err))
}

export const addOrUpdateHistoryAction =
  (entityId: number, type: string) => (dispatch: IDispatch, state: () => RootState) => {
    const userId = state().auth.user.id
    saveOrUpdateHistory(userId, entityId, type)
      .then((res) => {
        dispatch(addOrSaveHistoryReducer({ userHistory: res.data.data }))
      })
      .catch((err) => console.error(err))
  }

export const deleteOneHistoryAction =
  (entityId: number, type: string) => (dispatch: IDispatch, state: () => RootState) => {
    const userId = state().auth.user.id
    deleteOneHistory(userId, entityId, type)
      .then((res) => {
        dispatch(removeHistoryReducer({ userHistory: res.data.data }))
      })
      .catch((err) => console.error(err))
  }
