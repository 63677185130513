import { createSlice } from "@reduxjs/toolkit"

interface appState {
  isLoading: boolean
}

const initialState: appState = {
  isLoading: false,
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setIsLoading } = appSlice.actions

export default appSlice.reducer
