import React, { ReactNode, createContext, useState, useContext, useMemo } from "react"
import { View, ViewStyle, Modal, ModalProps, TouchableWithoutFeedback } from "react-native"
import { colors } from "@app/theme"

const ModalContext = createContext({
  showModal: (_customComponent: ReactNode, _props?: ModalProps, _width?: number) => undefined,
  hideModal: () => null,
})

interface ModalProviderProps {
  children: ReactNode
}

export function ModalProvider({ children }: ModalProviderProps) {
  const [component, setComponent] = useState(null)
  const [modalProps, setProps] = useState({})
  const [isModalVisible, setModalVisible] = useState(false)
  const [width, setWidth] = useState(null)

  const $modalView: ViewStyle = {
    alignItems: "center",
    width: width || "90%",
    backgroundColor: colors.WHITE,
    borderRadius: 10,
    elevation: 4,
    paddingBottom: 27,
    shadowColor: colors.palette.neutral900,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  }

  const showModal = (customComponent: ReactNode, props = {}, width) => {
    setComponent(customComponent)
    setProps(props)
    setWidth(width)
    setModalVisible(true)
  }

  const hideModal = () => {
    setComponent(null)
    setProps({})
    setWidth(null)
    setModalVisible(false)
  }

  const value = useMemo(() => {
    return {
      showModal,
      hideModal,
    }
  }, [])

  return (
    <ModalContext.Provider value={value}>
      <View style={isModalVisible ? $containerOpen : $containerClose}>
        {children}
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          // onBackdropPress={() => hideModal()}
          {...modalProps}
        >
          <View style={$modalCenteredView}>
            <View style={$modalView}>{component}</View>
          </View>
        </Modal>
      </View>
    </ModalContext.Provider>
  )
}

export function useModal() {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error("Fuera de context")
  }
  return context
}

const $modalCenteredView: ViewStyle = {
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
}

const $containerClose: ViewStyle = {
  flex: 1,
}

const $containerOpen: ViewStyle = {
  flex: 1,
  opacity: 0.4,
  backgroundColor: colors.SHADES_OF_BLACK,
}

const $containerBackDrop: ViewStyle = {
  flex: 1,
}
