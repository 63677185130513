import storage from "@app/services/firebase/storage/Storage"

export const uploadImageToFirebase = async (image: File, id: number): Promise<string | void> => {
  try {
    const ref = storage().ref(`images/profile/${id}.png`)

    await ref.put(image)

    const url = await ref.getDownloadURL()

    return url
  } catch (err) {
    console.log(err)
  }
}
