import React, { FC, useEffect, useRef } from "react"
import { ViewStyle, View, TouchableOpacity, TextStyle, ImageBackground } from "react-native"
import { Screen, Text, Icon } from "@app/components"
import { colors, typography, spacing } from "@app/theme"
import { AppStackScreenProps } from "@app/navigators"
import { BackDrop } from "@app/components/BackDrop"
import { useNavigation } from "@app/navigators/utils"
import { APP_BAR_HEIGHT } from "@app/utils/constants"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { isWeb } from "@app/utils/isWeb"
import useDocument from "./hooks/use-document"
import { ContainerResponsive } from "@app/components/ContainerResponsive"
import { DocumentContent } from "./components/DocumentContent"
import { useIsMounted } from "@app/utils/isMounted"
import { Footer } from "../Home/components/Footer"
import { useLocation } from "react-router-dom"

const BACKGROUND = require("../../../assets/images/web/bg-player.png")

export const DocumentScreen: FC<AppStackScreenProps<"Player">> = ({ route }) => {
  const { goBack, navigateTo } = useNavigation(null, route)
  const isMounted = useIsMounted()
  const { isLoading, initializeDocument, documentFetch, saveOrUpdateToHistory, auth } =
    useDocument()
  // const { documentId } = params

  const location = useLocation()
  const documentId: string = location.search.slice(12, location.search.length)
  const { height } = useWindowDimensions()
  const dataFetch = useRef(false)

  useEffect(() => {
    if (isMounted()) {
      initializeDocument(Number(documentId))
      if (dataFetch.current) return
      dataFetch.current = true
      // TODO: en development se ejecuta 2 veces, fijarse como evitar crear 2 iguales
      // Cambiar a un solo endpoint para update o create
      if (!auth.isAnonymous) {
        saveOrUpdateToHistory(Number(documentId), "document")
      }
    }
  }, [documentId])

  const $screenContentContainer: ViewStyle = {
    flexGrow: 1,
    backgroundColor: colors.SHADES_OF_BLACK,
    minHeight: height - APP_BAR_HEIGHT,
  }

  if (isLoading) {
    return (
      <Screen
        preset="scroll"
        safeAreaEdges={["top"]}
        contentContainerStyle={$screenContentContainer}
        backgroundColor={colors.SHADES_OF_BLACK}
      >
        <BackDrop />
      </Screen>
    )
  }

  return (
    <Screen
      preset="scroll"
      safeAreaEdges={["top"]}
      contentContainerStyle={$screenContentContainer}
      backgroundColor={colors.SHADES_OF_BLACK}
    >
      <ContainerResponsive>
        <View style={$headerContainer}>
          <TouchableOpacity onPress={async () => goBack()} style={$backBtn}>
            <Icon icon="leftArrowFilledCircle" size={37} color={colors.WHITE} />
          </TouchableOpacity>
          <View style={$textHeaderContainer}>
            {isWeb() ? (
              <Text text="Volver" style={$textHeader} />
            ) : (
              <Text text="Documento" style={$textHeader} />
            )}
          </View>
        </View>
      </ContainerResponsive>
      <ImageBackground
        style={$imageBgContainer}
        source={BACKGROUND}
        resizeMode={isWeb() ? "stretch" : "cover"}
      >
        <ContainerResponsive padding={!isWeb() && 16} xs={11} md={10} lg={9} xl={8}>
          <DocumentContent document={documentFetch} navigateTo={navigateTo} />
        </ContainerResponsive>
      </ImageBackground>
      <Footer />
    </Screen>
  )
}

const $headerContainer: ViewStyle = {
  height: 60,
  alignItems: "center",
  paddingLeft: 13,
  flexDirection: "row",
  backgroundColor: colors.SHADES_OF_BLACK,
}

const $backBtn: ViewStyle = { paddingRight: 10 }

const $textHeaderContainer: ViewStyle = { marginLeft: 5 }

const $textHeader: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  lineHeight: 21,
  fontWeight: 'bold',
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
}

// TODO: Mover todo el componente de la barra de volver como un componente re-utilizable

const $imageBgContainer: ViewStyle = {
  paddingTop: isWeb() ? spacing.medium : 0,
  paddingBottom: spacing.medium,
  display: "flex",
  flexGrow: 1,
}
