import React, { FC } from "react"
import { ActivityIndicator, View, ViewStyle } from "react-native"
import { colors } from "@app/theme"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { isWeb } from "@app/utils/isWeb"
import { APP_BAR_HEIGHT } from "@app/utils/constants"


interface props {
  sizeIndicator?: number
  color?: string
  zIndex?: number
}

export const BackDrop: FC<props> = ({ sizeIndicator, color, zIndex }) => {
  const { height } = useWindowDimensions()

  const $containerWeb: ViewStyle = {
    display: "flex",
    zIndex: 999,
    opacity: 0.6,
    justifyContent: "center",
    backgroundColor: colors.SHADES_OF_BLACK,
    height: height - APP_BAR_HEIGHT,
    overflow: "hidden",
  }

  const $containerMobile: ViewStyle = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 999,
    opacity: 0.6,
    backgroundColor: colors.SHADES_OF_BLACK,
  }

  return (
    <View style={isWeb() ? $containerWeb : $containerMobile}>
      <ActivityIndicator
        size={sizeIndicator || 50}
        color={color || colors.MAIN_AQUAMARINE}
        style={[isWeb() ? $activityIndicatorWeb : $activityIndicator, { zIndex }]}
      />
    </View>
  )
}

const $activityIndicator: ViewStyle = {
  position: "absolute",
  right: 0,
  left: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
}

const $activityIndicatorWeb: ViewStyle = {
  alignItems: "center",
}
