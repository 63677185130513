import axios from "@app/services/axios"
import { UserHistory } from "@app/interfaces/user_history.interface"
import { AUTH_HEADERS } from "@app/utils/constants"

export const fetchHistoryById = async (id: number) => {
  const response = await axios.get<{ data: UserHistory[]; status: number }>(
    `/api/history/${id}`,
    await AUTH_HEADERS(),
  )
  return response
}

export const saveOrUpdateHistory = async (userId: number, entityId: number, type: string) => {
  const response = await axios.post<{ data: UserHistory; status: number }>(
    `/api/history`,
    {
      user_id: userId,
      entity_id: entityId,
      type,
    },
    await AUTH_HEADERS(),
  )
  return response
}

export const deleteOneHistory = async (userId: number, entityId: number, type: string) => {
  const response = await axios.put<{ data: UserHistory; status: number }>(
    `/api/history/delete`,
    {
      user_id: userId,
      entity_id: entityId,
      type,
    },
    await AUTH_HEADERS(),
  )
  return response
}
