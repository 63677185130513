import { Screen, Icon, TextField, Button } from "@app/components"
import React from "react"
import { Text } from "../../../components/Text"
import { View, Alert, TextStyle, ViewStyle } from "react-native"
import auth from "@app/services/firebase/auth/Auth"
import { colors, spacing, typography } from "@app/theme"
import { isWeb } from "@app/utils/isWeb"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useNavigation } from "@app/navigators/utils"
import { Link } from "react-router-dom"
import useRecovery from "./hooks/useRecovery"

const backgroundImage = require("../../../../assets/images/web/background-screens.png")
const arrowBack = require('../../../../assets/images/web/arrowBack.png')
const logo = require('../../../../assets/images/web/logotipoWeb.png')

export const ForgotPassword = () => {
  const { navigateTo } = useNavigation()
  const {
    formik: { values, handleChange, handleBlur, handleSubmit, touched, errors },
  } = useRecovery()

  const { height } = useWindowDimensions()

  const insets = useSafeAreaInsets()

  const $screenContentContainerWeb = {
    flexGrow: 1,
    backgroundColor: colors.MAIN_DARK_BLUE,
    backgroundImage: `url(${backgroundImage})`,
    minHeight: height,
    paddingBottom: spacing.large,
    paddingTop: insets.top,
    backgroundSize: "100% 100%",
    backgrounAttachment: "fixed",
    backgroundPosition: "center, center",
  }

  return (
    <Screen
      preset="scroll"
      contentContainerStyle={isWeb() ? $screenContentContainerWeb : $screenContentContainer}
    >
      <View style={$header}>
        <View style={$iconBack}>
            <Link to="/Login" relative="path">
              <img src={arrowBack} style={$arrowBack} alt="back" />
            </Link>
        </View>

        <View>
          <Text style={$formTitle} text="Recuperar contraseña" />
        </View>

        <View style={$logo}>
            <Link to="/" relative="path">
              <img src={logo} style={$iconLogo}  alt="home" />
            </Link>
        </View>

      </View>
      <View style={$container}>
        <View>
          <View style={$ingresTuEmail}>
            <Text text="Ingresá tu email" style={$formSubtitle} />
          </View>
          <View style={$text}>
            <Text text="Te enviaremos un correo para" style={$h3} />
            <Text text="recuperar tu contraseña" style={$h3} />
          </View>
          <View style={$containerForm}>
            <Text style={$labelInput} text="Email" />
            <TextField
              onChangeText={handleChange("email")}
              onBlur={handleBlur("email")}
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              helper={touched.email && errors.email}
              value={values.email}
              status={touched.email && errors.email ? "error" : null}
              placeholderTextColor={$placeholderColor}
              placeholderTx="forgotPasswordScreen.emailPlaceholder"
              style={$emailTxt}
              inputWrapperStyle={$inputWrapper}
            />
          </View>
        </View>
      </View>
      <View style={$btnContent}>
        <View style={$submitContainer}>
          <Button
            style={$tapButtonToConfirm}
            preset="filled"
            text="Recuperar contraseña"
            textStyle={$editBtnText}
            onPress={() => {
              handleSubmit()
            }}
          />
        </View>
      </View>
    </Screen>
  )
}

export default ForgotPassword

//* Styles:
const $emailTxt = {
  fontFamily: typography.fonts.montserrat.normal
}

const $iconBack: ViewStyle = {
  marginLeft: 17,
  paddingRight: 5,
  marginTop: 45,
}
const $logo: ViewStyle = {
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  display: 'flex',

}
const $iconLogo: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 65,
  marginRight: 60,
  height: 50,
  borderWidth: 1,
  minHeight: 10,
  objectFit: "contain"
}

const $arrowBack: React.CSSProperties = {
  width: 30,
  height: 30,
  borderRadius: 16,
  marginLeft: 5
}

const $header: ViewStyle = {
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  height: 55,
}

const $text: ViewStyle = {
  display: "flex", 
  justifyContent: "center", 
  marginTop: "-30px"
}

const $screenContentContainer: ViewStyle = {
  paddingVertical: spacing.tiny,
  flex: 1,
  backgroundColor: colors.WHITE,
}

const $container: ViewStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  paddingHorizontal: spacing.extraLarge,
}


const $formTitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  marginTop: 45,
  marginLeft: 10,
  fontSize: 25,
  lineHeight: 22,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  fontWeight: "bold",
  color: colors.WHITE,
}

const $containerForm: ViewStyle = {
  marginTop: 50,
  marginStart: spacing.tiny,
  marginEnd: spacing.tiny,
  display: "flex",
  justifyContent: "center",
}

const $tapButtonToConfirm: TextStyle = {
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 30,
  borderStyle: "solid",
  borderWidth: 1,
  minHeight: 10,
  height: 51,
  width: 270,
  backgroundColor: colors.MAIN_AQUAMARINE,
  fontFamily: typography.fonts.montserrat.normal,
}

const $submitContainer: ViewStyle = {
  flex: 1,
}

const $inputWrapper: ViewStyle = {
  height: 41,
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: colors.WHITE,
  minHeight: 10,
}

const $labelInput: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  marginTop: 5,
  marginBottom: 5,
  fontSize: 20,
  fontWeight: "100",
  color: colors.WHITE,
}

const $placeholderColor = colors.palette.neutral500

const $formSubtitle: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 40,
  paddingTop: "10%",
  paddingBottom: "20%",
  fontWeight: "bold",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  color: colors.WHITE,
}

const $ingresTuEmail = {
  width: "100%",
  padding: 1,
}

const $h3: TextStyle = {
  fontFamily: typography.fonts.montserrat.light,
  fontSize: 18,
  fontWeight: "300",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  display: "flex",
  justifyContent: "flex-start",
  color: colors.WHITE,
}

const $editBtnText: TextStyle = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: 10,
  letterSpacing: -0.5,
  textAlign: "center",
  color: colors.MAIN_DARK_BLUE,
}

const $btnContent: ViewStyle = {
  justifyContent: "center",
  marginBottom: 100,
  display: 'flex'
}
