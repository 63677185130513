import React, { CSSProperties, FC } from "react"
import { View, TouchableOpacity, ViewStyle, TextStyle, ImageStyle, Linking } from "react-native"
import { Icon, Text } from "@app/components"
import { colors, typography } from "@app/theme"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { useAppSelector } from "@app/store/hooks/useRedux"
import { NavigateBtnFooter } from "./NavigateBtnFooter.web"
import { Grid } from "@mui/material"
import { Link } from "react-router-dom"

const LOGO = require("../../../../assets/images/web/appBar-logo.png")
const ANDROID_DOWNLOAD = require("../../../../assets/images/web/android-download.png")
const IOS_DOWNLOAD = require("../../../../assets/images/web/download-apple.png")

export const Footer: FC = () => {
  const { width } = useWindowDimensions()
  const { isAnonymous } = useAppSelector((state) => state.auth)

  const $logoContainer: ViewStyle = {
    marginBottom: 10,
    alignItems: "center",
    display: "flex",
  }

  const $downloadText: TextStyle = {
    marginBottom: 15,
    fontFamily: typography.fonts.montserrat.medium,
    fontSize: 19,
    lineHeight: 22,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    color: colors.WHITE,
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: colors.SHADES_OF_BLACK,
        minHeight: 350,
      }}
      alignItems="center"
      spacing={3}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
        direction="column"
        sx={{ marginBottom: 2 }}
      >
        <View style={$logoContainer}>
          <img src={LOGO} alt="logo" style={$logo} />
        </View>
        <Text text="Descarga la App" style={$downloadText} />
        <View style={$downloadAppStoreContainer}>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://play.google.com/store/apps/details?id=cl.udd.libbre');
              console.log("download android")
            }}
          >
            <img src={ANDROID_DOWNLOAD} alt="logo" style={$androidDownloadBtn} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://apps.apple.com/us/app/libbre/id6447318087');
              console.log("download ios")
            }}
          >
            <img src={IOS_DOWNLOAD} alt="logo" style={$iosDownloadBtn} />
          </TouchableOpacity>
        </View>
      </Grid>

      <Grid
        item
        container
        xs={12}
        justifyContent="space-around"
        alignItems="flex-start"
        direction="row"
        spacing={2}
      >
        <Grid
          item
          container
          sm={3}
          xs={3.5}
          md={3}
          direction="column"
          alignItems="center" // Ensure alignment
          sx={{ textAlign: 'center' }} // Center text horizontally
        >
          <Grid item container direction="column" sx={$SeguinosContainer}>
            <Grid sx={$seguinosTextContainer}>
              <Text text="Síguenos" style={$followText} />
            </Grid>

            <Grid style={$socialBtnContainer}>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL('https://www.youtube.com/channel/UCy6FFWnqwd_ABHDeOLtFUtg');
                  console.log("youtube")
                }}
              >
                <Icon icon="youtube" color={colors.WHITE} size={25} style={$iconMargins} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL('https://www.instagram.com/libbre_org/');
                  console.log("instagram")
                }}
              >
                <Icon icon="instagram" color={colors.WHITE} size={22} style={$iconMargins} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL('https://www.tiktok.com/@libbre_org');
                  console.log("tiktok")
                }}
              >
                <Icon icon="tiktok" color={colors.WHITE} size={18} style={$iconFacebook} />
              </TouchableOpacity>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={3}
          xs={3.5}
          md={3}
          direction="column"
          alignItems="center" // Align content in the center
          sx={{ textAlign: 'center' }} // Center text horizontally
        >
          <Grid item container direction="column" sx={$PaginaContainer}>
            <Text text="Página" style={$pagesText} />
            <NavigateBtnFooter isAnonymous={isAnonymous} />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={3.5}
          sm={3}
          md={3}
          direction="column"
          alignItems="center" // Align content in the center
          sx={{ textAlign: 'center' }} // Center text horizontally
        >
          <Link to="/Contacto" relative="path" style={$links}>
            <Text text="Contacto" style={$pagesText} />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

const $iconFacebook: ImageStyle = { marginHorizontal: 3 }

const $iconMargins: ImageStyle = { marginHorizontal: 5 }

const $seguinosTextContainer = { paddingBottom: 2 }

const $pagesText: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 19,
  lineHeight: 22,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  alignItems: "center",
  color: colors.WHITE,
  marginBottom: 15,
}

const $socialBtnContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}
const $SeguinosContainer = {
  display: "flex",
  alignItems: "center",
}
const $PaginaContainer: ViewStyle = {
  display: "flex",
  alignItems: "center",
}
const $logo: CSSProperties = { width: 160 }

const $downloadAppStoreContainer: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  flexWrap: "wrap",
}

const $androidDownloadBtn: CSSProperties = { height: 50 }

const $iosDownloadBtn: CSSProperties = { height: 55 }

const $followText: TextStyle = {
  fontFamily: typography.fonts.montserrat.normal,
  fontSize: 19,
  lineHeight: 22,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  alignItems: "center",
  color: colors.WHITE,
  marginBottom: 15,
}

const $links: CSSProperties = {
  textDecoration: "inherit",
  color: "inherit",
}
