/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { FC, CSSProperties } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import "./css/carouselBanners.css"
import { Banner } from "@app/interfaces/banner.interface"
import { colors, typography } from "@app/theme"
import useHome from "../hooks/use-home"
import { Button, Grid } from "@mui/material"
import { useWindowDimensions } from "@app/utils/useWindowDimensions"
import { APP_BAR_HEIGHT } from "@app/utils/constants"

interface CarouselBannerProps {
  banners: Banner[]
  navigateTo: any
}

export const CarouselBanner: FC<CarouselBannerProps> = ({ banners, navigateTo }) => {
  const { auth } = useHome()
  const { width } = useWindowDimensions()

  const isSmallDevice = width <= 600

  const $swiperStyle: CSSProperties = {
    backgroundColor: "transparent",
    flex: 1,
    height: "auto",
    maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
    aspectRatio: width > 900 ? 24 / 9 : 16 / 9,
  }

  const $cardContainer: CSSProperties = {
    height: "100%",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    paddingLeft: 50,
    paddingRight: 50,
    background: isSmallDevice
      ? "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9178046218487395) 28%, rgba(0,0,0,0.8141631652661064) 35%, rgba(0,0,0,0.6432948179271709) 42%, rgba(255,255,255,0) 56%)"
      : "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.852000175070028) 10%, rgba(0,0,0,0) 60%)",
  }

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={9} sx={$containerBannerCarouselWeb}>
        <Grid>
          <Swiper
            modules={[Pagination, Autoplay]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            style={$swiperStyle}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
          >
            {banners.length > 0 &&
              banners.map((banner, index) => (
                <SwiperSlide
                  style={{ width: "100%", height: "100%" } as CSSProperties}
                  key={banner.id}
                >
                  <div
                    style={
                      isSmallDevice
                        ? { display: "none" }
                        : {
                            height: "100%",
                            width: "40%",
                            backgroundColor: "black",
                            left: 10,
                            position: "relative",
                          }
                    }
                  ></div>
                  <div
                    style={
                      {
                        height: "100%",
                        backgroundImage: `url(${banner.image})`,
                        backgroundPositionX: "100%",
                        backgroundAttachment: "local",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "black",
                        backgroundSize: "cover",
                        backgroundPosition: "center right",
                        width: "100%",
                      } as CSSProperties
                    }
                  >
                    <Grid
                      style={$cardContainer}
                      onClick={(e) => {
                        window.open(banner.link, "_blank")
                        e.stopPropagation()
                      }}
                    >
                      <div
                        style={
                          width > 900
                            ? {
                                position: "absolute",
                                left: "2%",
                                maxWidth: "35vw",
                              }
                            : { position: "absolute", left: "5%", paddingRight: 15 }
                        }
                      >
                        <p
                          style={$bannerTitle}
                          className="break-word-banner banner-title-responsive"
                        >
                          {banner.name}
                        </p>
                        <p
                          style={$bannerDescription}
                          className="break-word banner-description-responsive"
                        >
                          {banner.description}
                        </p>
                        {!auth.isAuth && index === 0 && (
                          <div style={$btnLogInContainer}>
                            <Button
                              onClick={(e) => {
                                navigateTo("Login")
                                e.stopPropagation()
                              }}
                              variant="contained"
                              disableElevation
                              sx={{
                                backgroundColor: colors.MAIN_AQUAMARINE,
                                color: colors.MAIN_DARK_BLUE,
                                fontFamily: typography.fonts.montserrat.bold,
                                fontSize: isSmallDevice ? 14 : 16,
                                letterSpacing: -0.5,
                                fontStyle: "normal",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderRadius: 30,
                                height: isSmallDevice ? 40 : 51,
                                minWidth: isSmallDevice ? 200 : 330,
                                width: "auto",
                                "&:hover": {
                                  backgroundColor: colors.MAIN_DARK_BLUE,
                                  color: colors.WHITE,
                                },
                              }}
                            >
                              Iniciar Sesión
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </Grid>
      </Grid>
    </Grid>
  )
}

const $containerBannerCarouselWeb: CSSProperties = {
  justifyContent: "flex-start",
  alignItems: "center",
  minWidth: "100%",
  width: 1536,
}

const $btnLogInContainer: CSSProperties = {
  marginTop: 20,
  display: "flex",
  justifyContent: "flex-start",
}

const $bannerTitle: CSSProperties = {
  fontFamily: typography.fonts.montserrat.bold,
  fontSize: "3rem",
  fontStyle: "normal",
  fontWeight: "bold",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
  margin: 0,
}

const $bannerDescription: CSSProperties = {
  fontFamily: typography.fonts.montserrat.normal,
  fontWeight: "300",
  fontSize: 30,
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "left",
  color: colors.WHITE,
  margin: 0,
}
